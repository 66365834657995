import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import map from 'images/about-us/map.jpg';

function Address() {
  return (
    <Box mt={{ _: 40, lg: 0 }} m="0 auto">
      <Text fontSize={{ _: 26, md: 36 }} fontWeight={600} mb={6}>Image Engine Limited</Text>
      <Text mb={4}>
        Bell Road, Beachlands, Auckland, New Zealand
      </Text>
      <Text mb={6}>
        Email: Support@imageengine.ai
      </Text>
      <img src={map} alt="" />
    </Box>
  );
}

export default Address;
