import React from 'react';
import { startCase } from 'lodash';
import { Row, Col } from 'antd';
import iconPeople from 'images/icon-people.svg';
import iconBicycle from 'images/icon-bicycle.svg';
import iconBoat from 'images/icon-boat.svg';
import iconCar from 'images/icon-car.svg';
import iconFire from 'images/icon-fire.svg';
import Box from 'components/Box';
import Text from 'components/Text';

function Cards({ data }) {
  const cardIcon = (type) => {
    switch (type) {
      case 'person':
        return iconPeople;
      case 'car':
        return iconCar;
      case 'bicycle':
        return iconBicycle;
      case 'boat':
        return iconBoat;
      case 'fire':
        return iconFire;
      default:
        return iconPeople;
    }
  };

  return (
    <Row gutter={[20, 20]}>
      {
        data.map(({ type, imageCount, deviceCount, detectionCount }) => {
          return (
            <Col key={type} xs={{ span: 12 }} sm={{ span: 8 }} md={{ span: 8 }}>
              <Box
                width="100%"
                border="1px solid"
                borderColor="gray_4"
                py={3}
                px={4}
                backgroundColor="white"
                borderRadius={4}
              >
                <Box display="flex" alignItems="center">
                  <img src={cardIcon(type)} alt={type} />
                  <Text fontSize={24} fontWeight={600} ml={2}>
                    {detectionCount}
                  </Text>
                </Box>
                <Text fontSize={12} fontWeight={600} mt={1} mb={3}>
                  {startCase(type)} detection
                </Text>
                <Text fontSize={12}>{deviceCount} device</Text>
                <Text fontSize={12}>{imageCount} images</Text>
              </Box>
            </Col>
          );
        })
      }
    </Row>
  );
}

export default Cards;
