import React from 'react';
import { Tabs, Button } from 'antd';
import {
  AndroidOutlined,
  SearchOutlined,
  AppleOutlined,
  ScanOutlined,
  DatabaseOutlined,
  VideoCameraOutlined,
  FieldTimeOutlined,
  UploadOutlined,
  MobileOutlined,
  MailOutlined,
  ClusterOutlined,
} from '@ant-design/icons';
import Box from 'components/Box';
import Text from 'components/Text';
import checkTrue from 'images/check-true.svg';
import checkFalse from 'images/check-false.svg';

import { PRICING_DATA } from './constants';

const { TabPane } = Tabs;

function PricingSlider() {
  const copper = PRICING_DATA.find((i) => i.slug === 'copper');
  const bronze = PRICING_DATA.find((i) => i.slug === 'bronze');
  const silver = PRICING_DATA.find((i) => i.slug === 'silver');
  const gold = PRICING_DATA.find((i) => i.slug === 'gold');
  const platinum = PRICING_DATA.find((i) => i.slug === 'platinum');

  const renderItem = (i) => {
    return (
      <Box
        width="100%"
        maxWidth={400}
        m="30px auto 0"
        border="1px solid"
        borderColor="gray"
        borderBottom="none"
      >
        <Box
          px={20}
          py={15}
          textAlign="center"
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          position="relative"
          borderBottom="1px solid"
          borderColor="gray"
        >
          <Text fontSize={18}>{i.title}</Text>
          <Text fontSize={14}>Early Adopter Pricing</Text>
          <Text fontSize={18} fontWeight={600}>
            {i.price}
          </Text>
          <Text fontSize={11}>{i.subtitle}</Text>
          {i?.recommended && (
            <Box
              width="100%"
              position="absolute"
              backgroundColor="secondary"
              p={1}
              left={0}
              top={-32}
            >
              <Text color="white">Recommended</Text>
            </Box>
          )}
        </Box>
        <Box
          px={20}
          py={15}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          alignItems="center"
        >
          <SearchOutlined style={{ fontSize: '21px' }} />
          <Box ml={3}>
            <Text fontSize={14} fontWeight={600}>
              {i.imageStore}
            </Text>
            <Text fontSize={14}>Image Store and Analyse</Text>
          </Box>
        </Box>
        <Box
          px={20}
          py={15}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          alignItems="center"
        >
          <ScanOutlined style={{ fontSize: '21px' }} />
          <Box ml={3}>
            <Text fontSize={14} fontWeight={600}>
              {i.aiChecks}
            </Text>
            <Text fontSize={14}>AI Checks / Month</Text>
          </Box>
        </Box>
        <Box
          px={20}
          py={15}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          alignItems="center"
        >
          <DatabaseOutlined style={{ fontSize: '21px' }} />
          <Box ml={3}>
            <Text fontSize={14} fontWeight={600}>
              {i.storage}
            </Text>
            <Text fontSize={14}>Storage</Text>
          </Box>
        </Box>
        <Box
          px={20}
          py={15}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          alignItems="center"
        >
          <VideoCameraOutlined style={{ fontSize: '21px' }} />
          <Box ml={3}>
            <Text fontSize={14} fontWeight={600}>
              {i.ftpCamera ? (
                <img src={checkTrue} alt="check" />
              ) : (
                <img src={checkFalse} alt="check" />
              )}
            </Text>
            <Text fontSize={14}>Supports FTP Camera</Text>
          </Box>
        </Box>
        <Box
          px={20}
          py={15}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          alignItems="center"
        >
          <FieldTimeOutlined style={{ fontSize: '21px' }} />
          <Box ml={3}>
            <Text fontSize={14} fontWeight={600}>
              {i.processTime}
            </Text>
            <Text fontSize={14}>Process Time Per Image</Text>
          </Box>
        </Box>
        <Box
          px={20}
          py={15}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          alignItems="center"
        >
          <UploadOutlined style={{ fontSize: '21px' }} />
          <Box ml={3}>
            <Text fontSize={14} fontWeight={600}>
              {i.uploadSpeed}
            </Text>
            <Text fontSize={14}>Upload Speed</Text>
          </Box>
        </Box>
        <Box
          px={20}
          py={15}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          alignItems="center"
        >
          <MobileOutlined style={{ fontSize: '21px' }} />
          <Box ml={3}>
            <Text fontSize={14} fontWeight={600}>
              <AppleOutlined style={{ fontSize: '18px', margin: '0 3px' }} />
              <AndroidOutlined style={{ fontSize: '18px', margin: '0 3px' }} />
            </Text>
            <Text fontSize={14}>Mobile OS Built</Text>
          </Box>
        </Box>
        <Box
          px={20}
          py={15}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          alignItems="center"
        >
          <MailOutlined style={{ fontSize: '21px' }} />
          <Box ml={3}>
            <Text fontSize={14} fontWeight={600}>
              {i.emailSupport ? (
                <img src={checkTrue} alt="check" />
              ) : (
                <img src={checkFalse} alt="check" />
              )}
            </Text>
            <Text fontSize={14}>Email Support</Text>
          </Box>
        </Box>
        <Box
          px={20}
          py={15}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          alignItems="center"
        >
          <ClusterOutlined style={{ fontSize: '21px' }} />
          <Box ml={3}>
            <Text fontSize={14} fontWeight={600}>
              {i.addOnStorage ? (
                <img src={checkTrue} alt="check" />
              ) : (
                <img src={checkFalse} alt="check" />
              )}
            </Text>
            <Text fontSize={14}>Add-on Storage Units</Text>
          </Box>
        </Box>
        <Box
          px={20}
          py={15}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          alignItems="center"
        >
          <ClusterOutlined style={{ fontSize: '21px' }} />
          <Box ml={3}>
            <Text fontSize={14} fontWeight={600}>
              {i.addOnAi ? (
                <img src={checkTrue} alt="check" />
              ) : (
                <img src={checkFalse} alt="check" />
              )}
            </Text>
            <Text fontSize={14}>Add-on AI Units</Text>
          </Box>
        </Box>
        <Box
          px={20}
          py={15}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          alignItems="center"
        >
          <ClusterOutlined style={{ fontSize: '21px' }} />
          <Box ml={3}>
            <Text fontSize={14} fontWeight={600}>
              {i.additionalCameras ? (
                <img src={checkTrue} alt="check" />
              ) : (
                <img src={checkFalse} alt="check" />
              )}
            </Text>
            <Text fontSize={14}>Add Additional Cameras</Text>
          </Box>
        </Box>
        <Box
          p={20}
          backgroundColor={i?.recommended ? 'white_1' : 'white'}
          borderBottom="1px solid"
          borderColor="gray"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Button type="primary" block size="large">
            Choose Plan
          </Button>
          {i.trial && <Button type="link">Try it for free</Button>}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      display={{ _: 'block', lg: 'none' }}
      py={40}
      px={20}
      maxWidth={900}
      width="100%"
      margin="auto"
    >
      <Text fontSize={18} letterSpacing={0.005} textAlign="center">
        Choose the plan that best suits your needs and start your FREE one-month
        trial.
      </Text>
      <Box mt={30}>
        <Tabs type="card" centered defaultActiveKey="3">
          <TabPane tab="Copper" key="1">
            {renderItem(copper)}
          </TabPane>
          <TabPane tab="Bronze" key="2">
            {renderItem(bronze)}
          </TabPane>
          <TabPane tab="Silver" key="3">
            {renderItem(silver)}
          </TabPane>
          <TabPane tab="Gold" key="4">
            {renderItem(gold)}
          </TabPane>
          <TabPane tab="Platinum" key="5">
            {renderItem(platinum)}
          </TabPane>
        </Tabs>
      </Box>
    </Box>
  );
}

export default PricingSlider;
