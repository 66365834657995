import React from 'react';
import { Space, Typography, Avatar, Row, Button, Col, Input, Form, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { UserSwitchOutlined, UploadOutlined } from '@ant-design/icons';

import { useEffectOnce, useUpdateEffect } from 'react-use';
import Box from 'components/Box';
import { selectPendingUpdateAvatar, updateUserAvatar, selectUserMe, getUserMe } from 'features/Users/slice';

const { Title } = Typography;

function Account() {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const pendingUpdateAvatar = useSelector(selectPendingUpdateAvatar);
  const userMe = useSelector(selectUserMe);

  useEffectOnce(() => {
    dispatch(getUserMe());
  });

  useUpdateEffect(() => {
    if (userMe?.profile) {
      const { email, name } = userMe.profile;
      form.setFieldsValue({
        email,
        name,
      });
    }
  }, [userMe]);

  const uploadProps = {
    beforeUpload(file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        file.base64 = e.target.result;

        await dispatch(updateUserAvatar({ file }));
        await dispatch(getUserMe());
      };
      reader.readAsDataURL(file);

      return false;
    },
    showUploadList: false,
  };

  return (
    <Box p={{ _: 20, sm: 30 }}>
      <Space style={{ margin: '0 0 20px 0' }}>
        <UserSwitchOutlined style={{ fontSize: '21px' }} />
        <Title level={4} style={{ margin: '0' }}>
          Account Settings
        </Title>
      </Space>
      <Row>
        <Space size={30}>
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            src={pendingUpdateAvatar ? '' : userMe.profile?.avatar}
          />
          <Space direction="vertical">
            <Upload {...uploadProps}>
              <Button loading={pendingUpdateAvatar} icon={<UploadOutlined />}>Upload Avatar</Button>
            </Upload>
            <p>Recommended size 800x800. Max upload file size 2 MB</p>
          </Space>
        </Space>
      </Row>
      <Form form={form} layout="vertical">
        <Row style={{ maxWidth: '550px', marginTop: '30px' }}>
          <Col span={24}>
            <Form.Item label="Full Name" name="name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
          </Col>
          {/* <Col span={24}> */}
          {/*   <Form.Item label="Phone" name="phone"> */}
          {/*     <Input /> */}
          {/*   </Form.Item> */}
          {/* </Col> */}
          <Col span={24}>
            <Space>
              {/* generate Auth0 Request Password link via email */}
              <Button type="primary">Request Change Password</Button>
              <Button type="primary" danger>
                Delete Account
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Box>
  );
}

export default Account;
