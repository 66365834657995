import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';

export const productsGet = createAsyncThunk(
  'products/get',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const { data } = await api.get('/products', {
        params: {
          limit: 50,
          sort: {
            price: -1,
          },
        },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const productsAdapter = createEntityAdapter();
const initialState = productsAdapter.getInitialState({
  pending: false,
  error: null,
});

export const slice = createSlice({
  name: 'productsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(productsGet.fulfilled, (state, { payload }) => {
        state.pending = false;
        productsAdapter.setAll(state, payload || []);
      })
      .addCase(productsGet.pending, (state) => {
        state.pending = true;
      })
      .addCase(productsGet.rejected, (state) => {
        state.pending = false;
      });
  },
});

export const {
  selectAll: selectAllProducts,
} = productsAdapter.getSelectors((state) => state.productsList);

export const selectPending = (state) => state.productsList.pending;
export const selectError = (state) => state.productsList.error;

export default slice.reducer;
