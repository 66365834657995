import React from 'react';
import Box from 'components/Box';
import Text from 'components/Text';
import { Space, Input, Button } from 'antd';
import {
  SearchOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch, setShowActiveOnly, selectShowActiveOnly } from 'features/DeviceList/slice';

function Filter() {
  const dispatch = useDispatch();
  const showActiveOnly = useSelector(selectShowActiveOnly);
  const onSearch = (value) => {
    dispatch(setSearch(value));
  };

  const toggleShowActiveOnly = () => {
    dispatch(setShowActiveOnly(!showActiveOnly));
  };

  return (
    <Box
      width={{ _: '100%', md: 'calc(100% - 228px)' }}
      height={63}
      borderBottom="1px solid"
      borderColor="gray"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={25}
      backgroundColor="white"
      left={{ _: 0, md: 230 }}
      zIndex={10}
      position="fixed"
    >
      <Space>
        <Text fontSize={18} fontWeight={600}>
          Devices
        </Text>
      </Space>
      <Box display={{ _: 'none', sm: 'block' }}>
        <Space>
          <Button onClick={toggleShowActiveOnly}>{showActiveOnly ? 'Show All Device' : 'Show Active Device'}</Button>
          <Input.Search allowClear placeholder="Search..." onSearch={onSearch} />
          {/* <FilterOutlined style={{ fontSize: '21px' }} onClick={() => { }} /> */}
        </Space>
      </Box>
      <Box display={{ _: 'block', sm: 'none' }}>
        <SearchOutlined style={{ fontSize: '21px' }} onClick={() => { }} />
      </Box>
    </Box>
  );
}

export default Filter;
