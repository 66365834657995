import { Button, Col, Form, Input, Row } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contactUser, selectPendingContactForm } from 'features/Users/slice';

function Content3() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const pendingContactForm = useSelector(selectPendingContactForm);
  const handleFinish = () => {
    form.validateFields().then((formData) => {
      dispatch(contactUser({ ...formData, type: 'reseller' }));
    });
  };

  return (
    <Box px={{ _: 20, sm: 40 }}>
      <Box maxWidth={1270} m="auto" backgroundColor="#5466E0" p={4}>
        <Text fontSize={{ _: 20, sm: 30 }} fontWeight={600} textAlign="center" color="white">Get started as an Image Engine Reseller today!</Text>
      </Box>
      <Box maxWidth={600} m="40px auto">
        <Text fontSize={{ _: 18, sm: 24 }} fontWeight={700} mb={6}>Become an Image Engine Reseller TODAY</Text>
        <Form form={form} onFinish={handleFinish}>
          <Row gutter={[20, 10]} style={{ margin: '0 auto', maxWidth: '800px' }}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: 'First Name is required!' }]}
              >
                <Input placeholder="First Name" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: 'Last Name is required!' }]}
              >
                <Input placeholder="Last Name" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="companyName"
              >
                <Input placeholder="Company Name" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="companyUrl"
              >
                <Input placeholder="Company URL" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Email is required!' }]}
              >
                <Input placeholder="Email Address" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                rules={[{ required: true, message: 'Phone Number is required!' }]}
              >
                <Input placeholder="Phone Number" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="city"
              >
                <Input placeholder="City" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="country"
              >
                <Input placeholder="Country" size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button loading={pendingContactForm} htmlType="submit" type="primary" size="large" style={{ width: '100%' }}>Submit</Button>
            </Col>
          </Row>
        </Form>
        {/* <iframe title="reseller-form" height={1200} width={600} style={{ border: 'none' }} src="https://cdn.forms-content.sg-form.com/ddfb6a96-2442-11ed-a5e5-96a621b1af87" /> */}
      </Box>
    </Box>
  );
}

export default Content3;
