import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { openErrorNotification } from 'common/helpers';

export const getSavedPlates = createAsyncThunk(
  'getSavedPlates',
  async (
    _,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const response = await api.get('/plate/list');

      console.log('getDeviceSavedPlates:', response);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const createSavedPlates = createAsyncThunk(
  'createSavedPlates',
  async (
    { deviceId, type, plate, description },
    { extra: { createAuthenticatedClient }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const response = await api.post('/plate/list', {
        deviceId, type, plate, description,
      });

      console.log('createDeviceSavedPlates:', response);

      dispatch(getSavedPlates());
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSavedPlates = createAsyncThunk(
  'deleteSavedPlates',
  async (
    { plateId },
    { extra: { createAuthenticatedClient }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const response = await api.delete(`/plate/list/${plateId}`);

      console.log('deleteDeviceSavedPlates:', response);
      dispatch(getSavedPlates());
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
const initialState = {
  deviceWhitelistedPlates: [],
  deviceBlacklistedPlates: [],
  pendingGet: false,
  pendingCreate: false,
  pendingDelete: false,
  error: null,
};

export const slice = createSlice({
  name: 'savedPlateNumbers',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSavedPlates.fulfilled, (state, { payload }) => {
        const whiteList = payload.filter((item) => item.type === 'White List');
        const blacklist = payload.filter((item) => item.type === 'Black List');
        state.deviceWhitelistedPlates = whiteList;
        state.deviceBlacklistedPlates = blacklist;
        state.pendingGet = false;
      })
      .addCase(getSavedPlates.pending, (state) => {
        state.pendingGet = true;
      })
      .addCase(getSavedPlates.rejected, (state) => {
        openErrorNotification('Failed to get Saved Plate Numbers.');
        state.pendingGet = false;
      })
      .addCase(createSavedPlates.fulfilled, (state) => {
        state.pendingCreate = false;
      })
      .addCase(createSavedPlates.pending, (state) => {
        state.pendingCreate = true;
      })
      .addCase(createSavedPlates.rejected, (state) => {
        openErrorNotification('Failed to save Plate Numbers.');
        state.pendingCreate = false;
      })
      .addCase(deleteSavedPlates.fulfilled, (state) => {
        state.pendingDelete = false;
      })
      .addCase(deleteSavedPlates.pending, (state) => {
        state.pendingDelete = true;
      })
      .addCase(deleteSavedPlates.rejected, (state) => {
        openErrorNotification('Failed to delete Plate Numbers.');
        state.pendingDelete = false;
      });
  },
});

export const { clearError } = slice.actions;

export const selectBlackListedPlates = (state) => state.savedPlateNumbers.deviceBlacklistedPlates;
export const selectWhiteListedPlates = (state) => state.savedPlateNumbers.deviceWhitelistedPlates;

export const selectPendingGet = (state) => state.savedPlateNumbers.pendingGet;
export const selectPendingCreate = (state) => state.savedPlateNumbers.pendingCreate;
export const selectPendingDelete = (state) => state.savedPlateNumbers.pendingDelete;

export default slice.reducer;
