import React, { useState } from 'react';
import { groupBy } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Typography, Tooltip, Input, Row, Col, Spin, Image, DatePicker, Pagination, message } from 'antd';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { RetweetOutlined } from '@ant-design/icons';
import * as S from '../styles';
import {
  retrieveDevicePhotos,
  selectPhotos,
  selectTotalPhotos,
  selectPendingPhotos,
} from '../slice';

const { Title } = Typography;
const { RangePicker } = DatePicker;

function Photos({ subscriptionId }) {
  const { id, photoId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const pendingPhotos = useSelector(selectPendingPhotos);

  const [dateRange, setDateRange] = useState([moment().subtract(1, 'month').startOf('day'), moment().endOf('day')]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [toggleSearchFilename, setToggleSearchFilename] = useState('search');

  const photos = useSelector(selectPhotos);
  const total = useSelector(selectTotalPhotos);
  const groupedPhotos = groupBy(photos, (photo) => photo.uploadTime.split(' ')[0]);

  const handleSelectPhoto = (photo) => {
    if (photo.id !== photoId) {
      history.push(`/app/devices/${photo.deviceId}/photo/${photo.id}`);
    }
  };

  const handleRetrievePhotos = async () => {
    const apiPayload = {
      id,
      subscriptionId,
      page,
      limit: 30,
      from: dateRange[0].toISOString(),
      to: dateRange[1].toISOString(),
    };

    if (toggleSearchFilename === 'filename') {
      apiPayload.fileName = search;
    } else {
      apiPayload.search = search;
    }

    const { payload } = await dispatch(retrieveDevicePhotos(apiPayload));

    if (payload?.data && payload.data[0]) {
      history.push(`/app/devices/${payload.data[0].deviceId}/photo/${payload.data[0].id}`);
    }

    if (payload?.data.length === 0) {
      message.info('No Image found. Try changing the date range.');
    }
  };

  useUpdateEffect(async () => {
    handleRetrievePhotos();
  }, [page]);

  useEffectOnce(() => {
    handleRetrievePhotos();
  });

  return (
    <S.Photos>
      <Spin spinning={pendingPhotos}>
        <Row justify="space-between">
          <Title level={3}>Photos</Title>
          <Pagination
            current={total === 0 ? 0 : page}
            pageSize={30}
            defaultCurrent={total === 0 ? 0 : 1}
            onChange={(val) => setPage(val)}
            simple
            size="small"
            total={total}
          />
        </Row>
        <Row justify="space-between">
          <Input.Search
            addonBefore={
              <Tooltip title={`Search by ${toggleSearchFilename === 'search' ? 'Filename' : 'Keyword'}`}>
                <RetweetOutlined
                  onClick={() => setToggleSearchFilename(toggleSearchFilename === 'search' ? 'filename' : 'search')}
                />
              </Tooltip>
          }
            style={{ marginBottom: '10px' }}
            placeholder={`Enter ${toggleSearchFilename === 'search' ? 'keyword or plate number' : 'filename'}`}
            onSearch={handleRetrievePhotos}
            onChange={(e) => setSearch(e.target.value)}
          />
          <RangePicker
            style={{ width: '100%' }}
            onChange={setDateRange}
            defaultValue={dateRange}
            ranges={{
              'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
              'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
              Today: [moment().startOf('day'), moment().endOf('day')],
              'This Week': [moment().startOf('week'), moment().endOf('week')],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
            showTime
            format="MMM-DD-YYYY  HH:mm"
          />
        </Row>
        <div className="photo-list-wrapper">
          {Object.keys(groupedPhotos).map((key) =>
            <div className="item" key={key}>
              <Title level={5}>{moment(key, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</Title>
              <Row gutter={5}>
                {groupedPhotos[key].map((photo) =>
                  <Col span={8} key={photo.id}>
                    <Image
                      title={photo.fileName}
                      style={{ cursor: 'pointer' }}
                      preview={false}
                      onClick={() => handleSelectPhoto(photo)}
                      src={photo.thumbnailUrl}
                    />
                  </Col>)}
              </Row>
            </div>)}
        </div>
      </Spin>
    </S.Photos>
  );
}

export default Photos;
