import React from 'react';
import Box from 'components/Box';
import Text from 'components/Text';
import { CheckCircleFilled } from '@ant-design/icons';
import colors from 'styles/colors';

function Content2() {
  return (
    <Box p={{ _: 20, sm: 40 }}>
      <Text as="h2" fontSize={{ _: 20, md: 30 }} fontWeight={600} mb={4} textAlign="center">Who can become a reseller?</Text>
      <Text maxWidth={860} m="0 auto 40px" textAlign="center">The scope of possible uses for our services is all encompassing, given we not only store image files but act on the information in them. While anyone can become a reseller, it is best suited to.</Text>
      <Box maxWidth={600} m=" 0 auto" display="flex" justifyContent="space-between" flexWrap="wrap" flexDirection={{ _: 'column', sm: 'row' }}>
        <Box display="flex" flex="0 0 50%" mb={4}>
          <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
          <Text ml={2}>Security companies</Text>
        </Box>
        <Box display="flex" flex="0 0 50%" mb={4}>
          <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
          <Text ml={2}>Large corporates</Text>
        </Box>
        <Box display="flex" flex="0 0 50%" mb={4}>
          <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
          <Text ml={2}>Camera retailers</Text>
        </Box>
        <Box display="flex" flex="0 0 50%" mb={4}>
          <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
          <Text ml={2}>Specialist providers such as those in the Solar CCTV industry</Text>
        </Box>
      </Box>
    </Box>
  );
}

export default Content2;
