import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import thumb1 from 'images/ai-image-recognition/thumb-1.jpg';
import thumb2 from 'images/ai-image-recognition/thumb-2.jpg';
import thumb3 from 'images/ai-image-recognition/thumb-3.jpg';
import thumb4 from 'images/ai-image-recognition/thumb-4.jpg';
import thumb5 from 'images/ai-image-recognition/thumb-5.jpg';
import { Col, Row } from 'antd';

function Content3() {
  return (
    <>
      <Box p={{ _: '40px 20px', md: '80px 40px' }}>
        <Text maxWidth={994} m="auto" textAlign="center" fontSize={{ _: 18, md: 24 }} fontWeight={600}>Because Image Engine uses images instead of video, minimal data is used. This means we can support a wide range of customers in the remotest of locations using either cellular or satellite data.</Text>
      </Box>
      <Box maxWidth={1244} width="100%" m="auto" p={{ _: 20, sm: 40 }} backgroundColor="#fbfbfb">
        <Row gutter={20}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Box as="figure" display="block" m="0 auto" maxWidth={420} width="100%">
              <img
                src={thumb1}
                alt="smoke detection"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Box display="flex" height="100%" flexDirection="column" textAlign="center" alignItems="center" justifyContent="center" pt={{ _: 4, md: 0 }}>
              <Text fontSize={{ _: 20, md: 30 }} fontWeight={600} mb={{ _: 2, md: 4 }}>Protecting Forests</Text>
              <Text fontSize={{ _: 16, md: 18 }}>
                Image Engine acts as type of fire and smoke detector.  It is now possible to detect smoke at great distances, giving firefighters the opportunity to respond to fires quickly.
              </Text>
            </Box>
          </Col>
        </Row>
      </Box>
      <Box maxWidth={1244} width="100%" m="auto" p={{ _: 20, sm: 40 }}>
        <Row gutter={20}>
          <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 0 }} order={{ _: 1, md: 0 }}>
            <Box display="flex" height="100%" flexDirection="column" textAlign="center" alignItems="center" justifyContent="center" pt={{ _: 4, md: 0 }}>
              <Text fontSize={{ _: 20, md: 30 }} fontWeight={600} mb={{ _: 2, md: 4 }}>Protecting Parks and Reserves</Text>
              <Text fontSize={{ _: 16, md: 18 }}>
                Image Engine can detect unauthorised vehicles, people or even boats in restricted areas. Notifying rangers when the restrictions are breached.
              </Text>
            </Box>
          </Col>
          <Col xs={{ span: 24, order: 0 }} md={{ span: 12, order: 1 }} order={{ _: 0, md: 1 }}>
            <Box as="figure" display="block" m="0 auto" maxWidth={420} width="100%">
              <img
                src={thumb2}
                alt="4WD vehicle detection"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Col>
        </Row>
      </Box>
      <Box maxWidth={1244} width="100%" m="auto" p={{ _: 20, sm: 40 }} backgroundColor="#fbfbfb">
        <Row gutter={20}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Box as="figure" display="block" m="0 auto" maxWidth={420} width="100%">
              <img
                src={thumb3}
                alt="intruder detection"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Box display="flex" height="100%" flexDirection="column" textAlign="center" alignItems="center" justifyContent="center" pt={{ _: 4, md: 0 }}>
              <Text fontSize={{ _: 20, md: 30 }} fontWeight={600} mb={{ _: 2, md: 4 }}>Construction Site Surveillance</Text>
              <Text fontSize={{ _: 16, md: 18 }}>
                Monitor vehicle and human movements during work hours. Detect unauthorised site access out of hours.
              </Text>
            </Box>
          </Col>
        </Row>
      </Box>
      <Box maxWidth={1244} width="100%" m="auto" p={{ _: 20, sm: 40 }}>
        <Row gutter={20}>
          <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 0 }}>
            <Box display="flex" height="100%" flexDirection="column" textAlign="center" alignItems="center" justifyContent="center" pt={{ _: 4, md: 0 }}>
              <Text fontSize={{ _: 20, md: 30 }} fontWeight={600} mb={{ _: 2, md: 4 }}>Commercial Yard Surveillance</Text>
              <Text fontSize={{ _: 16, md: 18 }}>
                Use your existing cameras to monitor your yard for intruders, smoke or fire.
              </Text>
            </Box>
          </Col>
          <Col xs={{ span: 24, order: 0 }} md={{ span: 12, order: 1 }}>
            <Box as="figure" display="block" m="0 auto" maxWidth={420} width="100%">
              <img
                src={thumb4}
                alt="fire, smoke and intruder detection"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Col>
        </Row>
      </Box>
      <Box maxWidth={1244} width="100%" m="auto" p={{ _: 20, sm: 40 }} backgroundColor="#fbfbfb">
        <Row gutter={20}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Box as="figure" display="block" m="0 auto" maxWidth={420} width="100%">
              <img
                src={thumb5}
                alt="people detection"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Box display="flex" height="100%" flexDirection="column" textAlign="center" alignItems="center" justifyContent="center" pt={{ _: 4, md: 0 }}>
              <Text fontSize={{ _: 20, md: 30 }} fontWeight={600} mb={{ _: 2, md: 4 }}>Residential monitoring</Text>
              <Text fontSize={{ _: 16, md: 18 }}>
                Detect vehicles and/or persons loitering in your driveway.
              </Text>
            </Box>
          </Col>
        </Row>
      </Box>
    </>
  );
}

export default Content3;
