import { Col, Radio, Row, Space } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import React, { useState, useEffect } from 'react';

function Storage() {
  const [imageSize, setImageSize] = useState(0.25);
  const [imagePerHour, setImagePerHour] = useState(1);
  const [planStorage, setPlanStorage] = useState(2);
  const [daysRetention, setDaysRetention] = useState(0);

  useEffect(() => {
    const totalImage = planStorage * (1000 / imageSize);

    setDaysRetention(totalImage / imagePerHour / 24);
  }, [imageSize, imagePerHour, planStorage]);

  return (
    <Box pt={40} pb={80}>
      <Box maxWidth={1400} m="auto" py={6} px={4} backgroundColor="secondary" color="white" textAlign="center">
        <Text fontSize={24} fontWeight={600}>Unsure of your storage requirements?</Text>
        <Text>Use our calculator below to assist with selecting the right plan.</Text>
      </Box>
      <Box backgroundColor="#E8EBFF" maxWidth={1400} m="0 auto 40px" py={4} px={4}>
        <Box maxWidth={1000} m="auto">
          <Row gutter={20}>
            <Col xs={{ span: 6 }} sm={{ span: 6 }}>
              <Box mb={4}>
                <Text fontSize={12} fontWeight={600} mb={2}>Select Image Size</Text>
                <Radio.Group value={imageSize} onChange={(e) => setImageSize(e.target.value)}>
                  <Space direction="vertical">
                    <Radio value={0.25}>1280 x 960</Radio>
                    <Radio value={0.423}>1920 x 1080</Radio>
                    <Radio value={0.642}>2048 x 1536</Radio>
                    <Radio value={1.45}>3072 x 2304</Radio>
                    <Radio value={2.57}>4096 x 3072</Radio>
                  </Space>
                </Radio.Group>
              </Box>
            </Col>
            <Col xs={{ span: 6 }} sm={{ span: 6 }}>
              <Box mb={4}>
                <Text fontSize={12} fontWeight={600} mb={2}>Estimated images uploaded per hour</Text>
                <Radio.Group value={imagePerHour} onChange={(e) => setImagePerHour(e.target.value)}>
                  <Space direction="vertical">
                    <Radio value={1}>1</Radio>
                    <Radio value={25}>25</Radio>
                    <Radio value={50}>50</Radio>
                    <Radio value={100}>100</Radio>
                    <Radio value={200}>200</Radio>
                    <Radio value={300}>300</Radio>
                    <Radio value={500}>500</Radio>
                  </Space>
                </Radio.Group>
              </Box>
            </Col>
            <Col xs={{ span: 6 }} sm={{ span: 6 }}>
              <Box mb={4}>
                <Text fontSize={12} fontWeight={600} mb={2}>Select Plan</Text>
                <Radio.Group value={planStorage} onChange={(e) => setPlanStorage(e.target.value)}>
                  <Space direction="vertical">
                    <Radio value={2}>Bronze 2GB</Radio>
                    <Radio value={5}>Silver 5GB</Radio>
                    <Radio value={10}>Gold 10GB</Radio>
                    <Radio value={20}>Platinum 20GB</Radio>
                  </Space>
                </Radio.Group>
              </Box>
            </Col>
            <Col xs={{ span: 6 }} sm={{ span: 6 }}>
              <Box>
                <Text fontSize={12} fontWeight={600} textAlign={{ _: 'left', sm: 'center' }} mb={4}>
                  Estimated days of image retention
                </Text>
                <Text fontSize={21} fontWeight={700} textAlign={{ _: 'left', sm: 'center' }}>
                  {daysRetention < 1 ? 'Less than 1 Day' : `${parseFloat(daysRetention.toString()).toFixed(1)} Days`}
                </Text>
              </Box>
            </Col>
          </Row>
        </Box>
      </Box>
      <Text fontSize={18} fontWeight={600} textAlign="center">Upgrades and downgrades</Text>
      <Text textAlign="center" maxWidth={1100} m="auto" mt={4} px={4}>
        When upgrading from a lower plan to a higher plan, the plan upgrade takes effect immediately and your credit card will be charged the prorated amount.
        When moving from a higher plan to a lower plan, the changes will be reflected on your billing anniversary.
      </Text>
    </Box>
  );
}

export default Storage;
