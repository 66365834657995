import { useSelector, useDispatch } from 'react-redux';
import {
  selectDeviceUsers,
  selectDeviceUserPendingInvites,
  deviceUsersGet,
  // devicePendingInviteGet,
  devicePendingInviteDelete,
  deviceUsersInvite,
  deviceUserRemove,
  deviceUserGetPermission,
  deviceUserUpdatePermission,
  selectPendingGetDeviceUsers,
  selectPendingGetDeviceUserPendingInvites,
  selectPendingDeleteDeviceUserPendingInvites,
  selectPendingDeviceInvite,
  selectPendingRemoveDeviceUser,
  selectPendingGetDeviceUserPermission,
  selectPendingUpdateDeviceUserPermission,
  selectDeviceUserPermission,
  setSelectedTab,
  selectSelectedTab,
} from './slice';

function useDeviceSharing() {
  const dispatch = useDispatch();
  const deviceUsers = useSelector(selectDeviceUsers);
  const deviceUserPendingInvites = useSelector(selectDeviceUserPendingInvites);
  const deviceUserPermission = useSelector(selectDeviceUserPermission);
  const pendingGetDeviceUsers = useSelector(selectPendingGetDeviceUsers);
  const pendingGetDeviceUserPendingInvites = useSelector(selectPendingGetDeviceUserPendingInvites);
  const pendingDeleteDeviceUserPendingInvite = useSelector(selectPendingDeleteDeviceUserPendingInvites);
  const pendingDeviceInvite = useSelector(selectPendingDeviceInvite);
  const pendingRemoveDeviceUser = useSelector(selectPendingRemoveDeviceUser);
  const pendingGetDeviceUserPermission = useSelector(selectPendingGetDeviceUserPermission);
  const pendingUpdateDeviceUserPermission = useSelector(selectPendingUpdateDeviceUserPermission);
  const selectedTab = useSelector(selectSelectedTab);

  const getDeviceUsers = (deviceId) => {
    return dispatch(deviceUsersGet(deviceId));
  };

  const getDeviceUserPendingInvites = () => {
    // TODO: Revert once pending device invites is re-enabled
    // dispatch(devicePendingInviteGet(deviceId));
  };

  const deleteDeviceUserPendingInvites = (deviceId, inviteId) => {
    dispatch(devicePendingInviteDelete({ deviceId, inviteId }));
  };

  const getDeviceUserPermission = (deviceId, deviceUserId) => {
    dispatch(deviceUserGetPermission({ deviceId, deviceUserId }));
  };

  const updateDeviceUserPermission = (deviceId, deviceUserId, values) => {
    dispatch(deviceUserUpdatePermission({ deviceId, deviceUserId, values }));
  };

  const setTab = (tab) => {
    dispatch(setSelectedTab(tab));
  };

  const inviteDeviceUsers = (deviceId, email) => {
    return dispatch(deviceUsersInvite({ deviceId, email }));
  };

  const removeDeviceUsers = (deviceId, deviceUserId) => {
    return dispatch(deviceUserRemove({ deviceId, deviceUserId }));
  };

  return {
    deviceUsers,
    deviceUserPendingInvites,
    setTab,
    selectedTab,
    deviceUserPermission,
    getDeviceUsers,
    getDeviceUserPendingInvites,
    deleteDeviceUserPendingInvites,
    getDeviceUserPermission,
    updateDeviceUserPermission,
    inviteDeviceUsers,
    removeDeviceUsers,
    pendingGetDeviceUsers,
    pendingGetDeviceUserPendingInvites,
    pendingDeleteDeviceUserPendingInvite,
    pendingDeviceInvite,
    pendingRemoveDeviceUser,
    pendingGetDeviceUserPermission,
    pendingUpdateDeviceUserPermission,
  };
}

export default useDeviceSharing;
