import React, { useState } from 'react';
import { Space, Select, Row, Col, Typography, Button, Form, Input, Tooltip, message } from 'antd';
import { CreditCardOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import useDeviceList from 'features/DeviceList/hooks';
import Box from 'components/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import * as S from './styles';
import { retrieveDevice, selectDevice, deviceUpdate, selectPendingUpdate } from '../DeviceDetail/slice';

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

function DeviceDetailUpdate(props) {
  const { deviceId } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const device = useSelector(selectDevice);
  const pendingUpdate = useSelector(selectPendingUpdate);

  useEffectOnce(() => {
    dispatch(retrieveDevice({ id: deviceId }));
  });

  useUpdateEffect(() => {
    dispatch(retrieveDevice({ id: deviceId }));
  }, [deviceId]);

  useUpdateEffect(() => {
    const { name, description, deviceGroupId } = device;
    form.setFieldsValue({ name, description, deviceGroupId });
  }, [device]);

  const handleDeviceUpdate = async (formData) => {
    const response = await dispatch(deviceUpdate({ deviceId, ...formData }));
    if (response.meta.requestStatus === 'fulfilled') {
      message.success('Device Details Updated!');
    }
  };

  const [createNewGroup, setCreateNewGroup] = useState(false);

  const { groupedDevices } = useDeviceList();

  return (
    <S.StyledDeviceDetails>
      <Box p={{ _: 20, sm: 30 }}>
        <Space style={{ margin: '0 0 20px 0' }}>
          <CreditCardOutlined style={{ fontSize: '21px' }} />
          <Title level={4} style={{ margin: '0' }}>
            Device Details
          </Title>
        </Space>
        <Form form={form} onFinish={handleDeviceUpdate} layout="vertical">
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Description" name="description">
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            {
              (createNewGroup || groupedDevices.length === 0) ?
                <Col span={24}>
                  {
                    groupedDevices.length !== 0 &&
                    <>
                      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
                      <span role="button" className="add-new-group" onClick={() => setCreateNewGroup(false)}>
                        Click here to select from existing groups
                      </span>
                    </>
                  }
                  <Form.Item label="New Group" name="newDeviceGroupName" rules={[{ required: true, message: 'This field is required!' }]}>
                    {groupedDevices.length === 0 ?
                      <Input /> :
                      <Input
                        suffix={
                          <Tooltip title="Use existing Device Group">
                            <MenuOutlined
                              onClick={() => setCreateNewGroup(false)}
                              style={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        }
                      />
                    }
                  </Form.Item>
                </Col>
                :
                <Col span={24}>
                  {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
                  <span role="button" className="add-new-group" onClick={() => setCreateNewGroup(true)}>
                    Click here to create new group
                  </span>
                  <Form.Item label="Select from existing groups" name="deviceGroupId" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Select
                      suffixIcon={
                        <Tooltip title="Create New Device Group">
                          <PlusOutlined
                            onClick={() => setCreateNewGroup(true)}
                            style={{ cursor: 'pointer', color: '#333' }}
                          />
                        </Tooltip>
                      }
                    >
                      {groupedDevices.map((item) =>
                        <Option value={item.id} key={item.id}>{item.name}</Option>)}
                    </Select>

                  </Form.Item>
                </Col>
            }
          </Row>
          <Row gutter={20} style={{ marginTop: '10px' }}>
            <Col span={24}>
              <Button
                htmlType="submit"
                type="primary"
                loading={pendingUpdate}
                block
              >
                Change Details
              </Button>
            </Col>
          </Row>
        </Form>
      </Box>
    </S.StyledDeviceDetails>
  );
}

export default DeviceDetailUpdate;
