import React from 'react';
import Box from 'components/Box';
import Text from 'components/Text';
import headerThumb from 'images/reseller/header-thumb.png';
import { Space } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import colors from 'styles/colors';

function Header() {
  return (
    <Box
      background="linear-gradient(270deg, #5466E0 24.33%, rgba(118, 131, 215, 0.67) 58.49%, rgba(196, 196, 196, 0) 99.37%)"
      p={{ _: '40px 20px', lg: '80px 40px' }}
    >
      <Box maxWidth={1320} m="auto" display="flex" justifyContent="space-between">
        <Box maxWidth={600}>
          <Text as="h1" fontSize={{ _: 30, md: 40 }} lineHeight={1.2} fontWeight={600}>
            Become An Image <br />Engine Reseller
          </Text>
          <Text fontSize={{ _: 16, md: 18 }} lineHeight={1.4} my={4}>
            Sell our world leading AI image recognition and security camera cloud storage and increase your bottom line.  Receive recurring revenue for years to come while offering your customers a unique service.
          </Text>
          <Space direction="vertical">
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text fontSize={{ _: 16, md: 18 }} ml={2}>Earn Recurring Revenue</Text>
            </Box>
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text fontSize={{ _: 16, md: 18 }} ml={2}>No initial investment or minimum spend required</Text>
            </Box>
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text fontSize={{ _: 16, md: 18 }} ml={2}>Simple registration</Text>
            </Box>
          </Space>
        </Box>
        <Box display={{ _: 'none', md: 'block' }}>
          <img src={headerThumb} alt="Reseller Portal" />
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
