import React from 'react';
import { Spin, Tag } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPendingPhoto,
  selectPhoto,
  setRectangle,
} from '../slice';

function Detections() {
  const photo = useSelector(selectPhoto);

  const dispatch = useDispatch();
  const pendingPhoto = useSelector(selectPendingPhoto);

  const handleSelectRectangle = (item) => {
    // rectangle can start at location 0
    if (item.x || item.y || item.height || item.width) {
      dispatch(setRectangle({ x: item.x, y: item.y, h: item.height, w: item.width }));
    } else {
      dispatch(setRectangle({}));
    }
  };

  const detections = [
    { name: 'Detected Tags', type: 'Tag' },
    { name: 'Detected Objects', type: 'Object' },
    { name: 'Detected Fire', type: 'Fire' },
    { name: 'Detected Pedestrians', type: 'Pedestrian' },
    { name: 'Recognized Plates', type: 'Plate' },
  ];

  return (
    <Box mt={4}>
      <Spin spinning={pendingPhoto}>
        {photo?.detections?.length > 0 &&
        <>
          {
            detections.map((detection) => photo?.detections?.some((p) => p.type === detection.type) &&
              <Box key={detection.id} py={2} px={4} display="flex" alignItems="center" justifyContent="space-between" backgroundColor="white" borderRadius={4} mt={2}>
                <Box>
                  <Text fontWeight={600}>{ detection.name }: </Text>
                  <br />
                  <Text fontSize={12} color="gray_5">
                    {photo.detections.map((item) => {
                      if (item.type === detection.type) {
                        return (
                          <Tag key={item.id} style={{ marginBottom: '8px' }} onMouseEnter={() => handleSelectRectangle(item)}>
                            <b style={{ textTransform: 'capitalize' }}>{item.name}</b> : {item.score}%
                          </Tag>
                        );
                      }
                      return null;
                    })}
                  </Text>
                </Box>
              </Box>)
          }
        </>
        }
      </Spin>
    </Box>
  );
}

export default Detections;
