import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import icon1 from 'images/reseller/icon-1.svg';
import icon2 from 'images/reseller/icon-2.svg';
import icon3 from 'images/reseller/icon-3.svg';
import icon4 from 'images/reseller/icon-4.svg';
import icon5 from 'images/reseller/icon-5.svg';

function Content1() {
  return (
    <Box p={{ _: '40px 20px', lg: '80px 40px' }}>
      <Text as="h2" fontSize={{ _: 20, md: 30 }} fontWeight={600} mb={6} textAlign="center">Reselling made simple</Text>
      <Box maxWidth={1300} m="auto" display="flex" justifyContent="center" flexWrap="wrap" flexDirection={{ _: 'column', sm: 'row' }}>
        <Box m="0 auto" maxWidth={250} width="100%" textAlign="center" px={3} flex={{ _: '0 0 50%', lg: '0 0 20%' }} pb={4}>
          <Box as="figure" height={100} display="flex" alignItems="center" justifyContent="center" mb={3}>
            <img src={icon1} alt="Simple Interface" />
          </Box>
          <Text fontSize={{ _: 18, sm: 20 }} fontWeight={600} color="secondary">Simple Interface</Text>
          <Text mt={2}>Our user-friendly portal makes managing customers a breeze. </Text>
        </Box>
        <Box m="0 auto" maxWidth={250} width="100%" textAlign="center" px={3} flex={{ _: '0 0 50%', lg: '0 0 20%' }} pb={4}>
          <Box as="figure" height={100} display="flex" alignItems="center" justifyContent="center" mb={3}>
            <img src={icon2} alt="Scalable Solution" />
          </Box>
          <Text fontSize={{ _: 18, sm: 20 }} fontWeight={600} color="secondary">Scalable Solution</Text>
          <Text mt={2}>Our user capacity is endless so we can work with a customer base of any size.</Text>
        </Box>
        <Box m="0 auto" maxWidth={250} width="100%" textAlign="center" px={3} flex={{ _: '0 0 50%', lg: '0 0 20%' }} pb={4}>
          <Box as="figure" height={100} display="flex" alignItems="center" justifyContent="center" mb={3}>
            <img src={icon3} alt="Support" />
          </Box>
          <Text fontSize={{ _: 18, sm: 20 }} fontWeight={600} color="secondary">Support</Text>
          <Text mt={2}>Our dedicated support team are here to help if you have any questions about services.</Text>
        </Box>
        <Box m="0 auto" maxWidth={250} width="100%" textAlign="center" px={3} flex={{ _: '0 0 50%', lg: '0 0 20%' }} pb={4}>
          <Box as="figure" height={100} display="flex" alignItems="center" justifyContent="center" mb={3}>
            <img src={icon4} alt="Customisable platform" />
          </Box>
          <Text fontSize={{ _: 18, sm: 20 }} fontWeight={600} color="secondary">Customisable platform</Text>
          <Text mt={2}>We tailor our solution to suit your needs through co-branding or white labelling.</Text>
        </Box>
        <Box m="0 auto" maxWidth={250} width="100%" textAlign="center" px={3} flex={{ _: '0 0 50%', lg: '0 0 20%' }} pb={4}>
          <Box as="figure" height={100} display="flex" alignItems="center" justifyContent="center" mb={3}>
            <img src={icon5} alt="Continuous product development" />
          </Box>
          <Text fontSize={{ _: 18, sm: 20 }} fontWeight={600} color="secondary">Continuous product development</Text>
          <Text mt={2}>We have plenty of ideas in the pipeline which will only increase demand for our solution.</Text>
        </Box>
      </Box>
    </Box>
  );
}

export default Content1;
