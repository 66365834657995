import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';

function Content1() {
  return (
    <Box py={60} px={4} maxWidth={700} m="auto">
      <Text mb={4}>
        Image Engine is an image analysis platform which had been designed from the ground up using the massively scalable architecture and resources available in the cloud.  We use your security camera data to provide business intelligence that was previously unobtainable.
      </Text>
      <Text mb={6}>
        Our secure platform unifies disparate systems without any infrastructure upgrades. It is capable of processing images from remote solar powered CCTV cameras through to large Network Video Recorders. With unlimited storage and image processing capability, Image Engine can scale from one to thousands of cameras easily.
      </Text>
      <Text fontSize={{ _: 26, md: 36 }} mb={4} fontWeight={700}>
        Who created Image Engine
      </Text>
      <Text mb={4}>
        The Image Engine concept was born through necessity.  Image Engine’s founder, Brendon Shervell originally owned and operated Perimeter Security, a Solar powered CCTV Camera company. He discovered that there was a gap in the market when a government department wanted to derive statistical data from still images created by their remote CCTV cameras.
      </Text>
      <Text>
        Determined to find a cost-effective solution he set about creating Image Engine.
      </Text>
    </Box>
  );
}

export default Content1;
