import Invoice from 'features/Invoice';
import React from 'react';

function InvoicePage() {
  return (
    <Invoice />
  );
}

export default InvoicePage;
