import React from 'react';
import Box from 'components/Box';
import bgHeader from 'images/pricing/bg-header.jpg';
import Text from 'components/Text';

function Header() {
  return (
    <Box
      width="100%"
      background={`#fff url(${bgHeader}) bottom right no-repeat`}
      p={{ _: '40px 20px', lg: '120px 40px' }}
      borderBottom="1px solid"
      borderColor="gray"
    >
      <Box maxWidth={1320} m="auto">
        <Box maxWidth={600}>
          <Text as="h1" fontSize={{ _: 30, md: 40 }} lineHeight={1.2} fontWeight={600}>
            Plans and Pricing
          </Text>
          <Text fontSize={{ _: 16, md: 18 }} lineHeight={1.4} my={4}>
            Select the storage required and the amount of images that are to be processed by Image Engine and you are all set to go. Our storage calculator will help you determine what plan you require.
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
