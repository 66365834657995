import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'components/Logo';
import Box from 'components/Box';
import footerBg from 'images/footer-bg.jpg';
import Text from 'components/Text';

function Footer() {
  return (
    <Box as="footer" p={{ _: '40px 20px 100px', sm: '80px 40px 150px' }} background={`url(${footerBg}) bottom center/cover no-repeat`}>
      <Box maxWidth={1150} m="auto" display="flex" justifyContent="center" mb={30} flexWrap="wrap" flexDirection={{ _: 'column', sm: 'row' }} textAlign="center">
        <Text as={Link} to="/ai-image-recognition" mx={4} mb={2}>AI Image Recognition</Text>
        <Text as={Link} to="/camera-cloud-storage" mx={4} mb={2}>Camera Cloud Storage</Text>
        <Text as={Link} to="/reseller" mx={4} mb={2}>Reseller</Text>
        <Text as={Link} to="/pricing" mx={4} mb={2}>Pricing</Text>
        <Text as={Link} to="/about-us" mx={4} mb={2}>About Us</Text>
        <Text as={Link} to="/about-us#contact" mx={4} mb={2}>Contact</Text>
        <Text as={Link} to="/legal/terms-and-conditions" mx={4} mb={2}>Terms and Conditions</Text>
        <Text as={Link} to="/legal/privacy-policy" mx={4} mb={2}>Privacy Policy</Text>
      </Box>
      <Box textAlign="center">
        <Logo />
        <Text mt={3}>
          support@imageengine.ai
          <br />
          Copyright 2021 ImageEngine. All Rights Reserved.
        </Text>
      </Box>
    </Box>
  );
}

export default Footer;
