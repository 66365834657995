import axios from 'axios';
import { message } from 'antd';
import localStorage from 'localStorage';
import applyCaseMiddleware from 'axios-case-converter';

import { handleLogout } from 'common/auth0';
import { handleAPIError } from '../common/helpers';

const caseMiddleware = {
  requestTransformer: (config) => {
    // Disable query string transformation
    return config;
  },
};

/**
 *
 * creates an api client
 *
 */
const api = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  { caseMiddleware, preservedKeys: ['deviceId'] },
);

const timeout = 60_000;

const handleResponse = (response) => {
  let result;
  // const history = useHistory();
  const { code, message: msg, data } = response.data;
  if (code === 0) {
    // success
    result = data;
  } else {
    // reject
    // eslint-disable-next-line no-lonely-if
    if (msg === 'jwt expired') {
      handleLogout();
      message.info('Session token has expired, Please login again.');
    } else {
      message.error(`ERROR: ${msg}`);
    }
  }

  return result;
};

api.interceptors.response.use(handleResponse, (error) => {
  handleAPIError(error, 'API Error');
  return Promise.reject(error);
});

/**
 *
 * creates an api client with authentication headers
 *
 */
export function createAuthenticatedClient() {
  const authenticatedApi = applyCaseMiddleware(
    axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
      },
    }),
    {
      ignoreHeaders: false,
      caseMiddleware,
      preservedKeys: ['deviceId'],
    },
  );

  authenticatedApi.interceptors.response.use(handleResponse, (error) => {
    handleAPIError(error, 'API Error');
    return Promise.reject(error);
  });

  return authenticatedApi;
}

export default api;
