import GuestNav from 'components/GuestNav';
import React from 'react';
import GetStarted from 'components/GetStarted';
import Box from 'components/Box';
import Footer from 'components/Footer';

import Header from './Header';
import Content1 from './Content1';
import Content2 from './Content2';
import Content3 from './Content3';
import Content4 from './Content4';

function AiImageRecognition() {
  return (
    <>
      <GuestNav />
      <Box pt={80}>
        <Header />
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <GetStarted />
      </Box>
      <Footer />
    </>
  );
}

export default AiImageRecognition;
