import React, { useState } from 'react';
import {
  Space,
  Select,
  Row,
  Col,
  Typography,
  Button,
  Alert,
  Card,
  Form,
  InputNumber,
  Tabs,
  Input,
  notification,
} from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import useProductList from 'features/ProductsList/hooks';
import { useUpdateEffect, useEffectOnce } from 'react-use';

import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';

import { deviceAddOns } from 'features/DeviceAddModal/slice';
import useAddDevice from 'features/DeviceAddModal/hooks';

import { openSuccessNotification } from 'common/helpers';
import { createAuthenticatedClient } from 'app/client';
import useDeviceSubscription from './hooks';
import { StyledDeviceSubscription } from './styles';
import { deviceRevertCancelSubscription, deviceSubscriptionCancel } from './slice';

const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const REQUIRED = [{ required: true, message: 'This field is required!' }];

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#5466E0',
      color: 'rgba(0, 0, 0, 0.85)',
      fontSize: '14px',
    },
    invalid: {
      iconColor: '#f74242',
      color: '#f74242',
    },
  },
};
// const devicePlanDefaultStorage = {
//   Platinum: 20,
//   Gold: 10,
//   Silver: 5,
//   Bronze: 2,
//   Copper: 2,
// };

function DeviceSubscription(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { cards } = useAddDevice();

  const { deviceId, showStorage = false } = props;
  const { deviceProductsByCode, deviceAddOnsByCode } = useProductList();
  const {
    deviceSubscription,
    deviceSubscriptions,
    changeDeviceSubscription,
    getDeviceSubscription,
    getSubscriptions,
    pendingChange,
  } = useDeviceSubscription();
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [existingAddOnsObject, setExistingAddOnsObject] = useState({});

  const [processing, setProcessing] = useState(false);
  const [pendingCancel, setPendingCancel] = useState(false);
  const [selectedCard, setSelectedCard] = useState(undefined);
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);

  const handleSelectPaymentMethod = (selectedPaymentMethod) => {
    setSelectedCard(selectedPaymentMethod);
  };

  const handleChangSubscription = async () => {
    const addOns = await form.getFieldsValue();
    const addons = [];
    Object.keys(addOns).map((key) => {
      if (addOns[key] > 0) {
        addons.push({
          _id: existingAddOnsObject[key]?.id,
          code: key,
          quantity: addOns[key],
        });
      }
      return false;
    });

    if (showStorage) {
      await changeDeviceSubscription(selectedSubscriptionId, {
        immediately: true,
        addons,
        deviceId,
      });
    } else {
      await changeDeviceSubscription(selectedSubscriptionId, {
        immediately: true,
        newSubscriptionType: selectedProduct,
        deviceId,
      });
    }

    getDeviceSubscription({ subscriptionId: selectedSubscriptionId });
  };

  const handleCancelSubscription = async () => {
    setPendingCancel(true);
    const response = await dispatch(deviceSubscriptionCancel({ subscriptionId: selectedSubscriptionId, reason: 'Cancel from Subscription Settings' }));

    if (response.payload) {
      await getDeviceSubscription({ subscriptionId: selectedSubscriptionId });
      notification.info({
        title: 'Cancel Subscription',
        description: 'Subscription is now for cancellation. You can still revert this action before the end of the billing cycle.',
        duration: 10,
      });
    }
    setPendingCancel(false);
  };

  const handleRevertCancelSubscription = async () => {
    setPendingCancel(true);
    const response = await dispatch(deviceRevertCancelSubscription({ subscriptionId: selectedSubscriptionId }));

    if (response.payload) {
      await getDeviceSubscription({ subscriptionId: selectedSubscriptionId });
      notification.info({
        title: 'Subscription Cancellation Reverted',
        description: 'Subscription cancellation is now reverted.',
        duration: 5,
      });
    }

    setPendingCancel(false);
  };

  const handlePaymentThatRequiresCustomerAction = (result) => {
    console.log('handlePaymentThatRequiresCustomerAction: ', result);
    const { confirmationId, paymentMethodId } = result;
    if (!confirmationId) {
      // No customer action needed
      return result;
    }

    return stripe
      .confirmCardPayment(confirmationId, {
        payment_method: paymentMethodId,
      })
      .then((cardResult) => {
        if (cardResult.error) {
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          throw cardResult.error;
        } else if (cardResult.paymentIntent.status === 'succeeded') {
          // There's a risk of the customer closing the window before callback
          // execution. To handle this case, set up a webhook endpoint and
          // listen to invoice.paid. This webhook endpoint returns an Invoice.
          return result;
        }

        throw new Error('Unhandled Customer Action');
      });
  };

  const handleDeviceCreate = async (paymentMethodId, cardId, addons) => {
    const payload = {
      subscriptionId: selectedSubscriptionId,
      data: {
        newPaymentMethodId: paymentMethodId,
        cardId,
        addons,
      },
    };
    setProcessing(true);
    const result = await dispatch(deviceAddOns(payload));

    if (result.payload) {
      const { invoice, followUpId, confirmationId } = result.payload;

      if (invoice) {
        openSuccessNotification({
          message: 'Add-Ons!',
          description: 'Items are now added to your subscription!',
        });

        form.resetFields();
        setProcessing(false);
        setTotalAmount(0);
        setSelectedCard(undefined);
      } else if (confirmationId) {
        await handlePaymentThatRequiresCustomerAction({ confirmationId, paymentMethodId });

        const api = createAuthenticatedClient();
        await api.post(`subscription/${followUpId}/follow-ups`);

        openSuccessNotification({
          message: 'Add-Ons!',
          description: 'Items are now added to your subscription!',
        });
      } else {
        throw new Error('Unhandled subscription addons error');
      }
    }
  };

  const handleProcessPayment = async () => {
    const formData = form.getFieldsValue();
    const addons = [];

    Object.keys(formData).map((key) => {
      if (['addon-AI1000', 'addon-AI5000', 'addon-Camera'].includes(key) && formData[key] > 0) {
        addons.push({
          productId: key.split('-')[1],
          quantity: formData[key],
        });
      }
      return false;
    });

    if (addons.length > 0) {
      if (!selectedCard) {
        notification.info({
          title: 'Payment Method',
          description: 'Please select a payment method.',
        });
      } else if (selectedCard === 'new') {
        form.validateFields().then(async (cardDetails) => {
          if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
          }

          if (error) {
            elements.getElement('card').focus();
            return;
          }

          if (cardComplete) {
            setProcessing(true);
          }

          const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
              name: cardDetails.nameOnCard,
              phone: cardDetails.phone,
              email: cardDetails.email,
            },
          });

          if (payload.error) {
            setError(payload.error);
          } else {
            const { paymentMethod } = payload;
            const paymentMethodId = paymentMethod.id;

            await handleDeviceCreate(paymentMethodId, undefined, addons);
          }
        });
      } else {
        await handleDeviceCreate(undefined, selectedCard, addons);
      }

      setProcessing(false);
    } else {
      notification.info({
        title: 'Add-Ons',
        description: 'Please select at least one add-on',
      });
    }
  };

  const handleFormChanges = (changedValues, allValues) => {
    const total = ((allValues['addon-AI1000'] || 0) * deviceAddOnsByCode.AI1000.price) +
        ((allValues['addon-AI5000'] || 0) * deviceAddOnsByCode.AI5000.price) +
        ((allValues['addon-Camera'] || 0) * deviceAddOnsByCode.Camera.price);
    setTotalAmount(total);
  };

  useUpdateEffect(() => {
    if (selectedSubscriptionId) {
      getDeviceSubscription({ subscriptionId: selectedSubscriptionId });
    }
  }, [selectedSubscriptionId]);

  useUpdateEffect(() => {
    // let storageValue = devicePlanDefaultStorage[deviceSubscription.productId];
    const recurringAddOns = {};
    const addOnObject = {};
    // let storageAmount = 0;

    if (deviceSubscription.items && deviceSubscription.items.length > 1) {
      deviceSubscription.items.map((item) => {
        recurringAddOns[item.code] = item.quantity;
        addOnObject[item.code] = item;
        if (deviceAddOnsByCode[item.code] && deviceAddOnsByCode[item.code].value) {
          // storageValue += (item.quantity * deviceAddOnsByCode[item.code].value);
          // storageAmount += (item.quantity * deviceAddOnsByCode[item.code].price);
        }
        return false;
      });

      delete recurringAddOns[deviceSubscription.productId];
      form.setFieldsValue(recurringAddOns);
    }

    setExistingAddOnsObject(addOnObject);
  }, [deviceSubscription]);

  useUpdateEffect(() => {
    console.log('deviceSubscriptions:', deviceSubscriptions);

    if (deviceSubscriptions.length > 0) {
      setSelectedSubscriptionId(deviceSubscriptions[0]?.id);
    }
  }, [deviceSubscriptions]);

  useEffectOnce(() => {
    getSubscriptions();
  });

  return (
    <StyledDeviceSubscription p={{ _: 20, sm: 30 }}>

      <Space style={{ margin: '0 0 20px 0' }}>
        <CreditCardOutlined style={{ fontSize: '21px' }} />
        <Title level={4} style={{ margin: '0' }}>
          Device Subscription
        </Title>
      </Space>
      <Row gutter={15}>
        <Col span={24}>
          {(deviceSubscriptions.length > 0) &&
            <Select
              onChange={(val) => setSelectedSubscriptionId(val)}
              defaultValue={deviceSubscriptions[0]?.id}
              style={{ width: '100%', marginBottom: '20px' }}
            >
              {
              deviceSubscriptions.map((subscription) => {
                return (
                  <Option value={subscription.id} key={subscription.id}>
                    {`${subscription.productId} - ${subscription.recurringPeriod} [${subscription.id.split('-')[0].toUpperCase()}]`}
                  </Option>
                );
              })
            }
            </Select>}
        </Col>
      </Row>
      {deviceSubscription.productId &&
        <Tabs>
          <TabPane tab="Plans" key="plans">
            <Col span={12}>
              <Row gutter={10}>
                <Col span={16} offset={4} style={{ textAlign: 'center' }}>
                  <h3>Current Subscription<br />{`${deviceSubscription.productId} - ${deviceSubscription.recurringPeriod} [${deviceSubscription.id.split('-')[0].toUpperCase()}]`}</h3>
                </Col>
              </Row>
              <Row gutter={10} style={{ minHeight: '300px' }}>
                <Col span={18} offset={3}>
                  <Select
                    style={{ width: '100%', marginTop: '30px', marginBottom: '30px' }}
                    defaultActiveFirstOption={false}
                    placeholder="Please select a new Plan"
                    onChange={(product) => setSelectedProduct(product)}
                  >
                    {Object.keys(deviceProductsByCode).map((key) => {
                      if (deviceProductsByCode[key].code !== deviceSubscription.productId &&
                          deviceProductsByCode[key].code !== 'FREE') {
                        return (
                          <Option
                            value={deviceProductsByCode[key].code}
                            key={deviceProductsByCode[key].id}
                          >
                            {`${deviceProductsByCode[key].price} ${deviceProductsByCode[key].currency} - ${deviceProductsByCode[key].name}`}
                          </Option>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </Col>
              </Row>
              <Row gutter={20} style={{ marginTop: '10px' }}>
                <Col span={24}>
                  <Alert
                    message={
                      <p style={{ textAlign: 'center' }}>
                        Changes will be immediately applied, you will be charged for
                        the price difference. See
                        &nbsp;<a href="https://imageengine.ai/pricing/" target="_blank" rel="noopener noreferrer">Plan Storage</a>
                      </p>
                      }
                    type="info"
                  />
                  <Button
                    disabled={selectedProduct === ''}
                    loading={pendingChange}
                    onClick={() => handleChangSubscription()}
                    type="primary"
                    block
                  >
                    {selectedProduct !== '' ?
                      `Proceed with ${selectedProduct.toUpperCase()} PLAN`
                      :
                      'Please select a new Plan'
                      }
                  </Button>

                </Col>
              </Row>
            </Col>
          </TabPane>
          {deviceSubscription.productId === 'Platinum' && (
            <TabPane tab="Add-Ons" key="add-ons">
              <Form form={form} onValuesChange={handleFormChanges} layout="vertical">
                <Col span={24}>
                  <Row gutter={10} style={{ minHeight: '300px' }}>
                    <Col span={18} offset={3}>

                      <Row gutter={20}>
                        {Object.keys(deviceAddOnsByCode).map((key) => {
                          const addOn = deviceAddOnsByCode[key];
                          if (addOn.recurringPeriod === 'None' && addOn.id !== 'Camera') {
                            return (
                              <Col span={8} key={key}>
                                <Card title={addOn.name}>
                                  {`${addOn.currencySymbol}${addOn.price}`}
                                  <br />
                                  {addOn.description}
                                </Card>
                                <Form.Item name={`addon-${addOn.id}`} initialValue={0}>
                                  <InputNumber min={0} addonBefore="QTY" style={{ width: '100%' }} />
                                </Form.Item>
                              </Col>
                            );
                          }
                          return null;
                        })}
                      </Row>

                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col span={16} offset={4}>
                      <Select
                        placeholder="Select a payment method"
                        onChange={(val) => handleSelectPaymentMethod(val)}
                        value={selectedCard}
                        style={{ width: '100%', marginBottom: '20px' }}
                      >
                        {
                          cards.map((card) => {
                            return (
                              <Option value={card.id} key={card.id}>
                                {`${card.brand.toUpperCase()} card ending with ${card.last4} and expires on ${card.expMonth}/${card.expYear}`}
                              </Option>
                            );
                          })
                        }
                        <Option value="new">
                          New Card
                        </Option>
                      </Select>
                    </Col>
                  </Row>
                  {selectedCard === 'new' &&
                    <Row gutter={20}>
                      <Col span={16} offset={4}>
                        <Form.Item label="Name on Card" name="nameOnCard" rules={REQUIRED}>
                          <Input />
                        </Form.Item>
                        <Form.Item label="Phone" name="phone" rules={REQUIRED}>
                          <Input />
                        </Form.Item>
                        <Form.Item label="Email" name="email" rules={REQUIRED}>
                          <Input type="email" />
                        </Form.Item>
                      </Col>
                      <Col span={16} offset={4} className="credit-card-box">
                        <CardElement
                          options={CARD_OPTIONS}
                          onChange={(e) => {
                            setError(e.error);
                            setCardComplete(e.complete);
                          }}
                        />
                      </Col>
                    </Row>
                  }
                  <Row gutter={20} style={{ marginTop: '10px' }}>
                    <Col span={16} offset={4}>
                      <Typography.Text style={{ width: '100%', textAlign: 'center', display: 'block' }}>
                        Total: ${totalAmount} USD
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row gutter={20} style={{ marginTop: '10px' }}>
                    <Col span={16} offset={4}>
                      <Button
                        loading={processing}
                        onClick={() => handleProcessPayment()}
                        type="primary"
                        block
                      >
                        Proceed
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </TabPane>
          )}
          <TabPane tab="Cancel" key="cancel">
            <Row gutter={10}>
              <Col span={16} offset={4} style={{ textAlign: 'center' }}>
                <h3>Cancel Subscription<br />{`${deviceSubscription.productId} - ${deviceSubscription.recurringPeriod} [${deviceSubscription.id.split('-')[0].toUpperCase()}]`}</h3>
              </Col>
            </Row>
            <Row gutter={20} style={{ marginTop: '10px' }}>
              <Col span={16} offset={4}>
                <Alert
                  message={
                    <p style={{ textAlign: 'center' }}>
                      {deviceSubscription.isForCancellation ?
                        `Your subscription is for cancellation, you can still revert this before your subscription expire on ${deviceSubscription.periodEnd}` :
                        'Cancelling your subscription will stop all your devices from uploading images to ImageEngine.'}
                    </p>
                  }
                  type="danger"
                />
                {deviceSubscription.isForCancellation ?
                  <Button
                    loading={pendingCancel}
                    onClick={() => handleRevertCancelSubscription()}
                    type="primary"
                    block
                  >
                    Revert Cancellation
                  </Button> :
                  <Button
                    loading={pendingCancel}
                    onClick={() => handleCancelSubscription()}
                    type="danger"
                    block
                  >
                    Cancel Subscription
                  </Button>}

              </Col>
            </Row>
          </TabPane>
        </Tabs>
      }

    </StyledDeviceSubscription>
  );
}

export default DeviceSubscription;
