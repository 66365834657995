import React, { useState } from 'react';
import { groupBy } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Typography, Row, Col, Spin, Image, DatePicker, Pagination, Button, message, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserGalleries,
  selectGallery,
  selectTotalGallery,
  selectPendingGetGalleries,
} from 'features/Users/slice';
import useDeviceList from 'features/DeviceList/hooks';
import * as S from './styles';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

function Gallery() {
  const { photoId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { allDevices } = useDeviceList();

  const pendingPhotos = useSelector(selectPendingGetGalleries);

  const [dateRange, setDateRange] = useState([moment().startOf('week'), moment().endOf('week')]);
  const [page, setPage] = useState(1);
  const [selectedDevices, setSelectedDevices] = useState([]);

  const gallery = useSelector(selectGallery);
  const total = useSelector(selectTotalGallery);
  const groupedGallery = groupBy(gallery, (photo) => photo.date);

  const handleSelectPhoto = (photo) => {
    if (photo.id !== photoId) {
      history.push(`/app/devices/${photo.deviceId}/photo/${photo.id}`);
    }
  };

  const handleRetrievePhotos = async () => {
    const { payload: { data } } = await dispatch(getUserGalleries({
      deviceId: selectedDevices,
      page,
      from: dateRange[0].toISOString(),
      to: dateRange[1].toISOString(),
    }));

    if (data.length === 0) {
      message.info('No Image found. Try changing the date range.');
    }
  };

  useUpdateEffect(() => {
    handleRetrievePhotos();
  }, [page]);

  useUpdateEffect(() => {
    handleRetrievePhotos();
  }, [selectedDevices]);

  useEffectOnce(() => {
    handleRetrievePhotos();
  });

  return (
    <S.Gallery>
      <Spin spinning={pendingPhotos}>
        <Row justify="space-between">
          <Title level={3}>Gallery</Title>
        </Row>
        <Row justify="end" align="middle">
          <Select
            mode="multiple"
            onChange={(val) => { setSelectedDevices(val); }}
            maxTagCount="responsive"
            style={{ width: 'calc(100% - 610px)', marginRight: '10px' }}
            placeholder="Select Camera"
          >
            <Option value="">
              All Camera
            </Option>)
            {
              allDevices.map((device) =>
                <Option value={device.id} key={device.id}>
                  {device.name}
                </Option>)
            }
          </Select>
          <RangePicker
            style={{ width: '400px', marginRight: '10px' }}
            onChange={setDateRange}
            defaultValue={[moment().subtract(1, 'month'), moment().endOf('day')]}
            ranges={{
              'Last Month': [moment().subtract('month', 1).startOf('month'), moment().subtract('month', 1).endOf('month')],
              'Last Week': [moment().subtract('week', 1).startOf('week'), moment().subtract('week', 1).endOf('week')],
              Today: [moment().startOf('day'), moment().endOf('day')],
              'This Week': [moment().startOf('week'), moment().endOf('week')],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
            showTime
            format="MMM-DD-YYYY  HH:mm"
          />
          <Button
            style={{ width: '40px' }}
            icon={<SearchOutlined />}
            onClick={handleRetrievePhotos}
          />
          <Pagination
            current={total === 0 ? 0 : page}
            pageSize={100}
            defaultCurrent={total === 0 ? 0 : 1}
            onChange={(val) => setPage(val)}
            simple
            size="small"
            total={total}
          />
        </Row>
        <div className="photo-list-wrapper">
          {Object.keys(groupedGallery).map((key) =>
            <div className="item" key={key}>
              <Title level={5}>{moment(key, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</Title>
              <Row gutter={5}>
                {groupedGallery[key].map((photo) =>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    xl={{ span: 8 }}
                    xxl={{ span: 6 }}
                    key={photo.id}
                  >
                    <Image
                      style={{ cursor: 'pointer' }}
                      preview={false}
                      onClick={() => handleSelectPhoto(photo)}
                      src={photo.smallUrl}
                    />
                  </Col>)}
              </Row>
            </div>)}
        </div>
      </Spin>
    </S.Gallery>
  );
}

export default Gallery;
