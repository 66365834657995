import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { message } from 'antd';

export const invoicesGet = createAsyncThunk(
  'invoices/get',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const { data } = await api.get('/billing/invoices');
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const invoiceItemsGet = createAsyncThunk(
  'invoiceItems/get',
  (
    { invoiceId },
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      return api.get(`/billing/invoices/${invoiceId}/items`);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const invoiceDownload = createAsyncThunk(
  'invoice/download',
  async (
    { id },
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const response = await api.post(`/billing/invoices/${id}/download`, { output: 'email' });
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const invoiceAdapter = createEntityAdapter();
const initialState = invoiceAdapter.getInitialState({
  pending: false,
  pendingResendInvoice: false,
  items: [],
  error: null,
});

export const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(invoicesGet.fulfilled, (state, { payload }) => {
        state.pending = false;
        invoiceAdapter.setAll(state, payload || []);
      })
      .addCase(invoicesGet.pending, (state) => {
        state.pending = true;
      })
      .addCase(invoicesGet.rejected, (state) => {
        state.pending = false;
      })
      .addCase(invoiceItemsGet.fulfilled, (state, { payload }) => {
        state.pending = false;
        console.log('payload', payload);
        state.items = payload;
      })
      .addCase(invoiceItemsGet.pending, (state) => {
        state.pending = true;
      })
      .addCase(invoiceItemsGet.rejected, (state) => {
        state.pending = false;
      })
      .addCase(invoiceDownload.fulfilled, (state) => {
        message.success('Invoice sent to your email');
        state.pendingResendInvoice = false;
      })
      .addCase(invoiceDownload.pending, (state) => {
        state.pendingResendInvoice = true;
      })
      .addCase(invoiceDownload.rejected, (state) => {
        state.pendingResendInvoice = false;
        message.error('Unable to send invoice to your email');
      });
  },
});

export const {
  selectAll: selectAllInvoice,
} = invoiceAdapter.getSelectors((state) => state.invoice);

export const selectInvoiceItems = (state) => state.invoice.items;
export const selectPendingResendInvoice = (state) => state.invoice.pendingResendInvoice;
export const selectPending = (state) => state.invoice.pending;
export const selectError = (state) => state.invoice.error;

export default slice.reducer;
