import React from 'react';
import Footer from 'components/Footer';
import Box from 'components/Box';
import GuestNav from 'components/GuestNav';
import GetStarted from 'components/GetStarted';
import Header from './Header';
import Content1 from './Content1';
import Content2 from './Content2';

function CameraCloudStorage() {
  return (
    <>
      <GuestNav />
      <Box pt={80}>
        <Header />
        <Content1 />
        <Content2 />
        <GetStarted />
      </Box>
      <Footer />
    </>
  );
}

export default CameraCloudStorage;
