import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';

export const devicesGet = createAsyncThunk(
  'devices/get',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      // endpoint is not paginated
      const data = await api.get('/device/list');
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceAddToFavorites = createAsyncThunk(
  'devices/get',
  async (
    deviceId,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const data = await api.post(`/device/devices/${deviceId}/favorites`);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceRemoveToFavorites = createAsyncThunk(
  'devices/get',
  async (
    deviceId,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const data = await api.delete(`/device/devices/${deviceId}/favorites`);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const devicesAdapter = createEntityAdapter();
const initialState = devicesAdapter.getInitialState({
  pending: false,
  showActiveOnly: true,
  search: '',
});

export const slice = createSlice({
  name: 'deviceList',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setShowActiveOnly: (state, action) => {
      state.showActiveOnly = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(devicesGet.fulfilled, (state, { payload }) => {
        state.pending = false;
        devicesAdapter.setAll(state, payload || []);
      })
      .addCase(devicesGet.pending, (state) => {
        state.pending = true;
      })
      .addCase(devicesGet.rejected, (state) => {
        state.pending = false;
      });
  },
});

export const {
  selectAll: selectAllDevices,
} = devicesAdapter.getSelectors((state) => state.deviceList);

export const { setSearch, setShowActiveOnly } = slice.actions;

export const selectPending = (state) => state.deviceList.pending;
export const selectSearch = (state) => state.deviceList.search;
export const selectShowActiveOnly = (state) => state.deviceList.showActiveOnly;

export default slice.reducer;
