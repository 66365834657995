import React, { useState, useRef } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SideNav from 'components/SideNav';
import TopNav from 'components/TopNav';
import AddDeviceModal from 'features/DeviceAddModal';
import DeviceListPage from 'pages/DeviceListPage';

import DeviceDetailPage from 'pages/DeviceDetailPage';
import CustomersPage from 'pages/CustomersPage';
import CustomerDeviceListPage from 'pages/CustomerDeviceListPage';
import FavoritesPage from 'pages/FavoritesPage';
import GalleryPage from 'pages/GalleryPage';
import StatisticsPage from 'pages/StatisticsPage';
import SettingsPage from 'pages/SettingsPage';
import CheckoutPage from 'pages/CheckoutPage';
import HelpPage from 'pages/HelpPage';
import { useClickAway } from 'react-use';
import TransactionPage from 'pages/TransactionPage';

import * as S from './styles';

function AuthenticatedPage() {
  const sideNavRef = useRef(null);
  const [isSideNavActive, setSideNav] = useState(false);

  useClickAway(sideNavRef, () => {
    setSideNav(false);
  });

  return (
    <S.AuthenticatedPage>
      <aside ref={sideNavRef} className={isSideNavActive ? 'active' : ''}>
        <SideNav setSideNav={setSideNav} />
      </aside>
      <section>
        <TopNav setSideNav={setSideNav} />
        <Switch>
          <Route exact path="/app/devices">
            <DeviceListPage />
          </Route>
          <Route exact path="/app/devices/:id">
            <DeviceDetailPage />
          </Route>
          <Route exact path="/app/devices/:id/photo/:photoId">
            <DeviceDetailPage />
          </Route>
          <Route exact path="/app/customers">
            <CustomersPage />
          </Route>
          <Route exact path="/app/customers/:customerId/devices">
            <CustomerDeviceListPage />
          </Route>
          <Route exact path="/app/favorites">
            <FavoritesPage />
          </Route>
          <Route exact path="/app/gallery">
            <GalleryPage />
          </Route>
          <Route exact path="/app/statistics">
            <StatisticsPage />
          </Route>
          <Route path="/app/settings">
            <SettingsPage />
          </Route>
          <Route path="/app/checkout">
            <CheckoutPage />
          </Route>
          <Route path="/app/help">
            <HelpPage />
          </Route>
          <Route exact path="/app/transactions/:invoiceId">
            <TransactionPage />
          </Route>
          <Route exact path="/app/transactions">
            <TransactionPage />
          </Route>
          <Redirect from="/app/" to="/app/devices" />
        </Switch>
      </section>
      <AddDeviceModal />
    </S.AuthenticatedPage>
  );
}

export default AuthenticatedPage;
