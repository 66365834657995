import React, { useState } from 'react';
import { Input, Button, Row, Col, Alert, Form, Spin, List, Skeleton, Space } from 'antd';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import useDeviceSharing from '../hooks';

function Share(props) {
  const { deviceId } = props;
  const [form] = Form.useForm();
  const [currentInvite, setCurrentInvite] = useState('');

  const {
    inviteDeviceUsers,
    pendingDeviceInvite,
    pendingGetDeviceUserPendingInvites,
    pendingDeleteDeviceUserPendingInvite,
    deviceUserPendingInvites,
    deleteDeviceUserPendingInvites,
    getDeviceUserPendingInvites,
  } = useDeviceSharing();

  const handleSendInvites = (data) => {
    const { email } = data;
    inviteDeviceUsers(deviceId, email)
      .then(() => {
        form.setFieldsValue({ emails: '' });
      });
  };

  const handleCancelInvite = (inviteId) => {
    setCurrentInvite(inviteId);
    deleteDeviceUserPendingInvites(deviceId, inviteId);
  };

  useEffectOnce(() => {
    if (deviceId) {
      getDeviceUserPendingInvites(deviceId);
    }
  });

  useUpdateEffect(() => {
    if (deviceId) {
      getDeviceUserPendingInvites(deviceId);
    }
  }, [deviceId]);

  return (
    <Form form={form} onFinish={handleSendInvites}>
      <Row gutter={15} style={{ minHeight: '400px' }}>
        <Col span={24}>
          <Form.Item name="email">
            {/* <TextArea rows={4} /> */}
            <Input placeholder="Enter email" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Spin spinning={pendingGetDeviceUserPendingInvites}>
            <List
              dataSource={deviceUserPendingInvites}
              renderItem={(item) => (
                <List.Item>
                  <Skeleton avatar title={false} loading={false} active>
                    <List.Item.Meta
                      title={item.email}
                    />
                    <Space size={20}>
                      <Button
                        loading={pendingDeleteDeviceUserPendingInvite && currentInvite === item.id}
                        type="default"
                        onClick={() => handleCancelInvite(item.id)}
                      >
                        Cancel Invite
                      </Button>
                    </Space>
                  </Skeleton>
                </List.Item>
              )}
            />
          </Spin>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Alert
            message={
              <p style={{ textAlign: 'center' }}>
                Enter email address of person you want to invite.
              </p>
            }
            type="info"
          />
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={24}>
          <Button
            loading={pendingDeviceInvite}
            htmlType="submit"
            type="primary"
            style={{ width: '100%' }}
          >
            Send Invite
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default Share;
