import React, { useState } from 'react';
import { Form, Button, Row, Col, InputNumber, Card, Space, Typography, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import Box from 'components/Box';
import useProductsList from 'features/ProductsList/hooks';

import { createAuthenticatedClient } from 'app/client';
import { openSuccessNotification } from 'common/helpers';
import { CreditCardOutlined } from '@ant-design/icons';
import { selectUserMe } from 'features/Users/slice';
import { deviceAddOnPurchase } from './slice';

import * as S from './styles';

const { Title } = Typography;

function AddDeviceModal({ device, onPurchase }) {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const stripe = useStripe();
  const [totalAmount, setTotalAmount] = useState(0);
  const [pending, setPending] = useState(false);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const userMe = useSelector(selectUserMe);
  const { deviceAddOnsByCode } = useProductsList();

  const handleOnChange = (changeValues, allValues) => {
    let amount = 0;
    const addOns = [];
    Object.keys(allValues).map((key) => {
      const addOn = deviceAddOnsByCode[key];
      if (allValues[key] > 0) {
        amount += addOn.price * allValues[key];
        addOns.push({ code: key, quantity: allValues[key] });
      }
      return false;
    });

    setSelectedAddOns(addOns);
    setTotalAmount(amount);
  };

  const handleCardSetupRequired = async (result) => {
    const { pendingSetupIntent, paymentMethodId } = result;
    if (pendingSetupIntent && pendingSetupIntent.status === 'requires_action') {
      return stripe
        .confirmCardSetup(pendingSetupIntent.clientSecret, {
          payment_method: paymentMethodId,
        })
        .then((setupResult) => {
          if (setupResult.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw setupResult.error;
          } else if (setupResult.setupIntent.status === 'succeeded') {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to setup_intent.succeeded.
            return result;
          }

          throw new Error('Unhandled Card Setup');
        });
    }

    // No customer action needed
    return result;
  };

  const handlePaymentThatRequiresCustomerAction = (result) => {
    const { paymentIntent, paymentMethodId } = result;
    if (!paymentIntent) {
      // No customer action needed
      return result;
    }

    return stripe
      .confirmCardPayment(paymentIntent.clientSecret, {
        payment_method: paymentMethodId,
      })
      .then((cardResult) => {
        if (cardResult.error) {
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          throw cardResult.error;
        } else if (cardResult.paymentIntent.status === 'succeeded') {
          // There's a risk of the customer closing the window before callback
          // execution. To handle this case, set up a webhook endpoint and
          // listen to invoice.paid. This webhook endpoint returns an Invoice.
          return result;
        }

        throw new Error('Unhandled Customer Action');
      });
  };

  const handleProcessPayment = async () => {
    message.loading('Purchasing Add-Ons...', 0);
    setPending(true);
    const result = await dispatch(deviceAddOnPurchase({
      subscriptionId: device.subscriptionId,
      payload: { addons: selectedAddOns },
    }));

    if (result?.payload?.intents) {
      await handleCardSetupRequired({ ...result?.payload?.intents })
        .then(handlePaymentThatRequiresCustomerAction);

      const api = createAuthenticatedClient();
      await api.post(`user/invoices/${result?.payload.invoice.id}/verify`);
      message.destroy();
      openSuccessNotification({
        message: 'Add-On Credits Added',
        description: 'Successfully purchased add-on credits',
      });
      setPending(false);
      onPurchase();
    } else {
      message.destroy();
    }
  };

  return (
    <S.DeviceAddOnModal>
      <Box p={{ _: 20, sm: 30 }}>
        <Space style={{ margin: '0 0 20px 0' }}>
          <CreditCardOutlined style={{ fontSize: '21px' }} />
          <Title level={4} style={{ margin: '0' }}>
            Purchase AI Checks
          </Title>
        </Space>
        <Form form={form} onFinish={handleProcessPayment} onValuesChange={handleOnChange} layout="vertical">
          <Row gutter={20}>
            {Object.keys(deviceAddOnsByCode).map((key) => {
              const addOn = deviceAddOnsByCode[key];
              if (addOn.recurringPeriod === '') {
                return (
                  <Col span={12}>
                    <Card title={addOn.name}>
                      {`${addOn.currencySymbol}${addOn.price}`}
                      <br />
                      {addOn.description}
                    </Card>
                    <Form.Item name={addOn.code} initialValue={0}>
                      <InputNumber min={0} addonBefore="QTY" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                );
              }
              return null;
            })}
          </Row>
          <Row gutter={20} style={{ marginTop: '10px', textAlign: 'center' }}>
            <Col span={24}>
              <h3>Total Amount: ${totalAmount}</h3>
            </Col>
          </Row>
          <Row gutter={20} style={{ marginTop: '10px' }}>
            <Col span={8}>
              <Button type="default" block>
                Cancel
              </Button>
            </Col>
            <Col span={16}>
              <Button
                htmlType="submit"
                loading={pending}
                block
              >
                {`Proceed with card ending at ${userMe.defaultCard?.last4}`}
              </Button>
            </Col>
          </Row>
        </Form>
      </Box>
    </S.DeviceAddOnModal>
  );
}

export default AddDeviceModal;
