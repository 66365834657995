import { Button } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import { useHistory } from 'react-router-dom';

function Content2() {
  const history = useHistory();
  return (
    <Box py={60} px={4} maxWidth={1100} m="auto" textAlign="center">
      <Text fontSize={30}>How does Image Engine’s camera cloud storage work?</Text>
      <Text my={6}>Image Engine’s cloud storage secures your surveillance images for as long as required. Our FTP servers are isolated from our main processing servers to ensure that your data is protected.  Any ftp equipped CCTV camera can connect directly to our servers, so you don’t need to invest in any additional hardware.  All your information is contained in one location which you can access from any smart device or computer from your personalised portal.</Text>
      <Text fontSize={30}>What will the security camera cloud storage plan cost?</Text>
      <Text my={6}>You can try our security camera cloud storage for FREE for 7 days.  If you like what you see, then you can start using our storage only plans from just $3.95 per month.  Our plans can be upgraded or downgraded at any time.</Text>
      <Button type="primary" size="large" onClick={() => history.push('/pricing')}>See Our Pricing Plans</Button>
    </Box>
  );
}

export default Content2;
