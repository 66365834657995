import React from 'react';
import Box from 'components/Box';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import Filter from './Filter';
import {
  selectAllInvoice,
  selectInvoiceItems,
  invoicesGet,
  invoiceItemsGet,
  selectPending,
} from './slice';

function Invoice() {
  const { invoiceId } = useParams();

  const dispatch = useDispatch();
  const pending = useSelector(selectPending);
  // const pendingResendInvoice = useSelector(selectPendingResendInvoice);
  const allInvoice = useSelector(selectAllInvoice);
  const invoiceItems = useSelector(selectInvoiceItems);

  // const [currentDownloadInvoiceId, setCurrentDownloadInvoiceId] = React.useState(0);

  // const handleDownloadInvoice = (id) => {
  //   setCurrentDownloadInvoiceId(id);
  //   dispatch(invoiceDownload({ id }));
  // };

  const invoiceItemsColumns = [
    { title: 'Item', dataIndex: 'description', key: 'description', render: (value, { quantity }) => `${quantity} x ${value}` },
    { title: 'Price', dataIndex: 'price', key: 'price', width: '10%' },
    { title: 'Sub-Total', dataIndex: 'subTotal', key: 'subTotal', width: '8%' },

    { title: 'Discount', dataIndex: 'discount', key: 'discount', width: '8%', render: (value) => (value > 0 ? `- ${value}` : '') },
    { title: 'Total', dataIndex: 'total', key: 'total', width: '10%' },
    { title: 'Date', dataIndex: 'createdAt', key: 'createdAt', width: '15%', render: (text) => moment(text).format('MMM-DD-YYYY') },
  ];
  const invoicesColumns = [
    { title: 'Number', dataIndex: 'number', key: 'number', width: '10%' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Status', dataIndex: 'status', key: 'status', width: '10%', align: 'center' },
    { title: 'Sub-Total', dataIndex: 'subTotal', key: 'subTotal', width: '8%', render: (value, { currencySymbol }) => `${currencySymbol}${value}` },
    { title: 'Tax', dataIndex: 'tax', key: 'tax', width: '5%', render: (value, { currencySymbol }) => `${currencySymbol}${value}` },
    { title: 'Discount', dataIndex: 'discount', key: 'discount', width: '8%', render: (value, { currencySymbol }) => (`${value > 0 ? '-' : ''} ${currencySymbol}${value}`) },
    { title: 'Total', dataIndex: 'total', key: 'total', width: '5%', render: (value, { currencySymbol }) => `${currencySymbol}${value}` },
    { title: 'Date', dataIndex: 'createdAt', key: 'createdAt', width: '10%', render: (text) => moment(text).format('MMM-DD-YYYY') },
    { title: '', key: 'operation', width: '10%', render: (_, record) => <Link to={`/app/transactions/${record.id}`}>View Invoice Items</Link> },
    // { title: '', key: 'operation', render: (_, record) => <Button loading={pendingResendInvoice && currentDownloadInvoiceId === record.id} type="primary" onClick={() => handleDownloadInvoice(record.id)}>Resend Invoice</Button> },
  ];

  useEffectOnce(() => {
    if (invoiceId) {
      dispatch(invoiceItemsGet({ invoiceId }));
    } else {
      dispatch(invoicesGet());
    }
  });

  useUpdateEffect(() => {
    if (invoiceId) {
      dispatch(invoiceItemsGet({ invoiceId }));
    } else {
      dispatch(invoicesGet());
    }
  }, [invoiceId]);

  return (
    <>
      <Filter />
      <Box width="100%" backgroundColor="gray.1" p={4} mt={63} minHeight="calc(100vh - 119px)">
        {
          invoiceId && <Link style={{ marginBottom: '10px', display: 'block' }} to="/app/transactions">Back to Invoice List</Link>
        }
        <Table
          loading={pending}
          className="data-table"
          columns={invoiceId ? invoiceItemsColumns : invoicesColumns}
          dataSource={invoiceId ? invoiceItems : allInvoice}
          scroll={{ y: 'calc(100vh - 280px)' }}
        />
      </Box>
    </>
  );
}

export default Invoice;
