import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';

function LegalPrivacy() {
  return (
    <Box maxWidth={1200} py={60} px={40} m="auto">
      <Text fontSize={40} fontWeight={600} textAlign="center" mb={4}>Privacy Policy</Text>
      <Text fontSize={14} mb={4}>
        <Text as="strong">*Last updated: February 17, 2021*</Text>{' '}
        This Privacy Policy describes Our policies and
        procedures on the collection, use and disclosure of Your information when You use
        the Service and tells You about Your privacy rights and how the law protects You.
      </Text>
      <Text fontSize={14}>
        We use Your Personal data to provide and improve the Service.
        By using the Service, You agree to the collection and use of information
        in accordance with this Privacy Policy. This Privacy Policy
        has been created with the help of the Privacy Policy Generator.
      </Text>
      <Text fontSize={20} fontWeight={600} my={4}>Interpretation and Definitions</Text>
      <Text fontSize={16} fontWeight={600} my={2}>Interpretation</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        The words of which the initial letter is capitalized have meanings defined
        under the following conditions. The following definitions shall have the
        same meaning regardless of whether they appear in singular or in plural.
      </Text>
      <Text fontSize={16} fontWeight={600} my={2}>Definitions</Text>
      <Text fontSize={14} fontWeight={400} my={2}>
        For the purposes of this Privacy Policy:
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Account</Text>{' '}
        means a unique account created for You to access our Service or
        parts of our Service.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Affiliate</Text>{' '}
        means an entity that controls, is controlled by or is under common
        control with a party, where &quot;control&quot; means ownership of 50% or more
        of the shares, equity interest or other securities entitled to vote
        for election of directors or other managing authority.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Application</Text>{' '}
        means the software program provided by the Company
        downloaded by You on any electronic device, named Image Engine Web App
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Company</Text>{' '}
        (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot;
        or &quot;Our&quot; in this Agreement) refers to Image Engine Limited, 38a Third View Ave,
        Beachlands, Manukau, Auckland, New Zealand.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Cookies</Text>{' '}
        are small files that are placed on Your computer, mobile device
        or any other device by a website, containing the details of Your browsing
        history on that website among its many uses.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Country</Text>{' '}
        refers to: New Zealand
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Device</Text>{' '}
        means any device that can access the Service such as a computer, a
        cellphone or a digital tablet.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Personal</Text>{' '}
        Data is any information that relates to an identified or identifiable individual.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Service</Text>{' '}
        refers to the Application or the Website or both.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Service Provider</Text>{' '}
        means any natural or legal person who processes the data on behalf
        of the Company. It refers to third-party companies or individuals employed by the
        Company to facilitate the Service, to provide the Service on behalf of the Company,
        to perform services related to the Service or to assist the Company in analyzing how
        the Service is used.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Third-party Social Media Service</Text>{' '}
        refers to any website or any social network website
        through which a User can log in or create an account to use the Service.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Usage Data</Text>{' '}
        refers to data collected automatically, either generated by the use of the
        Service or from the Service infrastructure itself (for example, the duration of a
        page visit).
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Website</Text>{' '}
        refers to Image Engine, accessible from <a href="https://imageengine.ai">www.imageengine.ai</a>
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• You</Text>{' '}
        means the individual accessing or using the Service, or the company, or other
        legal entity on behalf of which such individual is accessing or using the Service,
        as applicable.
      </Text>
      <Text fontSize={20} fontWeight={600} my={4}>Collecting and Using Your Personal Data </Text>
      <Text fontSize={16} fontWeight={600} my={2}>Types of Data Collected</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        The words of which the initial letter is capitalized have meanings defined under the
        following conditions. The following definitions shall have the same meaning regardless
        of whether they appear in singular or in plural.
      </Text>
      <Text fontSize={16} fontWeight={600} my={2}>Personal Data</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        While using Our Service, We may ask You to provide Us with certain personally identifiable
        information that can be used to contact or identify You. Personally identifiable information
        may include, but is not limited to:
      </Text>
      <Text ml={4} mb={2}>
        • Email address
      </Text>
      <Text ml={4} mb={2}>
        • First name and last name
      </Text>
      <Text ml={4} mb={2}>
        • Phone number
      </Text>
      <Text ml={4} mb={2}>
        • Address, State, Province, ZIP/Postal code, City
      </Text>
      <Text ml={4} mb={2}>
        • Usage Data
      </Text>
      <Text fontSize={16} fontWeight={600} my={2} mt={4}>Usage Data</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        Usage Data is collected automatically when using the Service.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        Usage Data may include information such as Your Device&apos;s Internet Protocol address
        (e.g. IP address), browser type, browser version, the pages of our Service that You
        visit, the time and date of Your visit, the time spent on those pages, unique device
        identifiers and other diagnostic data.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        When You access the Service by or through a mobile device, We may collect certain
        information automatically, including, but not limited to, the type of mobile device
        You use, Your mobile device unique ID, the IP address of Your mobile device, Your
        mobile operating system, the type of mobile Internet browser You use, unique device
        identifiers and other diagnostic data.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        We may also collect information that Your browser sends whenever You visit our
        Service or when You access the Service by or through a mobile device.
      </Text>
      <Text fontSize={16} fontWeight={600} my={2} mt={4}>Tracking Technologies and Cookies</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        We use Cookies and similar tracking technologies to track the activity on Our Service
        and store certain information. Tracking technologies used are beacons, tags, and
        scripts to collect and track information and to improve and analyze Our Service.
        The technologies We use may include:
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Cookies or Browser Cookies.</Text>{' '}
        A cookie is a small file placed on Your Device.
        You can instruct Your browser to refuse all Cookies or to indicate when a Cookie
        is being sent. However, if You do not accept Cookies, You may not be able to use
        some parts of our Service. Unless you have adjusted Your browser setting so that
        it will refuse Cookies, our Service may use Cookies.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Flash Cookies.</Text>{' '}
        Certain features of our Service may use local stored objects (or Flash Cookies) to
        collect and store information about Your preferences or Your activity on our Service.
        Flash Cookies are not managed by the same browser settings as those used for Browser
        Cookies. For more information on how You can delete Flash Cookies, please read
        &quot;Where can I change the settings for disabling, or deleting local shared objects?&quot;
        available at https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• Web Beacons.</Text>{' '}
        Certain sections of our Service and our emails may contain small electronic files
        known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs)
        that permit the Company, for example, to count users who have visited those pages
        or opened an email and for other related website statistics (for example, recording the
        popularity of a certain section and verifying system and server integrity).
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent
        Cookies remain on Your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close Your web browser.
        Learn more about cookies: What Are Cookies?.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        We use both Session and Persistent Cookies for the purposes set out below:
      </Text>
      <Text ml={4} mb={1}>
        <Text as="strong">Necessary / Essential Cookies</Text>
      </Text>
      <Text ml={4} mb={1}>
        • Type: Session Cookies
      </Text>
      <Text ml={4} mb={1}>
        • Administered by: Us
      </Text>
      <Text ml={4} mb={1}>
        • Purpose: These Cookies are essential to provide You with services available
        through the Website and to enable You to use some of its features.
        They help to authenticate users and prevent fraudulent use of user accounts.
        Without these Cookies, the services that You have asked for cannot be provided,
        and We only use these Cookies to provide You with those services.
      </Text>
      <Text ml={4} mb={1} mt={2}>
        <Text as="strong">Cookies Policy / Notice Acceptance Cookies </Text>
      </Text>
      <Text ml={4} mb={1}>
        • Type: Persistent Cookies
      </Text>
      <Text ml={4} mb={1}>
        • Administered by: Us
      </Text>
      <Text ml={4} mb={1}>
        • Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
      </Text>
      <Text ml={4} mb={1} mt={2}>
        <Text as="strong">Functionality Cookies</Text>
      </Text>
      <Text ml={4} mb={1}>
        • Type: Persistent Cookies
      </Text>
      <Text ml={4} mb={1}>
        • Administered by: Us
      </Text>
      <Text ml={4} mb={1}>
        • Purpose: These Cookies allow us to remember choices You make when You use the Website,
        such as remembering your login details or language preference. The purpose of these
        Cookies is to provide You with a more personal experience and to avoid You having
        to re-enter your preferences every time You use the Website.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        For more information about the cookies we use and your choices regarding cookies,
        please visit our Cookies Policy or the Cookies section of our Privacy Policy.
      </Text>
      <Text fontSize={16} fontWeight={600} my={2} mt={4}>Use of Your Personal Data</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        The Company may use Personal Data for the following purposes:
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• To provide and maintain our Service,</Text>{' '}
        including to monitor the usage of our Service.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• To manage Your Account: </Text>{' '}
        to manage Your registration as a user of the Service. The Personal Data You provide
        can give You access to different functionalities of the Service that are available
        to You as a registered user.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• For the performance of a contract:</Text>{' '}
        the development, compliance and undertaking of the purchase contract for the products,
        items or services You have purchased or of any other contract with Us through the Service.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• To contact You:</Text>{' '}
        To contact You by email, telephone calls, SMS, or other equivalent forms of electronic
        communication, such as a mobile application&apos;s push notifications regarding updates or
        informative communications related to the functionalities, products or contracted
        services, including the security updates, when necessary or reasonable for their
        implementation.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• To provide You with news,</Text>{' '}
        special offers and general information about other goods, services and events which we
        offer that are similar to those that you have already purchased or enquired about unless
        You have opted not to receive such information.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• To manage Your requests:</Text>{' '}
        To attend and manage Your requests to Us.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• For business transfers:</Text>{' '}
        We may use Your information to evaluate or conduct a merger, divestiture, restructuring,
        reorganization, dissolution, or other sale or transfer of some or all of Our assets,
        whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding,
        in which Personal Data held by Us about our Service users is among the assets transferred.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• For other purposes:</Text>{' '}
        We may use Your information for other purposes, such as data analysis,
        identifying usage trends, determining the effectiveness of our promotional campaigns and to
        evaluate and improve our Service, products, services, marketing and your experience.
      </Text>
      <Text mb={2}>
        We may share Your personal information in the following situations:
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• With Service Providers:</Text>{' '}
        We may share Your personal information with Service Providers
        to monitor and analyze the use of our Service, to contact You.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• For business transfers:</Text>{' '}
        We may share or transfer Your personal information in connection
        with, or during negotiations of, any merger, sale of Company assets, financing, or
        acquisition of all or a portion of Our business to another company.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• With Affiliates:</Text>{' '}
        We may share Your information with Our affiliates, in which case
        we will require those affiliates to honor this Privacy Policy. Affiliates include
        Our parent company and any other subsidiaries, joint venture partners or other
        companies that We control or that are under common control with Us.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• With business partners:</Text>{' '}
        We may share Your information with Our business partners to offer You
        certain products, services or promotions.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• With other users:</Text>{' '}
        when You share personal information or otherwise interact in the public
        areas with other users, such information may be viewed by all users and
        may be publicly distributed outside. If You interact with other users
        or register through a Third-Party Social Media Service, Your contacts
        on the Third-Party Social Media Service may see Your name, profile,
        pictures and description of Your activity. Similarly, other users will
        be able to view descriptions of Your activity, communicate with You and view Your profile.
      </Text>
      <Text ml={4} mb={2}>
        <Text as="strong">• With Your consent:</Text>{' '}
        We may disclose Your personal information for any other purpose with Your consent.
      </Text>
      <Text fontSize={16} fontWeight={600} my={2} mt={4}>Retention of Your Personal Data</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        The Company will retain Your Personal Data only for as long as is necessary for the
        purposes set out in this Privacy Policy. We will retain and use Your Personal
        Data to the extent necessary to comply with our legal obligations (for example,
        if we are required to retain your data to comply with applicable laws), resolve
        disputes, and enforce our legal agreements and policies.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        The Company will also retain Usage Data for internal analysis purposes. Usage Data
        is generally retained for a shorter period of time, except when this data is used
        to strengthen the security or to improve the functionality of Our Service, or
        We are legally obligated to retain this data for longer time periods.
      </Text>
      <Text fontSize={16} fontWeight={600} my={2} mt={4}>Transfer of Your Personal Data</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        Your information, including Personal Data, is processed at the Company&apos;s operating
        offices and in any other places where the parties involved in the processing
        are located. It means that this information may be transferred to — and maintained
        on — computers located outside of Your state, province, country or other
        governmental jurisdiction where the data protection laws may differ than those
        from Your jurisdiction.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        Your consent to this Privacy Policy followed by Your submission of such information
        represents Your agreement to that transfer.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        The Company will take all steps reasonably necessary to ensure that Your data is
        treated securely and in accordance with this Privacy Policy and no transfer of
        Your Personal Data will take place to an organization or a country unless there
        are adequate controls in place including the security of Your data and other
        personal information.
      </Text>
      <Text fontSize={20} fontWeight={600} my={4}>Disclosure of Your Personal Data</Text>
      <Text fontSize={16} fontWeight={600} my={2} mt={4}>Business Transactions</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        If the Company is involved in a merger, acquisition or asset sale, Your Personal
        Data may be transferred. We will provide notice before Your Personal Data is
        transferred and becomes subject to a different Privacy Policy.
      </Text>
      <Text fontSize={16} fontWeight={600} my={2} mt={4}>Law enforcement </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        Under certain circumstances, the Company may be required to disclose Your
        Personal Data if required to do so by law or in response to valid requests
        by public authorities (e.g. a court or a government agency).
      </Text>
      <Text fontSize={16} fontWeight={600} my={2} mt={4}>Other legal requirements</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        The Company may disclose Your Personal Data in the good faith belief that
        such action is necessary to:
      </Text>
      <Text ml={4} mb={1}>
        • Comply with a legal obligation
      </Text>
      <Text ml={4} mb={1}>
        • Protect and defend the rights or property of the Company
      </Text>
      <Text ml={4} mb={1}>
        • Prevent or investigate possible wrongdoing in connection with the Service
      </Text>
      <Text ml={4} mb={1}>
        • Protect the personal safety of Users of the Service or the public
      </Text>
      <Text ml={4} mb={1}>
        • Protect against legal liability
      </Text>
      <Text fontSize={16} fontWeight={600} my={2} mt={4}>Security of Your Personal Data </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        The security of Your Personal Data is important to Us, but remember that no method of
        transmission over the Internet, or method of electronic storage is 100% secure.
        While We strive to use commercially acceptable means to protect Your Personal Data,
        We cannot guarantee its absolute security.
      </Text>
      <Text fontSize={20} fontWeight={600} my={4}>Children&apos;s Privacy</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        Our Service does not address anyone under the age of 13. We do not knowingly collect
        personally identifiable information from anyone under the age of 13. If You are
        a parent or guardian and You are aware that Your child has provided Us with
        Personal Data, please contact Us. If We become aware that We have collected
        Personal Data from anyone under the age of 13 without verification of parental
        consent, We take steps to remove that information from Our servers.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        If We need to rely on consent as a legal basis for processing Your information
        and Your country requires consent from a parent, We may require Your parent&apos;s
        consent before We collect and use that information.
      </Text>
      <Text fontSize={20} fontWeight={600} my={4}>Links to Other Websites</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        Our Service may contain links to other websites that are not operated by Us.
        If You click on a third party link, You will be directed to that third party&apos;s site.
        We strongly advise You to review the Privacy Policy of every site You visit.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </Text>
      <Text fontSize={20} fontWeight={600} my={4}>Changes to this Privacy Policy </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        We may update Our Privacy Policy from time to time. We will notify You of any changes
        by posting the new Privacy Policy on this page.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the &quot;Last updated&quot;
        date at the top of this Privacy Policy.
      </Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        You are advised to review this Privacy Policy periodically for any changes.
        Changes to this Privacy Policy are effective when they are posted on this page.
      </Text>
      <Text fontSize={20} fontWeight={600} my={4}>Contact Us</Text>
      <Text fontSize={14} fontWeight={400} my={2} mb={4}>
        If you have any questions about this Privacy Policy, You can contact us:
      </Text>
      <Text ml={4} mb={1}>
        • By visiting this page on our website: <a href="https://imageengine.ai/contact" target="_blank" rel="noreferrer">https://imageengine.ai/contact</a>
      </Text>
    </Box>
  );
}

export default LegalPrivacy;
