import React from 'react';
import { useEffectOnce, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from 'components/Box';
import Header from './components/Header';
import Thumbnail from './components/Thumbnail';
import Photos from './components/Photos';

import * as S from './styles';
import {

  reset, selectDevice, retrieveDevice,
} from './slice';

function DeviceDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const device = useSelector(selectDevice);

  useEffectOnce(() => {
    dispatch(retrieveDevice({ id }));
  });

  useUnmount(() => dispatch(reset()));

  return (
    <S.DeviceDetail>
      <Box as="section">
        {device &&
          <>
            <Header device={device} />
            <Thumbnail device={device} />
          </>
        }
      </Box>
      <aside>
        <Photos subscriptionId={device?.subscriptionId} />
      </aside>
    </S.DeviceDetail>
  );
}

export default DeviceDetail;
