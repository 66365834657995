import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import colors from 'styles/colors';
import { useHistory } from 'react-router-dom';

function Content4() {
  const history = useHistory();
  return (
    <Box p={{ _: '40px 20px', sm: '60px 40px' }} textAlign="center">
      <Text fontSize={30} fontWeight={600}>AI Image Recognition Pricing Plans</Text>
      <Text maxWidth={1023} m="20px auto 30px">You can try our AI image recognition and security camera cloud storage for FREE for 7 days.  If you like what you see, then you can start using our full suite of services from just $15.95 per month.</Text>
      <Button type="primary" size="large" onClick={() => history.push('/pricing')}>
        See our Pricing Plans
      </Button>
      <Box maxWidth={800} m="50px auto 0" textAlign="left">
        <Row gutter={[20, 20]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text ml={2}>Artificial Intelligence Object Detection</Text>
            </Box>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text ml={2}>Purpose built IOS and android Web Apps</Text>
            </Box>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text ml={2}>Compatible with any camera that supports FTP</Text>
            </Box>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text ml={2}>Unlimited Cloud storage available</Text>
            </Box>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text ml={2}>Flexible notification scheduling  </Text>
            </Box>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text ml={2}>Store and processes images up to 4MB in size</Text>
            </Box>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text ml={2}>Supports up to 1 image/second</Text>
            </Box>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text ml={2}>Scalable</Text>
            </Box>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Box display="flex" alignItems="center">
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.secondary }} />
              <Text ml={2}>Detects multiple objects including smoke, colour, animals, vehicles, licence plates, boats, people.</Text>
            </Box>
          </Col>
        </Row>
      </Box>
    </Box>
  );
}

export default Content4;
