import React from 'react';
import GuestNav from 'components/GuestNav';
import Box from 'components/Box';
import Footer from 'components/Footer';

import Header from './Header';
import Content1 from './Content1';
import Content2 from './Content2';
import ResellerForm from './ResellerForm';

function Reseller() {
  return (
    <>
      <GuestNav />
      <Box pt={80}>
        <Header />
        <Content1 />
        <Content2 />
        <ResellerForm />
      </Box>
      <Footer />
    </>
  );
}

export default Reseller;
