import { Button } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import { useHistory } from 'react-router-dom';

function GetStarted() {
  const history = useHistory();

  return (
    <Box px={4}>
      <Box
        maxWidth={1150}
        m="auto"
        borderRadius={8}
        display="flex"
        alignItems="center"
        justifyContent="center"
        background="linear-gradient(112.8deg, #6d51fd 20.17%, #9ca2ff 87.86%)"
      >
        <Box py={60} px={4} textAlign="center">
          <Text color="white" fontSize={{ _: 24, sm: 30 }} fontWeight={600} mb={4}>Get started with Image Engine today</Text>
          <Text color="white" fontSize={{ _: 16, sm: 20 }} fontWeight={500} mb={4}>Early Adopter Program</Text>
          <Text color="white" fontSize={{ _: 12, sm: 14 }} fontWeight={500} mb={4} px={20} maxWidth={1100}>
            Subscribe to Image Engine and retain the discount of 30% for the life of each subscribed device.
            As an early adopter you will be the first to use and further improve Image Engine.
            You will benefit from all the upcoming features of the product Hurry, limited spots available!
          </Text>
          <Box display="flex" justifyContent="center" flexDirection={{ _: 'column', sm: 'row' }}>
            <Box as={Button} mx={2} type="default" size="large" onClick={() => history.push('/register')}>
              Try Image Engine for FREE
            </Box>
            <Box as={Button} mx={2} type="default" size="large" mt={{ _: 10, sm: 0 }} onClick={() => history.push('/about-us#contact')}>
              Talk to us
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default GetStarted;
