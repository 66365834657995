import React from 'react';
import Footer from 'components/Footer';
import Box from 'components/Box';
import GuestNav from 'components/GuestNav';
import GetStarted from 'components/GetStarted';

import Header from './Header';
import Content1 from './Content1';
import Contact from './Contact';
import Address from './Address';

function Home() {
  return (
    <>
      <GuestNav />
      <Box pt={80}>
        <Header />
        <Content1 />
        <Box maxWidth={1200} px={4} m="0 auto 60px" display="flex" justifyContent="space-between" flexDirection={{ col: 'column', lg: 'row' }}>
          <Contact />
          <Address />
        </Box>
        <GetStarted />
      </Box>
      <Footer />
    </>
  );
}

export default Home;
