import { Col, Row, Form, Input, Button } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contactUser, selectPendingContactForm } from 'features/Users/slice';

function Contact() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const pendingContactForm = useSelector(selectPendingContactForm);
  const handleFinish = () => {
    form.validateFields().then(({
      email,
      firstName,
      lastName,
      phone,
      country,
      message,
    }) => {
      dispatch(contactUser({
        email,
        firstName,
        lastName,
        phone,
        country,
        message,
        type: 'contact',
      }));
    });
  };
  return (
    <Box maxWidth={600} id="contact" m="0 auto">
      <Text fontWeight={600} fontSize={36}>
        Contact Us
      </Text>
      <Text fontWeight={600} mb={4}>
        Please leave us a message with your enquiry.
      </Text>
      <Form form={form} onFinish={handleFinish}>
        <Row gutter={[20, 10]}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: 'First Name is required!' }]}
            >
              <Input placeholder="First Name" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: 'Last Name is required!' }]}
            >
              <Input placeholder="Last Name" size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Email is required!' }]}
            >
              <Input placeholder="Email Address" size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: 'Phone Number is required!' }]}
            >
              <Input placeholder="Phone Number" size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="country"
            >
              <Input placeholder="Country" size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="message"
              rules={[{ required: true, message: 'Message is required!' }]}
            >
              <Input.TextArea autoSize={{ minRows: 6, maxRows: 6 }} placeholder="Message" size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button loading={pendingContactForm} htmlType="submit" type="primary" size="large" style={{ width: '120px' }}>Submit</Button>
          </Col>
        </Row>
      </Form>
      {/* <iframe height={800} width={600} style={{ border: 'none' }} title="contact-us-form" src="https://cdn.forms-content.sg-form.com/bce90840-2444-11ed-a5e5-96a621b1af87" /> */}
    </Box>
  );
}

export default Contact;
