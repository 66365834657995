import styled from 'styled-components';
import colors from 'styles/colors';

export const TopNav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  border-bottom: 1px solid ${colors.gray};
  position: fixed;
  top: 0;
  left: 230px;
  width: calc(100% - 230px);
  background: ${colors.white};
  z-index: 9;

  @media (max-width: 900px) {
    left: 0;
    width: 100%;
  }

  > section {
    flex: 0 0 40%;
    display: flex;
    align-items: center;
    padding-right: 20px;
    @media (max-width: 1200px) {
      flex: 1;
    }
  }
  .username {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    
    b{
      font-size: 12px;
      color: #262E65;
    }
  }
  .ant-input-search {
    flex: 1;
  }
  .nav-btn {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    margin-left: 20px;
    flex: 0 0 140px;
    p {
      font-size: 14px;
      margin: 0 10px;
    }
  }
  .ant-badge-count-sm {
    font-size: 10px;
  }
  .ant-avatar {
    border-radius: 50%;
    border: 1px solid ${colors.gray};
    overflow: hidden;
  }
`;

export const ProfileBtn = styled.button`
  display: flex;
  align-items: center;
  margin-left: 10px;
  background: none;
  border: none;
  padding: 0;
  p {
    margin-right: 10px;
  }
`;

export const PopoverNotification = styled.div`
  width: 300px;
  max-height: 500px;
  overflow-y: scroll;
  padding-right: 10px;
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dedede;
  }
  
  section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    position: relative;
  }
  strong {
    font-size: 12px;
    display: block;
  }
  small {
    font-size: 10px;
    color: #333;
    display: block;
  }
  .dot {
    top: 2px;
    right: 0;
    position: absolute;
    display: block;
    height: 8px;
    width: 8px;
    background: ${colors.primary};
    border-radius: 50%;
  }
`;

export const PopoverProfile = styled.div`
  a {
    font-size: 12px;
    color: ${colors.black};

    &:hover {
      color: ${colors.primary};
    }
  }
`;

export const PopoverDevice = styled.div`
  button {
    padding: 0;
    background: none;
    border: none;
    font-size: 12px;
    &:hover {
      color: ${colors.primary};
    }
  }
`;
