import { combineReducers } from '@reduxjs/toolkit';
import deviceDetail from 'features/DeviceDetail/slice';
import deviceList from 'features/DeviceList/slice';
import customerDeviceList from 'features/CustomerDeviceList/slice';
import deviceAdd from 'features/DeviceAddModal/slice';
import deviceAddOn from 'features/DeviceAddOn/slice';
import deviceSharing from 'features/DeviceSharing/slice';
import deviceSubscription from 'features/DeviceSubscription/slice';
import aiParameters from 'features/AiParameters/slice';
import savedPlateNumbers from 'features/SavedPlateNumbers/slice';
import productsList from 'features/ProductsList/slice';
import users from 'features/Users/slice';
import admin from 'features/Admin/slice';
import reseller from 'features/Reseller/slice';
import invoice from 'features/Invoice/slice';
import reports from 'features/Statistics/slice';

export default combineReducers({
  deviceDetail,
  deviceList,
  customerDeviceList,
  deviceAdd,
  deviceAddOn,
  deviceSharing,
  deviceSubscription,
  savedPlateNumbers,
  aiParameters,
  productsList,
  invoice,
  users,
  admin,
  reseller,
  reports,
});
