import { configureStore } from '@reduxjs/toolkit';
import api, { createAuthenticatedClient } from './client';
import rootReducer from './reducers';

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { api, createAuthenticatedClient },
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
});
