import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from './Header';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

import * as S from './styles';

function CustomerAdd() {
  return (
    <>
      <Header step={0} />
      <S.CustomerAdd>
        <Switch>
          <Route exact path="/app/customers/new/step-1">
            <Step1 />
          </Route>
          <Route exact path="/app/customers/new/step-2">
            <Step2 />
          </Route>
          <Route exact path="/app/customers/new/step-3">
            <Step3 />
          </Route>
          <Redirect from="/app/customers/new" to="/app/customers/new/step-1" />
        </Switch>
      </S.CustomerAdd>
    </>
  );
}

export default CustomerAdd;
