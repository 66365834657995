import styled from 'styled-components';

export const AiParameters = styled.div`
  padding: 30px;
  width: 100%;

  @media (max-width: 600px) {
    padding: 20px;
  }
  
  .click-box{
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
  }

  .ant-select-selector{
    cursor: pointer !important;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  
  .ant-list-items{
    li{
      padding: 2px 12px;
    }
  }
  
  .ai-options{
    display: flex;
    margin: 10px 0;
    flex-direction: column;
    
    .ant-checkbox-wrapper{
      margin-left: 5px;
    }
  }
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
