import React, { useRef } from 'react';
import moment from 'moment';
import { Row, Col, Avatar } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import Text from 'components/Text';
import Box from 'components/Box';
import colors from 'styles/colors';
import { mbToSize } from 'common/helpers';
import { useSelector } from 'react-redux';

import { selectDevice, selectPending } from 'features/DeviceDetail/slice';
import useDeviceSharing from 'features/DeviceSharing/hooks';
import { useClickAway, useUpdateEffect } from 'react-use';

function InfoDrawer({ setDrawerActive, isDrawerActive }) {
  const sideDrawer = useRef(null);
  const device = useSelector(selectDevice);
  const loading = useSelector(selectPending);

  const {
    getDeviceUsers,
    pendingGetDeviceUsers,
    deviceUsers,
  } = useDeviceSharing();

  useUpdateEffect(() => {
    if (device?.id && device.isOwner) {
      getDeviceUsers(device.id);
    }
  }, [device]);

  useClickAway(sideDrawer, (e) => {
    if (e.target.getAttribute('data-icon') === 'info-circle') {
      setDrawerActive(true);
    } else {
      setDrawerActive(false);
    }
  });

  return (
    <Box
      ref={sideDrawer}
      position="fixed"
      top={0}
      right={0}
      width={300}
      height="100vh"
      zIndex={100}
      background="white"
      transition="0.2s all ease-in-out"
      transform={isDrawerActive ? 'translate3d(0,0,0)' : 'translate3d(100%,0,0)'}
      borderLeft="1px solid"
      borderLeftColor="gray"
    >
      <Box
        borderBottom="1px solid"
        borderColor="gray"
        height={56}
        px={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontSize={18}>Device Detail</Text>
        <CloseCircleOutlined onClick={() => setDrawerActive(false)} style={{ fontSize: '21px' }} />
      </Box>
      <Box p={30} overflow="auto">
        {(device && !loading) &&
        <Row gutter={[20, 10]}>
          <Col span={10}>
            <Text fontSize={13} color="gray_5">
              Owner
            </Text>
          </Col>
          <Col span={14}>
            <Avatar
              size="small"
              src="https://i.pravatar.cc/24"
              style={{ border: `1px solid ${colors.gray}` }}
            />
          </Col>
          {
            (!pendingGetDeviceUsers && deviceUsers.length > 0) &&
              <>
                <Col span={10}>
                  <Text fontSize={13} color="gray_5" mb={2}>
                    Shared with
                  </Text>
                </Col>

                <Col span={14}>
                  <Box mb={2}>

                    <Avatar.Group>
                      {deviceUsers.map((item) =>
                        <Avatar
                          key={item.name}
                          size="small"
                          title={item.name}
                          src={`https://api.multiavatar.com/${item.email}.svg?apikey=DyHpm1NoFUusV5`}
                          style={{ border: `1px solid ${colors.gray}` }}
                          gap={3}
                        />)}
                    </Avatar.Group>

                  </Box>
                </Col>
              </>
          }
          <Col span={10}>
            <Text fontSize={13} color="gray_5">
              Plan
            </Text>
          </Col>
          <Col span={14}>
            <Text fontSize={13}>{device.subscriptionType}</Text>
          </Col>
          <Col span={10}>
            <Text fontSize={13} color="gray_5">
              Status
            </Text>
          </Col>
          <Col span={14}>
            <Text fontSize={13}>{device.status}</Text>
          </Col>
          <Col span={10}>
            <Text fontSize={13} color="gray_5">
              Device name
            </Text>
          </Col>
          <Col span={14}>
            <Text fontSize={13}>{device.name}</Text>
          </Col>
          <Col span={10}>
            <Text fontSize={13} color="gray_5">
              Group
            </Text>
          </Col>
          <Col span={14}>
            <Text fontSize={13}>{device.deviceGroupName}</Text>
          </Col>
          <Col span={10}>
            <Text fontSize={13} color="gray_5">
              Date Created
            </Text>
          </Col>
          <Col span={14}>
            <Text fontSize={13}>{moment(device.createdAt).format('MMMM DD, YYYY')}</Text>
          </Col>
          <Col span={24} />
          <Col span={24} />
          <Col span={10}>
            <Text fontSize={13} color="gray_5">
              Subscription
            </Text>
          </Col>
          <Col span={14}>
            {device?.subscriptionId?.split('-')[0].toUpperCase()}
          </Col>
          {device.status !== 'Offline' &&
            <>
              <Col span={10}>
                <Text fontSize={13} color="gray_5">
                  {device?.subscriptionStatus === 'Trial' ? 'Trial End' : 'Renewal'}
                </Text>
              </Col>

              <Col span={14}>
                {moment(device.subscriptionPeriodEndDate).format('MMM DD, YYYY')}
              </Col>
            </>
          }

          {(device.subscriptionConsumableAiChecks + device.subscriptionMaxAiChecks) > 0 &&
          <>
            <Col span={10}>
              <Text fontSize={13} color="gray_5">
                AI Credits
              </Text>
            </Col>
            <Col span={14}>
              <Text fontSize={13}>
                {
                  (device.subscriptionConsumableAiChecks + device.subscriptionMaxAiChecks)
                  - device.subscriptionCurrentAiChecks
                }
              </Text>
            </Col>
          </>
          }
          <Col span={10}>
            <Text fontSize={13} color="gray_5">
              Storage
            </Text>
          </Col>
          <Col span={14}>
            {device.subscriptionCurrentStorage === null || device.subscriptionCurrentStorage === 0 ?
              <Text fontSize={13}>
                {mbToSize(device.subscriptionMaxStorage)}
              </Text>
              :
              <Text fontSize={13}>
                {mbToSize(device.subscriptionCurrentStorage.toFixed(2))}
                &nbsp;/&nbsp;
                {mbToSize(device.subscriptionMaxStorage)}
              </Text>
            }
          </Col>

          {device.subscriptionMaxDevice > 1 &&
          <>
            <Col span={10}>
              <Text fontSize={13} color="gray_5">
                Device
              </Text>
            </Col>
            <Col span={14}>
              {`${device.subscriptionCurrentDevice} of ${device.subscriptionMaxDevice}`}
            </Col>
          </>
          }

        </Row>
        }
      </Box>
    </Box>
  );
}

export default InfoDrawer;
