import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { openErrorNotification, openSuccessNotification } from 'common/helpers';

export const adminResellerCreate = createAsyncThunk(
  'admin/reseller/create',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const response = await api.post('/admin/resellers', payload);
      if (response) {
        openSuccessNotification({
          message: 'Account Created!',
          description: 'Successfully created a new Reseller Account',
        });
      }
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const adminUsersGet = createAsyncThunk(
  'admin/users/get',
  async (
    { search },
    { rejectWithValue },
  ) => {
    // const api = createAuthenticatedClient();
    try {
      // const { data } = await api.get('/admin/users');
      console.log('search: ', search);
      return [];
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  pendingResellerCreate: false,
  pendingUsersGet: false,
  users: [],
};

export const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adminResellerCreate.fulfilled, (state) => {
        state.pendingResellerCreate = false;
      })
      .addCase(adminResellerCreate.pending, (state) => {
        state.pendingResellerCreate = true;
      })
      .addCase(adminResellerCreate.rejected, (state) => {
        openErrorNotification('Failed to create Reseller.');
        state.pendingResellerCreate = false;
      })
      .addCase(adminUsersGet.fulfilled, (state, { payload }) => {
        state.pendingUsersGet = false;
        state.users = payload;
      })
      .addCase(adminUsersGet.pending, (state) => {
        state.pendingUsersGet = true;
      })
      .addCase(adminUsersGet.rejected, (state) => {
        openErrorNotification('Failed to get Users.');
        state.pendingUsersGet = false;
      });
  },
});

export const selectPendingResellerCreate = (state) => state.admin.pendingResellerCreate;
export const selectPendingUsersGet = (state) => state.admin.pendingUsersGet;
export const selectUsers = (state) => state.admin.users;

export default slice.reducer;
