import React from 'react';
import moment from 'moment';
import { Space, Modal, Button, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { handleLogout as auth0Logout } from 'common/auth0';

import { createAuthenticatedClient } from 'app/client';

import * as S from './styles';

export function PopoverNotification({ list }) {
  const [notification, setNotification] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);

  const handleShowNotificationMessage = (item) => {
    setNotification(item);
    setShowModal(true);
  };

  const handleDeleteNotification = (notificationId) => {
    if (!notificationId) return;
    const api = createAuthenticatedClient();
    api.delete(`/notification/list/${notificationId}`).catch();
  };

  return (
    <S.PopoverNotification>
      <Space direction="vertical" style={{ width: '100%' }}>
        {list.map((item) => {
          const { imageId, id: notificationId } = item;
          return (
            <>{
              imageId ?
                <Link onClick={() => handleDeleteNotification(notificationId)} to={`/app/devices/${item.deviceId}/photo/${imageId}`}>
                  <section>
                    <article>
                      <strong>{item.type}</strong>
                      <Typography.Text>{item.message}</Typography.Text>
                      <small>{moment(item.createdAt).fromNow()}</small>
                    </article>
                    <span className="dot" />
                  </section>
                </Link> :
                <section onClick={() => handleShowNotificationMessage(item)} role="presentation">
                  <article>
                    <strong>{item.type}</strong>
                    <Typography.Text>{item.message}</Typography.Text>
                    <small>{moment(item.createdAt).fromNow()}</small>
                  </article>
                  <span className="dot" />
                </section>
            }
            </>
          );
        })}

      </Space>
      <Modal
        title={notification.type}
        centered
        visible={showModal}
        closable
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <h4>Device</h4>{notification.deviceId}
        <br />
        <br />
        <h4>Message</h4>
        {notification.message}
        <br />
        <br />
        <h4>Date</h4>
        {moment(notification.createdAt).format('MMM-DD-YYYY hh:mm A')}
        <br />
        <br />
        {notification.message?.includes('No available AI check') &&
          <Button style={{ margin: '0 auto', display: 'block' }}>Purchase AI Check Credits</Button>
        }
        {notification.message?.includes('Not enough storage') &&
        <Button style={{ margin: '0 auto', display: 'block' }}>Purchase Storage Credits</Button>
        }
      </Modal>
    </S.PopoverNotification>
  );
}

export function PopoverProfile() {
  const history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    auth0Logout();
    history.push('/');
  };

  return (
    <S.PopoverProfile>
      <Space direction="vertical">
        <Link to="/app/settings/account">Account Settings</Link>
        <a href="/" onClick={handleLogout}>
          Logout
        </a>
      </Space>
    </S.PopoverProfile>
  );
}

export function PopoverDevice() {
  return (
    <S.PopoverDevice>
      <Space direction="vertical">
        <button type="button">View Device</button>
        <button type="button">Device Details</button>
        <button type="button">View Images</button>
        <button type="button">Add to Favorites</button>
        <button type="button">Sharing</button>
        <button type="button">Device Settings</button>
        <button type="button">Delete Device</button>
      </Space>
    </S.PopoverDevice>
  );
}
