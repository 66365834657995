import { CheckCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import colors from 'styles/colors';

function Content2() {
  return (
    <Box backgroundColor="#6D51FD" p={{ _: '40px 20px', md: '80px 40px' }}>
      <Box maxWidth={1126} m="auto">
        <Text as="h2" fontSize={{ _: 21, md: 31 }} mb={6} color="white" fontWeight={600} textAlign="center">What can Image Engine detect through AI image recognition?</Text>
        <Text fontSize={{ _: 18, md: 24 }} fontWeight={600} color="white" textAlign="center" mb={4}>
          From colour detection to smoke detection, Image Engine’s powerful technology can recognise far more objects than camera’s with built in AI technology.
        </Text>
        <Text fontSize={{ _: 18, md: 24 }} fontWeight={600} color="white" textAlign="center">
          Our serivce is constantly learning to identify objects of interest.  These are some of the objects we can currently detect.
        </Text>
      </Box>
      <Box maxWidth={800} m="60px auto 0">
        <Row gutter={[20, 20]}>
          <Col md={8}>
            <Box display="flex" alignItems="center" ml={50}>
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.white }} />
              <Text color="white" fontSize={24} ml={2}>Animals</Text>
            </Box>
          </Col>
          <Col md={8}>
            <Box display="flex" alignItems="center" ml={50}>
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.white }} />
              <Text color="white" fontSize={24} ml={2}>People</Text>
            </Box>
          </Col>
          <Col md={8}>
            <Box display="flex" alignItems="center" ml={50}>
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.white }} />
              <Text color="white" fontSize={24} ml={2}>Smoke</Text>
            </Box>
          </Col>
          <Col md={8}>
            <Box display="flex" alignItems="center" ml={50}>
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.white }} />
              <Text color="white" fontSize={24} ml={2}>Vehicles</Text>
            </Box>
          </Col>
          <Col md={8}>
            <Box display="flex" alignItems="center" ml={50}>
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.white }} />
              <Text color="white" fontSize={24} ml={2}>Boats</Text>
            </Box>
          </Col>
          <Col md={8}>
            <Box display="flex" alignItems="center" ml={50}>
              <CheckCircleFilled style={{ fontSize: '21px', color: colors.white }} />
              <Text color="white" fontSize={24} ml={2}>Fire</Text>
            </Box>
          </Col>
        </Row>
      </Box>
    </Box>
  );
}

export default Content2;
