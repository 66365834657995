import React from 'react';
import { Switch, Route } from 'react-router-dom';
import GuestNav from 'components/GuestNav';
import Box from 'components/Box';
import Footer from 'components/Footer';
import LegalTerms from 'features/LegalTerms';
import LegalPrivacy from 'features/LegalPrivacy';

function LegalPage() {
  return (
    <>
      <GuestNav />
      <Box pt={80}>
        <Switch>
          <Route exact path="/legal/terms-and-conditions">
            <LegalTerms />
          </Route>
          <Route exact path="/legal/privacy-policy">
            <LegalPrivacy />
          </Route>
        </Switch>
      </Box>
      <Footer />
    </>
  );
}

export default LegalPage;
