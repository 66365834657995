import React, { useState } from 'react';
import { remove, findIndex } from 'lodash';
import { Typography, Space, List, Slider, Spin, Select, Row, InputNumber, Button, Checkbox, message } from 'antd';
import { RobotOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { selectPendingUpdateAIDetection, deviceUpdateAIDetection, selectDevice, retrieveDevice } from 'features/DeviceDetail/slice';
import Box from 'components/Box';
import { devicesGet, selectAllDevices } from 'features/DeviceList/slice';
import {
  aiParameterCreate,
  aiParameterDelete,
  aiParametersGetByDeviceId,
  aiParameterUpdate,
  selectDeviceParameters,
  selectPending,
} from './slice';
import * as S from './styles';
import SelectAIParameter from './SelectAIParameter';

const { Title, Text } = Typography;
const { Option } = Select;

function AiParameters(props) {
  const { deviceId } = props;
  const dispatch = useDispatch();
  const currentDevice = useSelector(selectDevice);
  const allDevices = useSelector(selectAllDevices);
  const deviceParameters = useSelector(selectDeviceParameters);
  const loading = useSelector(selectPending);
  const pendingUpdateAIDetection = useSelector(selectPendingUpdateAIDetection);
  const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);
  const [usePersonDetection, setUsePersonDetection] = useState(false);
  const [useObjectsDetection, setUseObjectsDetection] = useState(false);
  const [useFireDetection, setUseFireDetection] = useState(false);
  const [usePlateRecognition, setUsePlateRecognition] = useState(false);
  const [useRodeConeDetection, setUseRodeConeDetection] = useState(false);
  // const [availableParameters, setAvailableParameters] = useState(_availableParameters);
  const [devicesWithAI, setDevicesWithAI] = useState([]);
  const [deviceParametersList, setDeviceParametersList] = useState(deviceParameters);
  const [selectedAIParameters, setSelectedAIParameters] = useState(deviceParameters.map((i) => i.name));
  const [selectedDeviceId, setSelectedDeviceId] = useState(deviceId);

  useUpdateEffect(() => {
    setSelectedAIParameters(deviceParameters.map((i) => i.name));
    setDeviceParametersList(deviceParameters);
  }, [deviceParameters]);

  useUpdateEffect(() => {
    const allDevicesWithAI = remove([...allDevices],
      (device) => ['Platinum', 'Gold', 'Silver', 'Bronze'].includes(device.subscriptionProductId));
    setDevicesWithAI(allDevicesWithAI);
  }, [allDevices]);

  useUpdateEffect(() => {
    if (devicesWithAI.length > 0) {
      setSelectedDeviceId(devicesWithAI[0].id);
      dispatch(aiParametersGetByDeviceId(devicesWithAI[0].id));
      dispatch(retrieveDevice({ id: devicesWithAI[0].id }));
    }
  }, [devicesWithAI]);

  useUpdateEffect(() => {
    if (currentDevice) {
      // TODO: set settings based on device
      setUseObjectsDetection(currentDevice.enableAiObjectDetection);
      setUsePersonDetection(currentDevice.enablePedestrianDetection);
      setUseFireDetection(currentDevice.enableFireDetection);
      setUsePlateRecognition(currentDevice.enablePlateRecognition);
      setUseRodeConeDetection(currentDevice.enableRoadConeDetection);
    }
  }, [currentDevice]);

  useUpdateEffect(() => {
    setSelectedDeviceId(deviceId);

    dispatch(aiParametersGetByDeviceId(deviceId));
    dispatch(retrieveDevice({ id: deviceId }));
  }, [deviceId]);

  useEffectOnce(() => {
    if (deviceId) {
      setSelectedDeviceId(deviceId);
      dispatch(aiParametersGetByDeviceId({ deviceId }));
      dispatch(retrieveDevice({ id: deviceId }));
      setSelectedAIParameters(deviceParameters.map((i) => i.name));
    } else {
      dispatch(devicesGet());
    }
  });

  const handleChangeSelectedDevice = (id) => {
    setSelectedDeviceId(id);
    dispatch(retrieveDevice({ id }));
    dispatch(aiParametersGetByDeviceId(id));
  };

  const handleChangeScore = (score, parameterId) => {
    const index = findIndex(deviceParameters, ['id', parameterId]);
    const newParametersList = [...deviceParameters];
    newParametersList[index] = { ...newParametersList[index], score };
    setDeviceParametersList(newParametersList);
  };

  const handleChangeParameterScore = (score, parameterId) => {
    dispatch(aiParameterUpdate({
      parameterId,
      deviceId: selectedDeviceId,
      score,
    }));
  };

  const handleUpdateAIDetection = async () => {
    if (!useObjectsDetection && !usePersonDetection && !useFireDetection && !usePlateRecognition && !useRodeConeDetection) {
      message.info('Please select at least one AI Detection');
      return;
    }
    await dispatch(deviceUpdateAIDetection({
      deviceId: selectedDeviceId,
      enableAIObjectDetection: useObjectsDetection,
      enablePedestrianDetection: usePersonDetection,
      enableFireDetection: useFireDetection,
      enablePlateRecognition: usePlateRecognition,
      enableRoadConeDetection: useRodeConeDetection,
    }));
    setShowAdvanceSettings(false);
  };

  const handleCrateAIParameter = (parameter) => {
    console.log('parameter:', parameter);
    dispatch(aiParameterCreate({
      name: parameter.value || parameter,
      deviceId: selectedDeviceId,
      score: 50,
    }));
  };

  const handleDeleteAIParameter = (parameter) => {
    const index = findIndex(deviceParameters, ['name', parameter]);
    dispatch(aiParameterDelete({
      parameterId: deviceParameters[index].id,
      deviceId: selectedDeviceId,
    }));
  };

  return (
    <S.AiParameters>
      <Space style={{ margin: '0 0 20px 0' }}>
        <RobotOutlined style={{ fontSize: '21px' }} />
        <Title level={4} style={{ margin: '0' }}>
          {showAdvanceSettings ? 'Device AI Settings' : 'Device AI Parameters'}
        </Title>
      </Space>
      {(!deviceId && devicesWithAI.length > 0) &&
        <Select
          onChange={(val) => handleChangeSelectedDevice(val)}
          defaultValue={devicesWithAI[0]?.id}
          style={{ width: '100%', marginBottom: '20px' }}
        >
          {
            devicesWithAI.map((device) =>
              <Option value={device.id} key={device.id}>
                {`${device.name} [${device.deviceGroupName}] ${device.description !== null ? `- ${device.description}` : ''}`}
              </Option>)
          }
        </Select>}
      <>
        {showAdvanceSettings ?
          <>
            <Text type="secondary">
              <p>
                The AI Engine is able to return two different types of results. These results look at the images and classify the content differently. There are two content types. These are Tags and Objects.
              </p>
              <p>
                <b>General Object Detection</b><br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <p>
                <b>Person Detection</b><br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <p>
                <b>Fire Detection</b><br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <p>
                <b>License Plate Recognition</b><br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </Text>
            <Row justify="space-between" style={{ marginTop: '30px' }}>
              <Text strong>AI Method Settings</Text>
            </Row>
            <Text type="secondary">
              <div className="ai-options">
                <Checkbox onChange={(e) => setUseObjectsDetection(e.target.checked)} checked={useObjectsDetection}>
                  Object Detection
                </Checkbox>
                {/* <Checkbox onChange={(e) => setUsePersonDetection(e.target.checked)} checked={usePersonDetection}> */}
                {/*  Person Detection */}
                {/* </Checkbox> */}
                {/* <Checkbox onChange={(e) => setUseFireDetection(e.target.checked)} checked={useFireDetection}> */}
                {/*  Fire Detection */}
                {/* </Checkbox> */}
                <Checkbox onChange={(e) => setUsePlateRecognition(e.target.checked)} checked={usePlateRecognition}>
                  License Plate Recognition
                </Checkbox>
                <Checkbox onChange={(e) => setUseRodeConeDetection(e.target.checked)} checked={useRodeConeDetection}>
                  Road Cone Detection
                </Checkbox>
              </div>
            </Text>

            <Button style={{ marginRight: '10px' }} onClick={() => setShowAdvanceSettings(false)}>Back</Button>
            <Button
              loading={pendingUpdateAIDetection}
              type="primary"
              onClick={() => handleUpdateAIDetection()}
            >
              {/* Save and Return to AI Parameters */}
              Save AI Settings
            </Button>
            <br />
            <br />
            <Text type="secondary">
              <p>
                <b>IMPORTANT:</b> An AI credit is used for each of the detection methods. If both tags and objects are selected, then the processing of each image will consume 2 credits.
              </p>
            </Text>
          </>
          :
          <>
            <Spin spinning={loading}>
              <span className="click-box">Click on this box to add more parameters</span>
              <SelectAIParameter
                mode="tags"
                value={selectedAIParameters}
                placeholder="Search and select AI Parameters"
                tokenSeparators={[',']}
                onSelect={(item) => {
                  handleCrateAIParameter(item);
                }}
                onDeselect={(item) => {
                  handleDeleteAIParameter(item);
                }}
                style={{
                  width: '100%',
                }}
              />
              <List>
                <List.Item>
                  <S.Item>
                    <section style={{ paddingLeft: '22px' }}>
                      <h4>AI Tags</h4>
                    </section>
                    <Space style={{ paddingRight: '22px' }}>
                      Confidence Level
                    </Space>
                  </S.Item>
                </List.Item>
              </List>
              <List
                style={{ height: '300px', overflowY: 'scroll' }}
                bordered
                dataSource={deviceParametersList}
                renderItem={(item) => {
                  if (selectedAIParameters.includes(item.name)) {
                    return (
                      <List.Item>
                        <S.Item>
                          <section>
                            <h4>{item.name}</h4>
                          </section>
                          <Box display={{ _: 'none', sm: 'block' }}>
                            <Space>
                              <Slider
                                onAfterChange={(val) => handleChangeParameterScore(val, item.id)}
                                onChange={(val) => handleChangeScore(val, item.id)}
                                value={item.score}
                                style={{ width: '200px' }}
                              />
                              <span
                                style={{ width: '40px', textAlign: 'right', display: 'inline-block' }}
                              >{item.score}%
                              </span>
                            </Space>
                          </Box>
                          <Box display={{ _: 'block', sm: 'none' }}>
                            <InputNumber
                              value={item.score}
                              min={0}
                              max={100}
                              formatter={(val) => `${val}%`}
                              parser={(val) => val.replace('%', '')}
                              onChange={(val) => handleChangeParameterScore(val, item.id)}
                              style={{ width: '70px' }}
                            />
                          </Box>
                        </S.Item>
                      </List.Item>
                    );
                  }
                  return null;
                }}
              />
            </Spin>
            <Row justify="space-between" style={{ marginTop: '30px' }}>
              <Text strong>Device Sensitivity</Text>
            </Row>
            <Text type="secondary">
              <p>
                Each image we pass through the AI Engine returns a tag with a score.
                The tag is an item that the AI detects in a image.
                The score is a range of 1 to 100. The closer the AI assigns a score to 100, the higher
                the confidence that the AI believes that it is correct.
              </p>
              {/* <p> */}
              {/*  We use this information to set the confidence level for each item that we would like to detect in an image. */}
              {/* </p> */}
            </Text>
            <Text type="secondary">
              <p>
                The AI Engine uses two methods to extract information from images. If the results returned are not what was expected,
                please go to the <b>Advanced Settings</b> to change the detection method.
              </p>
            </Text>
            <Button type="primary" onClick={() => setShowAdvanceSettings(true)}>Advance Settings</Button>
          </>
      }

      </>
    </S.AiParameters>
  );
}

export default AiParameters;
