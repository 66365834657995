import { useSelector, useDispatch } from 'react-redux';

import {
  createSavedPlates as createSavedPlatesAction,
  deleteSavedPlates as deleteSavedPlatesAction,
  getSavedPlates as getSavedPlatesAction,
  selectBlackListedPlates,
  selectWhiteListedPlates,
  selectPendingGet,
  selectPendingCreate,
  selectPendingDelete,
} from './slice';

function useSavedPlateNumbers() {
  const dispatch = useDispatch();
  const whiteListedPlates = useSelector(selectWhiteListedPlates);
  const blackListedPlates = useSelector(selectBlackListedPlates);
  const pendingCreate = useSelector(selectPendingCreate);
  const pendingGet = useSelector(selectPendingGet);
  const pendingDelete = useSelector(selectPendingDelete);

  const getSavedPlates = () => {
    dispatch(getSavedPlatesAction());
  };

  const createSavedPlates = (payload) => {
    dispatch(createSavedPlatesAction(payload));
  };

  const deleteSavedPlates = (plateId) => {
    dispatch(deleteSavedPlatesAction({ plateId }));
  };

  return {
    whiteListedPlates,
    blackListedPlates,
    pendingGet,
    pendingDelete,
    pendingCreate,
    getSavedPlates,
    createSavedPlates,
    deleteSavedPlates,
  };
}

export default useSavedPlateNumbers;
