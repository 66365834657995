export const PRICING_DATA = [
  {
    title: 'Copper',
    slug: 'copper',
    price: '$2.06/month',
    subtitle: 'Storage Only Plan',
    recommended: false,
    imageStore: '4 Megapixels',
    aiChecks: '-',
    storage: '2 GB',
    ftpCamera: true,
    processTime: '5 seconds',
    uploadSpeed: '75 kb/s',
    emailSupport: true,
    addOnStorage: true,
    addOnAi: false,
    additionalCameras: false,
    trial: false,
  },
  {
    title: 'Bronze',
    slug: 'bronze',
    price: '$4.86/month',
    subtitle: 'Low Volume Plan',
    recommended: false,
    imageStore: '4 Megapixels',
    aiChecks: '500 AI Checks',
    storage: '2 GB',
    ftpCamera: true,
    processTime: '5 seconds',
    uploadSpeed: '75 kb/s',
    emailSupport: true,
    addOnStorage: false,
    addOnAi: false,
    additionalCameras: false,
    trial: false,
  },
  {
    title: 'Silver',
    slug: 'silver',
    price: '$6.96/month',
    subtitle: 'Entry Level Plan',
    recommended: false,
    imageStore: '4 Megapixels',
    aiChecks: '1000 AI Checks',
    storage: '5 GB',
    ftpCamera: true,
    processTime: '3 seconds',
    uploadSpeed: '150 kb/s',
    emailSupport: true,
    addOnStorage: false,
    addOnAi: false,
    additionalCameras: false,
    trial: false,
  },
  {
    title: 'Gold',
    slug: 'gold',
    price: '$9.06/month',
    subtitle: 'Standard Level Plan',
    recommended: false,
    imageStore: '4 Megapixels',
    aiChecks: '2,000 AI Checks',
    storage: '10 GB',
    ftpCamera: true,
    processTime: '1 second',
    uploadSpeed: '400 kb/s',
    emailSupport: true,
    addOnStorage: false,
    addOnAi: false,
    additionalCameras: false,
    trial: false,
  },
  {
    title: 'Platinum',
    slug: 'platinum',
    price: '$10.46/month',
    subtitle: 'Premium Level Plan',
    recommended: false,
    imageStore: '4 Megapixels',
    aiChecks: '4,000 AI Checks',
    storage: '20 GB',
    ftpCamera: true,
    processTime: '1 second',
    uploadSpeed: '400 kb/s',
    emailSupport: true,
    addOnStorage: true,
    addOnAi: true,
    additionalCameras: true,
    trial: false,
  },
];
