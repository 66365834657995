import React from 'react';
import { Button } from 'antd';
import Box from 'components/Box';
import bgHeader from 'images/camera-cloud-storage/header-bg.jpg';
import Text from 'components/Text';
import { useHistory } from 'react-router-dom';

function Header() {
  const history = useHistory();
  return (
    <Box
      width="100%"
      background={`#f9f9fc url(${bgHeader}) bottom center/cover no-repeat`}
      p={{ _: '40px 20px', lg: '80px 40px' }}
    >
      <Box maxWidth={1320} m="auto">
        <Box maxWidth={600}>
          <Text as="h1" fontSize={{ _: 30, md: 40 }} lineHeight={1.2} fontWeight={600}>
            Security camera cloud storage
          </Text>
          <Text fontSize={{ _: 16, md: 18 }} lineHeight={1.4} my={4}>
            Access your security camera images from any device for an unlimited time without any data caps.
          </Text>
          <Button type="primary" size="large" onClick={() => history.push('/register')}>
            Try for FREE
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
