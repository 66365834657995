import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { selectUserRole } from 'features/Users/slice';
import ResellerUserList from './reseller';
import AdminUserList from './admin';
import Filter from './Filter';

function DevicesList() {
  const userRole = useSelector(selectUserRole);
  const [view, setView] = useState('list');

  return (
    <>
      <Filter setView={setView} view={view} />
      {userRole.includes('Admin') && <AdminUserList />}
      {userRole.includes('Reseller') && <ResellerUserList />}
    </>
  );
}

export default DevicesList;
