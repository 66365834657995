import { useDispatch, useSelector } from 'react-redux';
import {
  selectModalActive,
  selectResellerCustomer,
  setResellerCustomer as setCustomer,
  setModalActive, selectCards, selectPendingSetDefaultCard,
} from './slice';

function useAddDevice() {
  const dispatch = useDispatch();
  const isModalActive = useSelector(selectModalActive);
  const resellerCustomer = useSelector(selectResellerCustomer);
  const cards = useSelector(selectCards);
  const pendingSetDefaultCard = useSelector(selectPendingSetDefaultCard);

  const showModal = (val) => {
    dispatch(setModalActive(val));
  };

  const setResellerCustomer = (val) => {
    dispatch(setCustomer(val));
  };

  return { cards, pendingSetDefaultCard, showModal, setResellerCustomer, isModalActive, resellerCustomer };
}

export default useAddDevice;
