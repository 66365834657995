import React from 'react';
import { Space, Typography, Button, message } from 'antd';
import { useSelector } from 'react-redux';
import { DollarOutlined, CopyOutlined } from '@ant-design/icons';

import { useUpdateEffect, useCopyToClipboard } from 'react-use';
import Box from 'components/Box';
import { selectUserMe } from 'features/Users/slice';

const { Title, Text } = Typography;

function Reseller() {
  const [, copyToClipboard] = useCopyToClipboard();
  const userMe = useSelector(selectUserMe);

  console.log('userMe:', userMe);

  const handleCopyCode = (text) => {
    copyToClipboard(text);
    message.info('Referral Code copied to clipboard!');
  };

  const handleCopyRegistrationLink = (text) => {
    copyToClipboard(text);
    message.info('Registration Link with Code copied to clipboard!');
  };

  useUpdateEffect(() => {
    if (userMe) {
      const { email } = userMe;
      console.log(email);
    }
  }, [userMe]);

  return (
    <Box p={{ _: 20, sm: 30 }}>
      <Space style={{ margin: '0 0 20px 0' }}>
        <DollarOutlined style={{ fontSize: '21px' }} />
        <Title level={4} style={{ margin: '0' }}>
          Reseller Settings
        </Title>
      </Space>

      <Title level={5} style={{ margin: '20px 0 0 0' }}>
        Referral Code
      </Title>
      <Text type="secondary" style={{ marginBottom: '10px', display: 'block', fontWeight: 'bold' }}>
        {userMe.profile?.code}
      </Text>
      <Button
        type="primary"
        icon={<CopyOutlined />}
        onClick={() => handleCopyCode(userMe.profile?.code)}
      >
        Copy Code
      </Button>

      <Title level={5} style={{ margin: '20px 0 0 0' }}>
        Registration Link
      </Title>
      <Text type="secondary" style={{ marginBottom: '10px', display: 'block' }}>
        {`${process.env.REACT_APP_BASE_URL}/register?code=${userMe.profile?.code}`}
      </Text>
      <Button
        type="primary"
        icon={<CopyOutlined />}
        onClick={() => handleCopyRegistrationLink(`${process.env.REACT_APP_BASE_URL}/register?code=${userMe.profile?.code}`)}
      >
        Copy Link
      </Button>
    </Box>
  );
}

export default Reseller;
