import { Row, Col, Button } from 'antd';
import {
  // AndroidOutlined,
  // AppleOutlined,
  // MobileOutlined,
  RobotOutlined,
  ScanOutlined,
  DatabaseOutlined,
  VideoCameraOutlined,
  FieldTimeOutlined,
  UploadOutlined,
  MailOutlined,
  ClusterOutlined,
} from '@ant-design/icons';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import checkTrue from 'images/check-true.svg';
import checkFalse from 'images/check-false.svg';

import { useHistory } from 'react-router-dom';
import { PRICING_DATA } from './constants';

function PricingTable() {
  const history = useHistory();

  return (
    <Box maxWidth={1200} p={40} m="auto" display={{ _: 'none', lg: 'block' }}>
      <Text fontSize={{ _: 18, sm: 21 }} letterSpacing={0.005} textAlign="center">
        Choose the plan that best suits your needs and start your FREE 7 Days
        trial.
      </Text>
      <Box pt={80}>
        <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px">
            <Box display="flex" alignItems="center" height="100%">
              <Text fontSize={24} fontWeight={600} maxWidth={185}>
                Compare Plan and Features
              </Text>
            </Box>
          </Col>
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                p={20}
                textAlign="center"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
                position="relative"
              >
                <Text fontSize={18}>{i.title}</Text>
                <Text fontSize={10} fontWeight={600}>Early Adopter Pricing</Text>
                <Text fontSize={18} fontWeight={600}>
                  {i.price}
                </Text>
                <Text fontSize={11}>{i.subtitle}</Text>
                {i.recommended && (
                  <Box
                    width="100%"
                    position="absolute"
                    backgroundColor="secondary"
                    p={1}
                    left={0}
                    top={-32}
                  >
                    <Text color="white">Recommended</Text>
                  </Box>
                )}
              </Box>
            </Col>
          ))}
        </Row>
        {/* <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px">
            <Box display="flex" alignItems="center" height="100%">
              <SearchOutlined style={{ fontSize: '21px' }} />
              <Text fontSize={14} ml={2}>
                Image Store and Analyse
              </Text>
            </Box>
          </Col>
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                px={20}
                py={15}
                textAlign="center"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
              >
                <Text fontSize={14}>{i.imageStore}</Text>
              </Box>
            </Col>
          ))}
        </Row> */}
        <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px">
            <Box display="flex" alignItems="center" height="100%">
              <ScanOutlined style={{ fontSize: '21px' }} />
              <Text fontSize={14} ml={2}>
                AI Checks / Month
              </Text>
            </Box>
          </Col>
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                px={20}
                py={15}
                textAlign="center"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
              >
                <Text fontSize={14}>{i.aiChecks}</Text>
              </Box>
            </Col>
          ))}
        </Row>
        <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px">
            <Box display="flex" alignItems="center" height="100%">
              <DatabaseOutlined style={{ fontSize: '21px' }} />
              <Text fontSize={14} ml={2}>
                Storage
              </Text>
            </Box>
          </Col>
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                px={20}
                py={15}
                textAlign="center"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
              >
                <Text fontSize={14}>{i.storage}</Text>
              </Box>
            </Col>
          ))}
        </Row>
        <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px">
            <Box display="flex" alignItems="center" height="100%">
              <VideoCameraOutlined style={{ fontSize: '21px' }} />
              <Text fontSize={14} ml={2}>
                Supports FTP and FTPS Camera
              </Text>
            </Box>
          </Col>
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                px={20}
                py={15}
                textAlign="center"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
              >
                {i.ftpCamera ? (
                  <img src={checkTrue} alt="check" />
                ) : (
                  <img src={checkFalse} alt="check" />
                )}
              </Box>
            </Col>
          ))}
        </Row>
        <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px">
            <Box display="flex" alignItems="center" height="100%">
              <FieldTimeOutlined style={{ fontSize: '21px' }} />
              <Text fontSize={14} ml={2}>
                Process Time Per Image
              </Text>
            </Box>
          </Col>
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                px={20}
                py={15}
                textAlign="center"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
              >
                <Text fontSize={14}>{i.processTime}</Text>
              </Box>
            </Col>
          ))}
        </Row>
        <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px">
            <Box display="flex" alignItems="center" height="100%">
              <UploadOutlined style={{ fontSize: '21px' }} />
              <Text fontSize={14} ml={2}>
                Upload Speed
              </Text>
            </Box>
          </Col>
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                px={20}
                py={15}
                textAlign="center"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
              >
                <Text fontSize={14}>{i.uploadSpeed}</Text>
              </Box>
            </Col>
          ))}
        </Row>
        {/* <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}> */}
        {/*  <Col flex="0 0 280px"> */}
        {/*    <Box display="flex" alignItems="center" height="100%"> */}
        {/*      <MobileOutlined style={{ fontSize: '21px' }} /> */}
        {/*      <Text fontSize={14} ml={2}> */}
        {/*        Mobile OS Built */}
        {/*      </Text> */}
        {/*    </Box> */}
        {/*  </Col> */}
        {/*  {PRICING_DATA.map((i) => ( */}
        {/*    <Col flex="0 0 165px" key={i.slug}> */}
        {/*      <Box */}
        {/*        px={20} */}
        {/*        py={15} */}
        {/*        textAlign="center" */}
        {/*        backgroundColor={i.recommended ? 'white_1' : 'white'} */}
        {/*      > */}
        {/*        <AppleOutlined style={{ fontSize: '18px', margin: '0 3px' }} /> */}
        {/*        <AndroidOutlined */}
        {/*          style={{ fontSize: '18px', margin: '0 3px' }} */}
        {/*        /> */}
        {/*      </Box> */}
        {/*    </Col> */}
        {/*  ))} */}
        {/* </Row> */}
        <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px">
            <Box display="flex" alignItems="center" height="100%">
              <MailOutlined style={{ fontSize: '21px' }} />
              <Text fontSize={14} ml={2}>
                Email Support
              </Text>
            </Box>
          </Col>
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                px={20}
                py={15}
                textAlign="center"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
              >
                {i.emailSupport ? (
                  <img src={checkTrue} alt="check" />
                ) : (
                  <img src={checkFalse} alt="check" />
                )}
              </Box>
            </Col>
          ))}
        </Row>
        <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px">
            <Box display="flex" alignItems="center" height="100%">
              <ClusterOutlined style={{ fontSize: '21px' }} />
              <Text fontSize={14} ml={2}>
                Add-on Storage Units
              </Text>
            </Box>
          </Col>
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                px={20}
                py={15}
                textAlign="center"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
              >
                {i.addOnStorage ? (
                  <img src={checkTrue} alt="check" />
                ) : (
                  <img src={checkFalse} alt="check" />
                )}
              </Box>
            </Col>
          ))}
        </Row>
        <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px">
            <Box display="flex" alignItems="center" height="100%">
              <RobotOutlined style={{ fontSize: '21px' }} />
              <Text fontSize={14} ml={2}>
                Add-on AI Units
              </Text>
            </Box>
          </Col>
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                px={20}
                py={15}
                textAlign="center"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
              >
                {i.addOnAi ? (
                  <img src={checkTrue} alt="check" />
                ) : (
                  <img src={checkFalse} alt="check" />
                )}
              </Box>
            </Col>
          ))}
        </Row>
        <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px">
            <Box display="flex" alignItems="center" height="100%">
              <VideoCameraOutlined style={{ fontSize: '21px' }} />
              <Text fontSize={14} ml={2}>
                Add Additional Cameras
              </Text>
            </Box>
          </Col>
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                px={20}
                py={15}
                textAlign="center"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
              >
                {i.additionalCameras ? (
                  <img src={checkTrue} alt="check" />
                ) : (
                  <img src={checkFalse} alt="check" />
                )}
              </Box>
            </Col>
          ))}
        </Row>
        <Row style={{ borderBottom: '1px solid #DDE0F9' }} wrap={false}>
          <Col flex="0 0 280px" />
          {PRICING_DATA.map((i) => (
            <Col flex="0 0 165px" key={i.slug}>
              <Box
                py={15}
                px={10}
                textAlign="center"
                height="100%"
                backgroundColor={i.recommended ? 'white_1' : 'white'}
              >
                <Button
                  type={i.recommended ? 'primary' : 'default'}
                  block
                  onClick={() => history.push('/register')}
                  size="large"
                >
                  Choose Plan
                </Button>
                {i.trial && <Button type="link">Try it for free</Button>}
              </Box>
            </Col>
          ))}
        </Row>
      </Box>
    </Box>
  );
}

export default PricingTable;
