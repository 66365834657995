import React, { useState } from 'react';
import { startCase } from 'lodash';
import moment from 'moment/moment';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import { Row, Col, DatePicker, Button } from 'antd';
import Text from 'components/Text';
import Box from 'components/Box';

import { useEffectOnce, useUpdateEffect } from 'react-use';
import axios from 'axios';
import localStorage from 'localStorage';
import { useGetReports } from './hooks';

const fileDownload = require('js-file-download');

const { RangePicker } = DatePicker;

function PieChartSection({ data }) {
  const [getReports] = useGetReports();
  const [loading, setLoading] = React.useState(false);
  const [pieData, setPieData] = React.useState([]);
  const [dateRange, setDateRange] = useState([moment().startOf('year'), moment().endOf('year')]);

  const handleExportData = () => {
    const downloadApi = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Accept: 'application/json',
        'Content-Disposition': 'attachment',
        'Content-Type': 'application/octet-stream', // or Content-type: "application/vnd.ms-excel"
      },
    });

    setLoading(true);

    downloadApi.get('/report/detections/export', {
      params: {
        from: dateRange[0].toISOString(),
        to: dateRange[1].toISOString(),
      },
    }).then((response) => {
      fileDownload(
        response.data,
        `exported-data-${moment(dateRange[0]).format(
          'DD-MM-YYYY',
        )}-to-${moment(dateRange[1]).format('DD-MM-YYYY')}.csv`,
      );
      setLoading(false);
    })
      .catch(() => {
        setLoading(false);
      });
  };

  const onChange = (dates, dateStrings) => {
    if (dates) {
      getReports(
        moment(dateStrings[0]).toISOString(),
        moment(dateStrings[1]).toISOString(),
      );
      setDateRange([
        moment(dateStrings[0]),
        moment(dateStrings[1]),
      ]);
    }
  };

  useUpdateEffect(() => {
    const newData = data.map(({ type, detectionCount }) => {
      return {
        name: type,
        color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        value: detectionCount,
      };
    });

    setPieData(newData);
  }, [data]);

  useEffectOnce(() => {
    getReports(
      moment().startOf('year').toISOString(),
      moment().endOf('year').toISOString(),
    );
  });

  return (
    <Box
      width="100%"
      border="1px solid"
      borderColor="gray_4"
      p={{ _: '20px 10px', md: 5 }}
      backgroundColor="white"
      borderRadius={4}
      height="100%"
    >
      <Box display="flex" alignItems="center" justifyContent={{ _: 'center', md: 'flex-start' }}>
        <Text fontSize={18} fontWeight={600} letterSpacing="0.005em" mr={2}>
          AI Detections:
        </Text>
        <RangePicker
          value={dateRange}
          defaultValue={[moment().startOf('year'),
            moment().endOf('year')]}
          ranges={{
            Today: [moment(), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
          }}
          onChange={onChange}
        />
        <Button loading={loading} onClick={handleExportData} style={{ marginLeft: '10px' }}>Export Data</Button>
      </Box>
      <Box mt={4} display="flex" alignItems="center" justifyContent="center" flexDirection={{ _: 'column', sm: 'row' }}>
        <Box width={300}>
          <Row gutter={[10, 20]}>
            {pieData.map((i) => (
              <Col span={12} key={i.name}>
                <Box display="flex" alignItems="center">
                  <Box
                    height={16}
                    width={16}
                    backgroundColor={i.color}
                    borderRadius={4}
                  />
                  <Text fontSize={13} ml={1}>
                    {startCase(i.name)}
                  </Text>
                </Box>
              </Col>
            ))}
          </Row>
        </Box>
        <Box>
          <PieChart width={300} height={200}>
            <Pie
              dataKey="value"
              data={pieData}
              innerRadius={50}
              outerRadius={70}
              fill="#82ca9d"
            >
              {pieData.map((entry) => (
                <Cell
                  key={`cell-${entry.name}`}
                  fill={entry.color}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </Box>
      </Box>
    </Box>
  );
}

export default PieChartSection;
