import React from 'react';
import Box from 'components/Box';
import bgHeader from 'images/about-us/bg-header.jpg';
import Text from 'components/Text';

function Header() {
  return (
    <Box
      width="100%"
      background={`#f9f9fc url(${bgHeader}) top center/cover no-repeat`}
      p={{ _: '40px 20px', lg: '80px 40px' }}
    >
      <Box maxWidth={1320} m="40px auto">
        <Box maxWidth={600}>
          <Text as="h1" fontSize={{ _: 30, md: 40 }} lineHeight={1.2} fontWeight={600}>
            About Image Engine
          </Text>
          <Text fontSize={{ _: 16, md: 18 }} lineHeight={1.4} my={4}>
            Image Engine were driven by the desire to provide affordable business intelligence.
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
