import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import { Col, Row } from 'antd';
import icon1 from 'images/camera-cloud-storage/icon-1.svg';
import icon2 from 'images/camera-cloud-storage/icon-2.svg';
import icon3 from 'images/camera-cloud-storage/icon-3.svg';
import icon4 from 'images/camera-cloud-storage/icon-4.svg';
import icon5 from 'images/camera-cloud-storage/icon-5.svg';
import icon6 from 'images/camera-cloud-storage/icon-6.svg';

function Content1() {
  return (
    <Box>
      <Box p={{ _: 40, sm: 60 }}>
        <Text fontSize={{ _: 20, md: 30 }} fontWeight={600} textAlign="center">What does Image Engine’s camera cloud storage offer? </Text>
      </Box>
      <Box
        py={40}
        px={4}
        background="linear-gradient(112.8deg, #6d51fd 20.17%, #9ca2ff 87.86%)"
      >
        <Box maxWidth={1100} m="auto" color="white">
          <Row gutter={[40, 40]}>
            <Col md={{ _: 24, span: 12 }}>
              <Box display="flex" alignItems="flex-start">
                <img src={icon1} alt="Unlimited storage capability" />
                <Box ml={3}>
                  <Text color="white" fontSize={18} fontWeight={600}>Unlimited storage capability</Text>
                  <Text color="white" fontSize={14}>
                    Select the plan that is right for you with our storage calculator. On our top plan, add storage blocks to give you the image retention you require. We provide a handy tool to allow you to estimate your storage.
                  </Text>
                </Box>
              </Box>
            </Col>
            <Col md={{ _: 24, span: 12 }}>
              <Box display="flex" alignItems="flex-start">
                <img src={icon2} alt="Unlimited Uploading" />
                <Box ml={3}>
                  <Text color="white" fontSize={18} fontWeight={600}>Unlimited Image Uploading </Text>
                  <Text color="white" fontSize={14}>We do not have any data caps on image uploading. Image storage capacity is based on your chosen plan.</Text>
                </Box>
              </Box>
            </Col>
            <Col md={{ _: 24, span: 12 }}>
              <Box display="flex" alignItems="flex-start">
                <img src={icon3} alt="Camera Sharing" />
                <Box ml={3}>
                  <Text color="white" fontSize={18} fontWeight={600}>Camera Sharing</Text>
                  <Text color="white" fontSize={14}>Invite and share your cameras with others from any smart phone or internet browser. Control what access the invited person has with permissions.</Text>
                </Box>
              </Box>
            </Col>
            <Col md={{ _: 24, span: 12 }}>
              <Box display="flex" alignItems="flex-start">
                <img src={icon4} alt="Download Full Resolution Images" />
                <Box ml={3}>
                  <Text color="white" fontSize={18} fontWeight={600}>Download Full Resolution Images</Text>
                  <Text color="white" fontSize={14}>Download your images in the resolution you uploaded them in.</Text>
                </Box>
              </Box>
            </Col>
            <Col md={{ _: 24, span: 12 }}>
              <Box display="flex" alignItems="flex-start">
                <img src={icon5} alt="Favourites" />
                <Box ml={3}>
                  <Text color="white" fontSize={18} fontWeight={600}>Favourites</Text>
                  <Text color="white" fontSize={14}>Save your cameras or any shared devices to favourites for easy access.</Text>
                </Box>
              </Box>
            </Col>
            <Col md={{ _: 24, span: 12 }}>
              <Box display="flex" alignItems="flex-start">
                <img src={icon6} alt="Gallery View" />
                <Box ml={3}>
                  <Text color="white" fontSize={18} fontWeight={600}>Gallery View</Text>
                  <Text color="white" fontSize={14}>
                    Save Images to your gallery for easy access later on.
                  </Text>
                </Box>
              </Box>
            </Col>
          </Row>
        </Box>
      </Box>
    </Box>
  );
}

export default Content1;
