import React from 'react';
import GuestNav from 'components/GuestNav';
import Box from 'components/Box';
import Footer from 'components/Footer';
import GetStarted from 'components/GetStarted';

import Header from './Header';
import Storage from './Storage';
import PricingTable from './PricingTable';
import PricingSlider from './PricingSlider';

function Pricing() {
  return (
    <>
      <GuestNav />
      <Box pt={80}>
        <Header />
        <PricingTable />
        <PricingSlider />
        <Storage />
        <GetStarted />
      </Box>
      <Footer />
    </>
  );
}

export default Pricing;
