import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Space, Avatar, Dropdown, Menu, Modal, message } from 'antd';
import {
  ArrowLeftOutlined,
  ShareAltOutlined,
  SettingOutlined, CloseOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import Box from 'components/Box';
import Text from 'components/Text';

import { useEffectOnce } from 'react-use';
import useDeviceSharing from 'features/DeviceSharing/hooks';
import AiParameters from 'features/AiParameters';
import DeviceSharing from 'features/DeviceSharing';
import DeviceSubscription from 'features/DeviceSubscription';
import DeviceAddOn from 'features/DeviceAddOn';
import useDeviceSubscription from 'features/DeviceSubscription/hooks';

import colors from 'styles/colors';

import { useDispatch, useSelector } from 'react-redux';
import { deleteDevice, deviceChangePassword } from '../slice';
import * as S from '../styles';
import { selectUserRole } from '../../Users/slice';
import { devicesGet } from '../../DeviceList/slice';
import { deviceSubscriptionDeleteImmediately } from '../../DeviceSubscription/slice';

const { confirm } = Modal;

function Header({ device }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { showModal: showSubscriptionModal, isModalActive } = useDeviceSubscription();
  const [showAiParametersModal, setShowAIParametersModal] = useState(false);
  const [showDeviceSharingModal, setShowDeviceSharingModal] = useState(false);
  const [showDeviceAddOnModal, setShowDeviceAddOnModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [showStorage, setShowStorage] = useState(false);
  const userRole = useSelector(selectUserRole);

  const {
    getDeviceUsers,
    deviceUsers,
  } = useDeviceSharing();

  const handleShowAIParameters = (deviceId) => {
    setSelectedDevice(deviceId);
    setShowAIParametersModal(true);
  };

  const handleShowDeviceSubscription = (deviceId, displayStorage) => {
    setSelectedDevice(deviceId);
    setShowStorage(displayStorage);
    showSubscriptionModal(true);
  };

  const handleShowDeviceAddOns = (deviceId) => {
    setSelectedDevice(deviceId);
    setShowDeviceAddOnModal(true);
  };

  const handleShowDeviceSharing = (deviceId) => {
    setSelectedDevice(deviceId);
    setShowDeviceSharingModal(true);
  };

  const handleChangePassword = async (deviceId) => {
    confirm({
      title: 'Confirm Password Change?',
      icon: <ExclamationCircleOutlined />,
      content: 'A new password will be generated for this device and it will be sent to your email',
      onOk: async () => {
        message.loading('Generating new password...', 0);
        let newPassword = '';
        const characters = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < 10; i += 1) {
          newPassword += characters.charAt(Math.floor(Math.random() *
            charactersLength));
        }

        const data = await dispatch(deviceChangePassword({
          deviceId,
          newPassword,
        }));

        message.destroy();
        if (data.errors) {
          message.info(data.errors[0]?.message);
        } else {
          message.success('New password sent to email. Please update your device settings to continue uploading image.');
        }
      },
      onCancel() {
      },
    });
  };

  const handleDeleteDevice = (payload) => {
    const { subscriptionId, id: deviceId } = payload;
    Modal.confirm({
      title: 'Confirm Delete Device',
      content: 'Are you sure you want to delete this device? All photos on this device will be deleted.',
      onOk: async () => {
        await dispatch(deleteDevice({ subscriptionId, deviceId }));
        history.push('/app/devices');
      },
      okText: 'Delete Device and Photos',
    });
  };

  const handleCancelSubscription = (payload) => {
    const { subscriptionId, subscriptionCurrentDevice } = payload;
    Modal.confirm({
      title: 'Confirm Subscription Cancellation',
      content: subscriptionCurrentDevice > 1 ?
        <p>
          Are you sure you want to cancel this subscription?
          <br />
          <br />
          All photos from all connected device will be deleted permanently.
          <br />
          <br />
          You currently have <b>{subscriptionCurrentDevice} Devices</b> under this subscription.
        </p>
        : 'Are you sure you want to cancel this subscription? All photos from this device will be deleted permanently',
      onOk: async () => {
        await dispatch(deviceSubscriptionDeleteImmediately({ subscriptionId }));
        await dispatch(devicesGet());
      },
      okText: 'Cancel Subscription',
    });
  };

  useEffectOnce(() => {
    if (device?.id && device.isOwner) {
      getDeviceUsers(device.id);
    }
  });

  return (
    <S.Header>
      <aside>
        <Link to="/app/devices">
          <Box display="flex" alignItems="center" color="black">
            <ArrowLeftOutlined style={{ marginRight: '5px' }} />
            <Text fontSize={{ _: 12, sm: 14 }}>{device?.name}</Text>
          </Box>
        </Link>
      </aside>
      <section>
        <Space size={0} align="center">
          {deviceUsers?.length > 0 &&
            <Avatar.Group style={{ display: 'flex', marginRight: '10px' }}>
              {deviceUsers.map((item) =>
                <Avatar
                  key={item.name}
                  size="small"
                  title={`Shared with ${item.name}`}
                  src={`https://api.multiavatar.com/${item.email}.svg?apikey=DyHpm1NoFUusV5`}
                  style={{ border: `1px solid ${colors.gray}` }}
                  gap={3}
                />)}
            </Avatar.Group>
          }
          <Box display={{ _: 'none', sm: 'block' }}>
            {(device.deviceUserAllowConfigureAi ||
              device.deviceUserAllowManageUser ||
              device.deviceUsesIsOwner)
              ?
                <Dropdown
                  overlay={
                    <Menu>
                      {device.deviceUserAllowManageUser !== 0 &&
                      <Menu.Item
                        key="1"
                        onClick={() => handleShowDeviceSharing(device.id)}
                      >
                        Share Device
                      </Menu.Item>
                    }
                      {(device.deviceUserAllowConfigureAi !== 0 &&
                      ['Platinum', 'Gold', 'Silver', 'Bronze'].includes(device.subscriptionType)) &&
                      <Menu.Item
                        key="2"
                        onClick={() => handleShowAIParameters(device.id)}
                      >
                        AI Parameters
                      </Menu.Item>
                    }
                      {(device.deviceUserIsOwner !== 0 &&
                      (!userRole.includes('Customer'))) &&
                      <>
                        <>
                          {(device.deviceUserAllowConfigureAi !== 0 && device.subscriptionType === 'Platinum') &&
                          <Menu.Item
                            key="3"
                            onClick={() => handleShowDeviceAddOns(device)}
                          >Add Ons
                          </Menu.Item>
                        }
                        </>
                        <>
                          {(device.subscriptionType === 'Platinum' || device.subscriptionType === 'Copper') &&
                          <Menu.Item
                            key="4"
                            onClick={() => handleShowDeviceSubscription(device.id, true)}
                          >
                            Change Storage
                          </Menu.Item>
                        }
                        </>
                        <Menu.Item
                          key="6"
                          onClick={() => handleShowDeviceSubscription(device.id, false)}
                        >
                          Change Subscription
                        </Menu.Item>
                        <Menu.Item
                          key="7"
                          onClick={() => handleCancelSubscription(device)}
                        >
                          Cancel Subscription
                        </Menu.Item>

                      </>
                    }
                      {device.deviceUserIsOwner !== 0 &&
                      <>
                        <Menu.Item
                          key="5"
                          onClick={() => handleChangePassword(device.id)}
                        >
                          Change Password
                        </Menu.Item>
                        <Menu.Item
                          key="8"
                          onClick={() => handleDeleteDevice(device)}
                        >
                          Delete Device
                        </Menu.Item>
                      </>
                    }
                    </Menu>
                }
                  trigger={['click']}
                >
                  <Button type="text" icon={<SettingOutlined />}>
                    Settings
                  </Button>
                </Dropdown>
              :
              null
            }
          </Box>
          <Box display={{ _: 'block', sm: 'none' }}>
            <Space>
              <ShareAltOutlined style={{ fontSize: '18px' }} />
              <SettingOutlined style={{ fontSize: '18px' }} />
            </Space>
          </Box>
        </Space>
      </section>
      <Modal
        destroyOnClose
        visible={showAiParametersModal}
        footer={null}
        width={800}
        closeIcon={<CloseOutlined onClick={() => setShowAIParametersModal(false)} />}
      >
        <AiParameters deviceId={selectedDevice} />
      </Modal>
      <Modal
        destroyOnClose
        visible={showDeviceSharingModal}
        footer={null}
        width={600}
        closeIcon={<CloseOutlined onClick={() => setShowDeviceSharingModal(false)} />}
      >
        <DeviceSharing deviceId={selectedDevice} />
      </Modal>
      <Modal
        destroyOnClose
        visible={isModalActive}
        footer={null}
        width={600}
        closeIcon={<CloseOutlined onClick={() => showSubscriptionModal(false)} />}
      >
        <DeviceSubscription showStorage={showStorage} deviceId={selectedDevice} />
      </Modal>
      <Modal
        destroyOnClose
        visible={showDeviceAddOnModal}
        footer={null}
        width={600}
        closeIcon={<CloseOutlined onClick={() => setShowDeviceAddOnModal(false)} />}
      >
        <DeviceAddOn device={selectedDevice} onPurchase={() => setShowDeviceAddOnModal(false)} />
      </Modal>
    </S.Header>
  );
}

export default Header;
