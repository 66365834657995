import React from 'react';
import { Form, Input, Button, Divider, message, notification } from 'antd';
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import api from 'app/client';
import { handleLogin } from 'common/auth0';
import { useLocation } from 'react-router-dom';

import textLogo from 'images/logo.jpg';

import * as S from './styles';

function RegisterForm() {
  const { search, pathname } = useLocation();
  const [form] = Form.useForm();
  const params = new URLSearchParams(search);
  const email = params.get('email');
  const code = params.get('code') || '';

  if (code !== '') {
    form.setFieldsValue({ resellerCode: code });
  }

  const [loading, setLoading] = React.useState(false);
  const [showReferral, setShowReferral] = React.useState(code !== '');
  const [showSuccess, setShowSuccess] = React.useState(false);

  const handleFinish = (values) => {
    // TODO add loading
    setLoading(true);
    console.log('values: ', values);
    api.post('/register', {
      password: values.password,
      name: values.name,
      email: email || values.email,
      resellerCode: values.resellerCode,
    }).then((response) => {
      if (response !== undefined) {
        setShowSuccess(true);
        notification.success({
          message: 'Registration Successful!',
          description: 'Thank you for registering with Image Engine. Please check your inbox to verify your account.',
        });
      }
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      message.error(error.message);
    });
  };

  if (pathname === '/login') {
    handleLogin();
  }

  return (
    <S.RegisterForm>
      <img style={{ display: 'none' }} alt="ie-logo" src={textLogo} />
      {pathname === '/register' && (
        <section>
          {email ?
            <h5>Register an account for <br />
              <span style={{ color: '#5466E0', fontSize: '14px' }}>{email}</span>
            </h5>
            :
            <h5>Register an account</h5>
          }

          <Form onFinish={handleFinish} form={form} validateTrigger="onBlur">
            <strong>Name</strong>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Provide a name.',
                },
              ]}
            >
              <Input prefix={<UserOutlined />} size="large" />
            </Form.Item>
            {!email &&
              <>
                <strong>Email</strong>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Provide a valid email.',
                    },
                  ]}
                >
                  <Input prefix={<MailOutlined />} size="large" />
                </Form.Item>
              </>
            }
            <strong>Password</strong>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Input your password.' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                size="large"
              />
            </Form.Item>
            <strong>Repeat Password</strong>
            <Form.Item
              name="passwordConfirm"
              hasFeedback
              rules={[
                { required: true, message: 'Please input your password.' },
                { min: 8, message: 'Must be 8 characters and above.' },
                ({ getFieldValue }) => ({
                  validator(_rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      'Password did not match.',
                    );
                  },
                }),
              ]}
            >
              <Input.Password prefix={<LockOutlined />} type="password" size="large" />
            </Form.Item>

            {!showReferral &&
              <Button
                onClick={() => setShowReferral(true)}
                block
                style={{ margin: '5px 0 0' }}
              >
                Click here to enter Referral Code
              </Button>
            }

            {showReferral &&
              <>
                <strong>Referral Code</strong>
                <Form.Item
                  name="resellerCode"
                >
                  <Input disabled={code !== ''} defaultValue={code} prefix={<LinkOutlined />} size="large" />
                </Form.Item>
              </>
            }

            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              block
              style={{ margin: '5px 0 0' }}
            >
              Create Account
            </Button>
          </Form>
          <Divider />

          {showSuccess ?
            <p style={{ textAlign: 'center' }}>
              Registration successful! <br />please check your email to verify your account!
            </p>
            :
            <p style={{ textAlign: 'center' }}>
              I have an account. <Button type="link" onClick={handleLogin}>Login</Button>
            </p>
          }
        </section>
      )}
    </S.RegisterForm>
  );
}

export default RegisterForm;
