import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SettingsNav from 'components/SettingsNav';
import AiParameters from 'features/AiParameters';
import DeviceSubscription from 'features/DeviceSubscription';
import SavedPlateNumbers from 'features/SavedPlateNumbers';
import DeviceSharing from 'features/DeviceSharing';
import Account from 'features/Account';
import Reseller from 'features/Reseller';
import PaymentMethods from 'features/PaymentMethods';
import Payments from 'features/Payments';
import Storage from 'features/Storage';
import Notifications from 'features/Notifications';

import * as S from './styles';

function SettingsPage() {
  return (
    <S.SettingsPage>
      <section>
        <nav>
          <SettingsNav />
        </nav>
        <article>
          <Switch>
            <Route path="/app/settings/storage">
              <Storage />
            </Route>
            <Route path="/app/settings/ai-parameters">
              <AiParameters />
            </Route>
            <Route path="/app/settings/notifications">
              <Notifications />
            </Route>
            <Route path="/app/settings/subscription">
              <DeviceSubscription />
            </Route>
            <Route path="/app/settings/plate-numbers">
              <SavedPlateNumbers />
            </Route>
            <Route path="/app/settings/sharing">
              <DeviceSharing />
            </Route>
            <Route path="/app/settings/billing">
              <Payments />
            </Route>
            <Route path="/app/settings/reports">
              DAILY REPORTS
            </Route>
            <Route path="/app/settings/account">
              <Account />
            </Route>
            <Route path="/app/settings/reseller">
              <Reseller />
            </Route>
            <Route path="/app/settings/payment-methods">
              <PaymentMethods />
            </Route>
            <Redirect from="/app/settings" to="/app/settings/account" />
          </Switch>
        </article>
      </section>
    </S.SettingsPage>
  );
}

export default SettingsPage;
