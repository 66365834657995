import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import localStorage from 'localStorage';
import { Row, Input } from 'antd';
import { useUpdateEffect } from 'react-use';
import Logo from 'components/Logo';
import { handleValidateToken } from 'common/auth0';
import AuthenticatedPage from 'pages/AuthenticatedPage';
import AuthPage from 'pages/AuthPage';
import RegisterPage from 'pages/RegisterPage';
import AiImageRecognitionPage from 'pages/AiImageRecognitionPage';
import PricingPage from 'pages/PricingPage';
// import HomePage from 'pages/HomePage';
import ResellerPage from 'pages/ResellerPage';
import LegalPage from 'pages/LegalPage';
import CameraCloudStoragePage from 'pages/CameraCloudStoragePage';
import AboutUsPage from 'pages/AboutUsPage';
import ScrollToTop from 'components/ScrollToTop';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = handleValidateToken();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) return <Component {...props} />;
        return <Redirect to="/" />;
      }}
    />
  );
};

function App() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [password, setPassword] = useState('imageengine2022');

  useUpdateEffect(() => {
    if (password === 'imageengine2022') {
      localStorage.setItem('password', 'imageengine2022');
    }
  }, [password]);

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <ScrollToTop />
        {password.toLowerCase() === 'imageengine2022' ?
          <Switch>
            <Route path="/auth">
              <AuthPage />
            </Route>
            <Route path="/register">
              <RegisterPage />
            </Route>
            <Route path="/login">
              <RegisterPage />
            </Route>
            <Route exact path="/">
              <RegisterPage />
            </Route>
            <Route exact path="/pricing">
              <PricingPage />
            </Route>
            <Route exact path="/reseller">
              <ResellerPage />
            </Route>
            <Route exact path="/ai-image-recognition">
              <AiImageRecognitionPage />
            </Route>
            <Route exact path="/camera-cloud-storage">
              <CameraCloudStoragePage />
            </Route>
            <Route exact path="/about-us">
              <AboutUsPage />
            </Route>
            <Route path="/legal">
              <LegalPage />
            </Route>
            <PrivateRoute path="/app" component={AuthenticatedPage} />
            <Redirect to="/" />
          </Switch>
          :
          <Row justify="center">
            <div style={{ margin: '100px' }}>
              <Logo />
              <br />
              <br />
              <p style={{ textAlign: 'center' }}>please enter password </p>
              <br />
              <Input style={{ textAlign: 'center' }} size="large" onChange={(e) => setPassword(e.target.value)} />
            </div>
          </Row>
        }
      </Router>
    </Elements>
  );
}

export default App;
