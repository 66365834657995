import styled from 'styled-components';

export const DeviceSubscriptionCancel = styled.div`
   .selected-plan{
     .ant-card-head{
       background: #5466E0;
       color: #fff;
     }
     
     .ant-card-body{
       border: 1px solid #5466E0;
     }
   }
  
  .credit-card-box{
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 8px;
  }
  
  .add-new-group{
    color: #5466E0;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 2px;
    z-index: 2;
  }

  .add-new-subscription{
    color: #5466E0;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    width: 100%;
  }
`;
