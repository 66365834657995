import React, { useState } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { Modal, Form, Input, Button, Select, Row, Col, Tooltip, Card, Radio, Space, Tag, Alert } from 'antd';
import { MenuOutlined, PlusOutlined, QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import useDeviceList from 'features/DeviceList/hooks';
import useProductsList from 'features/ProductsList/hooks';
import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import { createAuthenticatedClient } from 'app/client';
import { openSuccessNotification } from 'common/helpers';

import { deviceCustomerSubscriptionCreate, selectPending as subscriptionCreatePending } from 'features/DeviceSubscription/slice';
import { devicesGet } from 'features/DeviceList/slice';
import { filter } from 'lodash';
import useDeviceSubscription from 'features/DeviceSubscription/hooks';
import { selectUserRole } from 'features/Users/slice';
import useAddDevice from './hooks';
import { cardsGet, cardSetDefault, deviceCreate, selectPending, deviceAddOns } from './slice';

import * as S from './styles';

const { Option } = Select;
const { TextArea } = Input;

const REQUIRED = [{ required: true, message: 'This field is required!' }];

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#5466E0',
      color: 'rgba(0, 0, 0, 0.85)',
      fontSize: '14px',
    },
    invalid: {
      iconColor: '#f74242',
      color: '#f74242',
    },
  },
};

function AddDeviceModal() {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();
  const { cards, pendingSetDefaultCard, showModal, isModalActive, resellerCustomer } = useAddDevice();
  const { groupedDevices } = useDeviceList();
  const { getSubscriptions, deviceSubscriptions } = useDeviceSubscription();
  const { deviceProductsByCode, deviceAddOnsByCode } = useProductsList();
  const [showPayment, setShowPayment] = useState(false);
  const [createNewGroup, setCreateNewGroup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [processing, setProcessing] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState({});

  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);

  const [selectedCard, setSelectedCard] = useState(undefined);
  const [isTrial, setIsTrial] = useState(false);
  const pending = resellerCustomer === '' ? useSelector(selectPending) : useSelector(subscriptionCreatePending);

  const platinumPlan = filter(deviceSubscriptions, (item) => item.productId === 'Platinum' && item.status === 'Active');

  const handlePaymentThatRequiresCustomerAction = (result) => {
    // console.log('handlePaymentThatRequiresCustomerAction: ', result);
    const { confirmationId, paymentMethodId } = result;
    if (!confirmationId) {
      // No customer action needed
      return result;
    }

    return stripe
      .confirmCardPayment(confirmationId, {
        payment_method: paymentMethodId,
      })
      .then((cardResult) => {
        if (cardResult.error) {
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          throw cardResult.error;
        } else if (cardResult.paymentIntent.status === 'succeeded') {
          // There's a risk of the customer closing the window before callback
          // execution. To handle this case, set up a webhook endpoint and
          // listen to invoice.paid. This webhook endpoint returns an Invoice.
          return result;
        }

        throw new Error('Unhandled Customer Action');
      });
  };

  const handleClose = () => {
    form.resetFields();
    setShowPayment(false);
    setIsTrial(false);
    setSelectedProduct('');
    showModal(false);
  };

  const handleAddDeviceByCustomer = (productId) => {
    form.validateFields()
      .then(async (values) => {
        const payload = {
          productId: productId ?? deviceProductsByCode[selectedProduct]?.id,
          device: values,
        };
        const response = resellerCustomer !== '' ?
          await dispatch(deviceCustomerSubscriptionCreate({ customerId: resellerCustomer, payload })) :
          await dispatch(deviceCreate(payload));

        console.log('response:', response);
        if (response.payload) {
          console.log('response.payload: ', response.payload);

          handleClose();
        }
      });
  };

  const handleDeviceCreate = async (paymentMethodId, devicePayload = deviceDetails, plan = deviceProductsByCode[selectedProduct]?.id, cardId) => {
    if (platinumPlan.length > 0) {
      const payload = {
        subscriptionId: platinumPlan[0].id,
        data: {
          newPaymentMethodId: paymentMethodId,
          cardId,
          device: devicePayload,
        },
      };
      setProcessing(true);
      const result = await dispatch(deviceAddOns(payload));

      if (result.payload) {
        const { invoice, followUpId, confirmationId } = result.payload;

        if (invoice) {
          openSuccessNotification({
            message: 'New Camera Added!',
            description: 'Items are now added to your subscription!',
          });

          form.resetFields();
          setProcessing(false);
          setSelectedCard(undefined);
        } else if (confirmationId) {
          await handlePaymentThatRequiresCustomerAction({ confirmationId, paymentMethodId });

          const api = createAuthenticatedClient();
          await api.post(`subscription/${followUpId}/follow-ups`);

          openSuccessNotification({
            message: 'New Device Added!',
            description: 'FTP credentials for the newly added device was sent to your email!',
          });
        } else {
          throw new Error('Unhandled subscription addons error');
        }
      } else {
        setProcessing(false);
        console.log(result);
      }
    } else {
      const payload = {
        productId: isTrial ? 'Bronze' : plan,
        newPaymentMethodId: paymentMethodId,
        cardId,
        device: devicePayload,
        addons: [],
        // isTrial,
      };
      const result = resellerCustomer !== '' ?
        await dispatch(deviceCustomerSubscriptionCreate({ customerId: resellerCustomer, payload })) :
        await dispatch(deviceCreate(payload));

      if (result.payload) {
        const { subscription: newSubscription, followUpId, confirmationId } = result.payload;

        if (newSubscription) {
          openSuccessNotification({
            message: 'New Device Added!',
            description: 'FTP credentials for the newly added device was sent to your email!',
          });

          handleClose();
        } else if (confirmationId) {
          await handlePaymentThatRequiresCustomerAction({ confirmationId, paymentMethodId });

          const api = createAuthenticatedClient();
          await api.post(`subscription/${followUpId}/follow-ups`);
          dispatch(devicesGet());

          openSuccessNotification({
            message: 'New Device Added!',
            description: 'FTP credentials for the newly added device was sent to your email!',
          });
        } else {
          throw new Error('Unhandled device creation error');
        }

        handleClose();
      }
    }
  };

  const handleProcessPayment = async () => {
    if (selectedCard === 'new') {
      form.validateFields().then(async (cardDetails) => {
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }

        if (error) {
          elements.getElement('card').focus();
          return;
        }

        if (cardComplete) {
          setProcessing(true);
        }

        const payload = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            name: cardDetails.nameOnCard,
            phone: cardDetails.phone,
            email: cardDetails.email,
          },
        });

        setProcessing(false);

        if (payload.error) {
          setError(payload.error);
        } else {
          const { paymentMethod } = payload;
          const paymentMethodId = paymentMethod.id;

          await handleDeviceCreate(paymentMethodId);
        }
      });
    } else {
      await handleDeviceCreate(undefined, undefined, undefined, selectedCard);
    }
  };

  const handleFirstStep = () => {
    form.validateFields()
      .then((values) => {
        setDeviceDetails(values);
        setShowPayment(true);
      });
  };

  if (groupedDevices.length !== 0) {
    delete deviceProductsByCode.FREE;
  }

  const handleSetDefaultCard = (e, cardId) => {
    e.preventDefault();
    dispatch(cardSetDefault({ cardId }));
  };

  useEffectOnce(() => {
    dispatch(cardsGet());
    getSubscriptions();
  });

  useUpdateEffect(() => {
    if (cards.length > 0 && selectedCard === undefined) {
      setSelectedCard(cards[0].id);
    } else if (cards.length === 0 && selectedCard === undefined) {
      setSelectedCard('new');
    }
  }, [cards]);

  console.log('userRole:', userRole);

  return (
    <Modal
      title="Add Device"
      visible={isModalActive}
      onCancel={handleClose}
      footer={null}
      closable
      width={600}
      destroyOnClose
    >
      <S.DeviceAddModal>
        <Form form={form} layout="vertical">
          {showPayment ?
            <>
              {platinumPlan.length > 0 ? (
                <Row gutter={20}>

                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Card title="New Device Camera">
                      {deviceAddOnsByCode.Camera?.price}&nbsp;
                      {deviceAddOnsByCode.Camera?.currency}
                    </Card>
                  </Col>

                </Row>
              ) : (
                <Row gutter={20}>
                  {isTrial ?
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Card title="7 Day Free Trial">
                        Bronze Plan<br />
                        Card will be charged after trial period
                      </Card>
                    </Col>
                    :
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Card title={deviceProductsByCode[selectedProduct]?.code}>
                        {deviceProductsByCode[selectedProduct]?.name}<br />
                        {deviceProductsByCode[selectedProduct]?.price}&nbsp;
                        {deviceProductsByCode[selectedProduct]?.currency}
                      </Card>
                    </Col>
                  }
                </Row>
              )}

              <Row gutter={20} style={{ margin: '10px 0' }}>
                <Col span={24}>
                  Select Payment Method {pendingSetDefaultCard && <LoadingOutlined />}
                  <Radio.Group onChange={(e) => setSelectedCard(e.target.value)} value={selectedCard}>
                    <Space direction="vertical" className="cards-list">
                      {cards.map((card) =>
                        <Radio value={card.id} key={card.id} style={{ margin: '4px', display: 'flex', alignItems: 'center' }}>
                          {`${card.brand.toUpperCase()} card ending with ${card.last4} and expires on ${card.expMonth}/${card.expYear}`}
                          <br />
                          {card.isDefault ?
                            <Tag
                              style={{ cursor: 'default' }}
                              color="blue"
                            >Default
                            </Tag>
                            :
                            <Tag
                              onClick={(e) => handleSetDefaultCard(e, card.id)}
                              color="#e2e2e2"
                            >Set as Default
                            </Tag>
                          }
                        </Radio>)}
                      <Radio value="new" style={{ margin: '4px', display: 'flex', alignItems: 'center' }}>
                        New Card
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
              {selectedCard === 'new' &&
                <Row>
                  <Col span={24}>
                    <Form.Item label="Name on Card" name="nameOnCard" rules={REQUIRED}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Phone" name="phone" rules={REQUIRED}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={REQUIRED}>
                      <Input type="email" />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="credit-card-box">
                    <CardElement
                      options={CARD_OPTIONS}
                      onChange={(e) => {
                        setError(e.error);
                        setCardComplete(e.complete);
                      }}
                    />
                  </Col>
                </Row>
              }
              <Row gutter={20} style={{ marginTop: '10px' }}>
                <Col span={12}>
                  <Button type="default" onClick={() => setShowPayment(false)} block>
                    Back
                  </Button>
                </Col>
                <Col span={12}>
                  <Button onClick={handleProcessPayment} loading={processing || pending} type="primary" htmlType="submit" block>
                    Create Device
                  </Button>
                </Col>
              </Row>
            </>
            :
            <>
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item label="Name" name="name" rules={REQUIRED}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Description" name="description">
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                {
                  (createNewGroup || groupedDevices.length === 0) ?
                    <Col span={24}>
                      {
                        groupedDevices.length !== 0 &&
                        <>
                          {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
                          <span role="button" className="add-new-group" onClick={() => setCreateNewGroup(false)}>
                            Click here to select from existing groups
                          </span>
                        </>
                      }
                      <Form.Item label="New Group" name="newDeviceGroupName" rules={REQUIRED}>
                        {groupedDevices.length === 0 ?
                          <Input /> :
                          <Input
                            suffix={
                              <Tooltip title="Use existing Device Group">
                                <MenuOutlined
                                  onClick={() => setCreateNewGroup(false)}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Tooltip>
                            }
                          />
                        }
                      </Form.Item>
                    </Col>
                    :
                    <Col span={24}>
                      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
                      <span role="button" className="add-new-group" onClick={() => setCreateNewGroup(true)}>
                        Click here to create new group
                      </span>
                      <Form.Item label="Select from existing groups" name="deviceGroupId" rules={REQUIRED}>
                        <Select
                          suffixIcon={
                            <Tooltip title="Create New Device Group">
                              <PlusOutlined
                                onClick={() => setCreateNewGroup(true)}
                                style={{ cursor: 'pointer', color: '#333' }}
                              />
                            </Tooltip>
                          }
                        >
                          {groupedDevices.map((item) =>
                            <Option value={item.id} key={item.id}>{item.name}</Option>)}
                        </Select>

                      </Form.Item>
                    </Col>
                }
              </Row>
              {platinumPlan.length > 0 ? (
                <Row>
                  <Col span={24}>
                    <Alert
                      message={
                        <p style={{ textAlign: 'center' }}>
                          {platinumPlan[0].maxDevice > platinumPlan[0].currentDevice ?
                            'You have a Platinum Plan, new device will be added on your existing Platinum Plan.'
                            :
                            'You have already used all the camera slot on your Platinum Plan, you will be charged for the extra camera as add-on if you proceed.'
                          }
                        </p>
                      }
                      type="info"
                    />
                  </Col>
                </Row>
              ) : (
                <Row gutter={20}>
                  <Col span={24}>
                    <Form.Item label="Plan" name="plan" rules={REQUIRED}>
                      {Object.keys(deviceProductsByCode).length &&
                        <Select
                          defaultActiveFirstOption={false}
                          onChange={(product) => setSelectedProduct(product)}
                          suffixIcon={
                            <Tooltip title="See Pricing Details">
                              <a target="_blank" href="/pricing">
                                <QuestionCircleOutlined
                                  style={{ cursor: 'pointer', color: '#333' }}
                                />
                              </a>
                            </Tooltip>
                          }
                        >
                          {Object.keys(deviceProductsByCode).map((key) =>
                            <Option
                              value={deviceProductsByCode[key].code}
                              key={deviceProductsByCode[key].id}

                            >{`${deviceProductsByCode[key].price} ${deviceProductsByCode[key].currency} - ${deviceProductsByCode[key].name}`}
                            </Option>)}
                        </Select>}
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gutter={20} style={{ marginTop: '10px' }}>
                <Col span={6}>
                  <Button disabled={pending} type="default" onClick={handleClose} block>
                    Cancel
                  </Button>
                </Col>
                {userRole.includes('Customer') ? (
                  <Col span={18}>
                    {platinumPlan.length === 0 ? (
                      <Button
                        disabled={!deviceProductsByCode[selectedProduct]}
                        onClick={handleAddDeviceByCustomer}
                        loading={pending}
                        type="primary"
                        block
                      >
                        {deviceProductsByCode[selectedProduct] ?
                          'Add Selected Plan'
                          :
                          'Please Select a Plan'
                        }
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleAddDeviceByCustomer('Platinum')}
                        loading={pending}
                        type="primary"
                        block
                      >
                        Proceed with new device under Platinum Plan
                      </Button>
                    )}
                  </Col>
                ) : (
                  <Col span={18}>
                    {platinumPlan.length === 0 ? (
                      <Button
                        disabled={!deviceProductsByCode[selectedProduct]}
                        onClick={handleFirstStep}
                        loading={pending}
                        type="primary"
                        block
                      >
                        {deviceProductsByCode[selectedProduct] ?
                          'Proceed to payment details'
                          :
                          'Please Select a Plan'
                        }
                      </Button>
                    ) : (
                      <Button
                        onClick={handleFirstStep}
                        loading={pending}
                        type="primary"
                        block
                      >
                        Proceed with new device under Platinum Plan
                      </Button>
                    )}
                  </Col>
                )}

              </Row>
            </>
          }
        </Form>
      </S.DeviceAddModal>
    </Modal>
  );
}

export default AddDeviceModal;
