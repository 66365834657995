import styled from 'styled-components';

export const StyledDeviceDetails = styled.div`
  padding: 30px;
  width: 100%;

  .add-new-group{
    color: #5466E0;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 2px;
    z-index: 2;
  }
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  aside {
    flex: 0 0 200px;
  }
  
  h4{
    text-transform: capitalize;
  }
`;
