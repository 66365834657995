import React from 'react';
import { notification, Tag } from 'antd';
import colors from 'styles/colors';

export const sortByName = (a, b) => {
  let result = 0;
  if (a.name < b.name) result = -1;
  else if (a.name > b.name) result = 1;

  return result;
};

export const openSuccessNotification = (config) => {
  notification.success(config);
};

export const openErrorNotification = (errorMessage) => {
  notification.error({
    message: 'Error',
    description: errorMessage.toString(),
    duration: 10,
    placement: 'bottomLeft',
  });
};

export const bytesToSize = (bytes, index = 0) => {
  const sizes = ['KB', 'MB', 'GB', 'TB'];
  if (bytes < 1_000) return `${(bytes).toFixed(1)} ${sizes[index]}`;
  return bytesToSize(bytes / 1_000, index + 1);
};

export const mbToSize = (mb) => {
  return bytesToSize(mb, 1);
};

export const renderPlanTag = (plan) => {
  let color;
  let name;

  switch (plan) {
    case 'gold':
      color = colors.gold;
      name = 'Gold';
      break;
    case 'silver':
      color = colors.silver;
      name = 'Silver';
      break;
    case 'bronze':
      color = colors.bronze;
      name = 'Bronze';
      break;
    case 'copper':
      color = colors.copper;
      name = 'Copper';
      break;
    default:
      break;
  }
  return <Tag color={color}>{name}</Tag>;
};

export const handleAPIError = (err) => {
  notification.error({
    message: 'API Error',
    description: err.toString(),
    duration: 10,
    placement: 'bottomLeft',
  });
};
