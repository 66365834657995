import React, { useState } from 'react';
import Logo from 'components/Logo';
import { Button, Row, Col, Space, Drawer } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { handleLogin, handleLogout, handleGetSession } from 'common/auth0';
import nav from 'images/nav.svg';
import Box from 'components/Box';

import * as S from './styles';

function GuestNav() {
  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();

  const currentUser = handleGetSession();

  const showDrawer = () => setVisible(true);

  const onClose = () => setVisible(false);

  return (
    <S.GuestNav>
      <Box maxWidth={1400} width="100%" m="0 auto" px={4}>
        <Row justify="space-between" align="middle">
          <Col>
            <a href="https://imageengine.ai">
              <Logo />
            </a>
          </Col>
          <Col>
            <Box display={{ _: 'none', lg: 'block' }}>
              <Space size={20} style={{ marginRight: '20px' }}>
                {/* <Text as={NavLink} to="/ai-image-recognition" activeClassName="active" color="black" fontWeight={500}> */}
                {/*  AI Image Recognition */}
                {/* </Text> */}
                {/* <Text as={NavLink} to="/camera-cloud-storage" activeClassName="active" color="black" fontWeight={500}> */}
                {/*  Camera Cloud Storage */}
                {/* </Text> */}
                {/* <Text as={NavLink} to="/reseller" activeClassName="active" color="black" fontWeight={500}> */}
                {/*  Reseller */}
                {/* </Text> */}
                {/* <Text as={NavLink} to="/pricing" activeClassName="active" color="black" fontWeight={500}> */}
                {/*  Pricing */}
                {/* </Text> */}
                {/* <Text as={NavLink} to="/about-us" activeClassName="active" color="black" fontWeight={500}> */}
                {/*  About Us */}
                {/* </Text> */}
                {/* <Text as={NavLink} to="/about-us#contact" activeClassName="active" color="black" fontWeight={500}> */}
                {/*  Contact */}
                {/* </Text> */}
              </Space>
              {currentUser ? (
                <Space>
                  <Link to="/app/devices">
                    <Button type="primary" ghost>
                      Go to My Devices
                    </Button>
                  </Link>
                  <Button type="primary" onClick={handleLogout}>
                    Log Out
                  </Button>
                </Space>
              ) : (
                <Space>
                  { pathname === '/register' && (
                    <Button type="primary" onClick={handleLogin}>
                      Sign In
                    </Button>
                  )}
                </Space>
              )}
            </Box>
            <Box display={{ _: 'block', lg: 'none' }} onClick={showDrawer}>
              <img src={nav} alt="nav" />
            </Box>
          </Col>
        </Row>
      </Box>
      <Drawer
        title="MENU"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        footer={
          <Box width="100%" display="flex" flexDirection="column">
            {pathname === '/register' ? (
              <Link to="/register">
                <Button type="primary" ghost block style={{ marginBottom: '10px' }}>Register</Button>
              </Link>
            ) : (
              <Link to="/login">
                <Button type="primary" block style={{ marginBottom: '10px' }} onClick={handleLogin}>Sign In</Button>
              </Link>
            )}

          </Box>
        }
      >
        {/* <Box width="100%" display="flex" flexDirection="column"> */}
        {/*  <Button */}
        {/*    block */}
        {/*    style={{ marginBottom: '10px' }} */}
        {/*    onClick={() => history.push('/ai-image-recognition')} */}
        {/*  > */}
        {/*    AI Image Recognition */}
        {/*  </Button> */}
        {/*  <Button */}
        {/*    block */}
        {/*    style={{ marginBottom: '10px' }} */}
        {/*    onClick={() => history.push('/camera-cloud-storage')} */}
        {/*  > */}
        {/*    Camera Cloud Storage */}
        {/*  </Button> */}
        {/*  <Button */}
        {/*    block */}
        {/*    style={{ marginBottom: '10px' }} */}
        {/*    onClick={() => history.push('/reseller')} */}
        {/*  > */}
        {/*    Reseller */}
        {/*  </Button> */}
        {/*  <Button */}
        {/*    block */}
        {/*    style={{ marginBottom: '10px' }} */}
        {/*    onClick={() => history.push('/pricing')} */}
        {/*  > */}
        {/*    Pricing */}
        {/*  </Button> */}
        {/*  <Button */}
        {/*    block */}
        {/*    style={{ marginBottom: '10px' }} */}
        {/*    onClick={() => history.push('/about-us')} */}
        {/*  > */}
        {/*    About Us */}
        {/*  </Button> */}
        {/*  <Button */}
        {/*    block */}
        {/*    style={{ marginBottom: '10px' }} */}
        {/*    onClick={() => history.push('/about-us#contact')} */}
        {/*  > */}
        {/*    Contact */}
        {/*  </Button> */}
        {/* </Box> */}
      </Drawer>
    </S.GuestNav>
  );
}

export default GuestNav;
