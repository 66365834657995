import styled from 'styled-components';
import colors from 'styles/colors';

export const SettingsPage = styled.div`
  padding: 20px;
  min-height: calc(100vh - 56px);
  background: ${colors.gray_1};
  @media (max-width: 600px) {
    padding: 0;
  }

  > section {
    background: ${colors.white};
    height: 100%;
    display: flex;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
    
    > nav {
      border-right: 1px solid ${colors.gray};
      flex: 0 0 284px;
      @media (max-width: 1200px) {
        flex: 1;
        border-right: none;
      }
    }
    > article {
      width: 100%;
    }
  }
`;
