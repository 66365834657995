import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';

function Content1() {
  return (
    <Box p={{ _: '40px 20px', md: '80px 40px' }}>
      <Text as="h2" fontSize={{ _: 21, md: 31 }} fontWeight={600} mb={6} textAlign="center">How does AI Image Recognition Work?</Text>
      <Text maxWidth={1290} m="auto" width="100%">AI is the simulation of human intelligence processes by computers.  AI can perform several human like functions.  Machine vision is one AI function that enables a computer to “see” images by breaking them down into recognisable objects.  This is called image recognition.
        <br /><br />
        AI has a high accuracy rate which is why CCTV cameras with built in technology are appealing as they reduce the number of false alarms.  However, CCTV cameras with integrated AI have lomited processing power and cannot detect anything other than vehicles or humans.
        <br /><br />
        Imagine Engine uses AI to recognise objects from images created by regular CCTV cameras if they support FTP. This eliminates the need to invest in additional hardware or AI equipped CCTV cameras.
        <br /><br />
        We utilise a custom built secure cloud storage platform to analyse your images which far exceeds the limited processing capability of cameras with integrated AI. When a “trigger” object is recognised Imagine Engine sends alerts via a mobile application along with the captured image.
      </Text>
    </Box>
  );
}

export default Content1;
