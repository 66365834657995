import React from 'react';
import {
  RobotOutlined,
  UserOutlined,
  CalendarOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';

export const NAV_ITEMS = [
  {
    name: 'Account Settings',
    route: '/app/settings/account',
    icon: <UserOutlined style={{ fontSize: '21px' }} />,
  },
  // TODO
  // {
  //   name: 'Storage',
  //   route: '/app/settings/storage',
  //   icon: <DatabaseOutlined style={{ fontSize: '21px' }} />,
  // },
  {
    name: 'Device AI Parameters',
    route: '/app/settings/ai-parameters',
    icon: <RobotOutlined style={{ fontSize: '21px' }} />,
  },
  {
    name: 'Subscriptions',
    route: '/app/settings/subscription',
    icon: <CalendarOutlined style={{ fontSize: '21px' }} />,
  },
  {
    name: 'Saved Plate Numbers',
    route: '/app/settings/plate-numbers',
    icon: <DatabaseOutlined style={{ fontSize: '21px' }} />,
  },
  // TODO
  // {
  //   name: 'Device Sharing',
  //   route: '/app/settings/sharing',
  //   icon: <UserSwitchOutlined style={{ fontSize: '21px' }} />,
  // },
  // TODO
  // {
  //   name: 'Notifications',
  //   route: '/app/settings/notifications',
  //   icon: <BellOutlined style={{ fontSize: '21px' }} />,
  // },
];
