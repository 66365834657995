import React from 'react';

import { useUpdateEffect } from 'react-use';
import { Tabs, Space, Select, Row, Col, Typography } from 'antd';
import { RobotOutlined } from '@ant-design/icons';
import useDeviceList from 'features/DeviceList/hooks';
import Box from 'components/Box';
import Manage from './components/Manage';
import Invite from './components/Invite';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

function DeviceSharing(props) {
  const { deviceId } = props;
  const { allDevices } = useDeviceList();
  const [selectedDeviceId, setSelectedDeviceId] = React.useState(deviceId);

  useUpdateEffect(() => {
    if (allDevices.length > 0) {
      setSelectedDeviceId(deviceId || allDevices[0].id);
    }
  }, [allDevices, deviceId]);

  return (
    <Box p={{ _: 20, sm: 30 }}>
      <Space style={{ margin: '0 0 20px 0' }}>
        <RobotOutlined style={{ fontSize: '21px' }} />
        <Title level={4} style={{ margin: '0' }}>
          Device Sharing
        </Title>
      </Space>
      <Row gutter={15}>
        <Col span={24}>
          {(!deviceId && allDevices.length > 0) &&
            <Select
              onChange={(val) => setSelectedDeviceId(val)}
              defaultValue={allDevices[0]?.id}
              style={{ width: '100%', marginBottom: '20px' }}
            >
              {
                allDevices.map((device) => {
                  if (device.deviceUserIsOwner === 1 || device.deviceUserAllowManageUser === 1) {
                    return (
                      <Option value={device.id} key={device.id}>
                        {`${device.name} [${device.deviceGroupName}]`}
                      </Option>
                    );
                  }
                  return null;
                })
              }
            </Select>}
        </Col>
      </Row>
      <Col span={24}>
        <Tabs centered>
          <TabPane tab="Invite" key="invite">
            <Invite deviceId={selectedDeviceId} />
          </TabPane>
          <TabPane tab="Manage" key="manage">
            <Manage deviceId={selectedDeviceId} />
          </TabPane>
        </Tabs>
      </Col>
      <Row />
    </Box>
  );
}

export default DeviceSharing;
