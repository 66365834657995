import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { openErrorNotification } from 'common/helpers';
import { devicesGet } from 'features/DeviceList/slice';

export const deviceCreate = createAsyncThunk(
  'device/create',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const newDevice = await api.post('/subscription/subscriptions', payload);
      if (newDevice?.device) {
        dispatch(devicesGet());
      }
      return newDevice;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceAddOns = createAsyncThunk(
  'device/addOns',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedClient();
    const { subscriptionId, data } = payload;
    try {
      const newAddon = await api.post(`/subscription/subscriptions/${subscriptionId}/addons`, data);

      dispatch(devicesGet());

      return newAddon;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const cardsGet = createAsyncThunk(
  'cards/get',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const response = await api.get('/billing/cards');
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const cardSetDefault = createAsyncThunk(
  'cards/setDefault',
  async (
    { cardId },
    { extra: { createAuthenticatedClient }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const response = await api.post(`/billing/cards/${cardId}/default`, {});
      dispatch(cardsGet());
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  products: [],
  cards: [],
  pending: false,
  pendingSetDefaultCard: false,
  error: null,
  resellerCustomer: '',
  isModalActive: false,
};

export const addDeviceSlice = createSlice({
  name: 'deviceAdd',
  initialState,
  reducers: {
    setModalActive: (state, action) => {
      state.isModalActive = action.payload;
    },
    setResellerCustomer: (state, action) => {
      state.resellerCustomer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deviceCreate.fulfilled, (state) => {
        state.pending = false;
        state.isModalActive = false;
      })
      .addCase(deviceCreate.pending, (state) => {
        state.pending = true;
      })
      .addCase(deviceCreate.rejected, (state) => {
        openErrorNotification('Failed to add device.');
        state.pending = false;
      })
      .addCase(cardSetDefault.fulfilled, (state) => {
        state.pendingSetDefaultCard = false;
      })
      .addCase(cardSetDefault.pending, (state) => {
        state.pendingSetDefaultCard = true;
      })
      .addCase(cardSetDefault.rejected, (state) => {
        state.pendingSetDefaultCard = false;
      })
      .addCase(cardsGet.fulfilled, (state, { payload }) => {
        state.cards = payload;
      });
  },
});

export const { setModalActive, setResellerCustomer } = addDeviceSlice.actions;

export const selectPending = (state) => state.deviceAdd.pending;
export const selectPendingSetDefaultCard = (state) => state.deviceAdd.pendingSetDefaultCard;
export const selectCards = (state) => state.deviceAdd.cards;
export const selectModalActive = (state) => state.deviceAdd.isModalActive;
export const selectResellerCustomer = (state) => state.deviceAdd.resellerCustomer;

export default addDeviceSlice.reducer;
