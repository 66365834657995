import React from 'react';
import { Space } from 'antd';
import {
  SearchOutlined,
} from '@ant-design/icons';
import Box from 'components/Box';
import Text from 'components/Text';

function Filter() {
  return (
    <Box
      width={{ _: '100%', md: 'calc(100% - 228px)' }}
      height={63}
      borderBottom="1px solid"
      borderColor="gray"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={25}
      backgroundColor="white"
      left={{ _: 0, md: 230 }}
      zIndex={10}
      position="fixed"
    >
      <Space>
        <Text fontSize={18} fontWeight={600}>
          Invoice
        </Text>
      </Space>
      {/* <Box display={{ _: 'none', sm: 'block' }}> */}
      {/*  <Space> */}
      {/*    <Input.Search placeholder="Search..." onSearch={onSearch} /> */}
      {/*  </Space> */}
      {/* </Box> */}
      <Box display={{ _: 'block', sm: 'none' }}>
        <SearchOutlined style={{ fontSize: '21px' }} onClick={() => { }} />
      </Box>
    </Box>
  );
}

export default Filter;
