import React, { useState } from 'react';
import moment from 'moment/moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import Box from 'components/Box';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { DatePicker, Select } from 'antd';
import { useGetGroupedReports } from './hooks';

const { RangePicker } = DatePicker;
const { Option } = Select;

function LineChartSection() {
  const [getReportsByGroup, { data, loading }] = useGetGroupedReports();

  const [chartData, setChartData] = useState([]);
  const [groupBy, setGroupBy] = useState('month');
  const [dateRange, setDateRange] = useState([moment().startOf('year'), moment().endOf('year')]);

  const onChange = (dates, dateStrings) => {
    if (dates) {
      setDateRange([
        moment(dateStrings[0]),
        moment(dateStrings[1]),
      ]);
    }
  };

  useEffectOnce(() => {
    getReportsByGroup({
      from: moment().startOf('year').toISOString(),
      to: moment().endOf('year').toISOString(),
      group: groupBy,
    });
  });

  useUpdateEffect(() => {
    getReportsByGroup({
      from: dateRange[0].toISOString(),
      to: dateRange[1].toISOString(),
      group: groupBy,
    });
  }, [groupBy, dateRange]);

  useUpdateEffect(() => {
    const newChartData = [];

    console.log('Data: ', data);

    if (groupBy === 'day') {
      data.forEach((item) => {
        newChartData.push({
          name: moment(`${item.day}/${item.month}/${item.year}`, 'DD/MM/YYYY').format('DD/MM/YYYY'),
          detectionCount: item.detectionCount,
          imageCount: item.imageCount,
        });
      });
    } else if (groupBy === 'month') {
      data.forEach((item) => {
        newChartData.push({
          name: moment(item.month, 'MM').format('MMMM'),
          detectionCount: item.detectionCount,
          imageCount: item.imageCount,
        });
      });
    } else if (groupBy === 'year') {
      data.forEach((item) => {
        newChartData.push({
          name: item.year,
          detectionCount: item.detectionCount,
          imageCount: item.imageCount,
        });
      });
    }

    setChartData(newChartData);
  }, [data, groupBy]);

  return (
    <Box
      width="100%"
      border="1px solid"
      borderColor="gray_4"
      p={{ _: '20px 10px', md: 5 }}
      backgroundColor="white"
      borderRadius={4}
      height="100%"
    >
      <Box display="flex" alignItems="left" justifyContent="start" flexDirection={{ _: 'column', md: 'row' }}>
        <RangePicker
          value={dateRange}
          defaultValue={[moment().startOf('year'),
            moment().endOf('year')]}
          ranges={{
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
          }}
          onChange={onChange}
        />
        <Select value={groupBy} style={{ width: '150px', marginLeft: '10px' }} onChange={(group) => setGroupBy(group)}>
          <Option value="day">Day</Option>
          <Option value="month">Month</Option>
          <Option value="year">Year</Option>
        </Select>
      </Box>
      <Box width="100%" height={400} mt={30} ml={{ _: -20, md: 0 }}>
        {!loading && (
          <ResponsiveContainer width="100%" height="100%">

            <LineChart
              data={chartData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" name="Detection Count" dataKey="detectionCount" stroke="#3B6EF1" />
              <Line type="monotone" name="Image Count" dataKey="imageCount" stroke="#8E64E7" />
            </LineChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Box>
  );
}

export default LineChartSection;
