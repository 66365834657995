import React from 'react';
import StripeHooks from './components/stripe-hooks';
import * as S from './styles';

function Account() {
  return (
    <S.Payments>
      {/* can only use one implementation at a time */}
      <StripeHooks />
      {/* <StripeCardElement /> */}
    </S.Payments>
  );
}

export default Account;
