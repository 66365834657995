import React from 'react';
import { Button } from 'antd';
import Box from 'components/Box';
import bgHeader from 'images/ai-image-recognition/header-bg.jpg';
import Text from 'components/Text';
import { useHistory } from 'react-router-dom';

function Header() {
  const history = useHistory();

  return (
    <Box
      width="100%"
      background={`#f9f9fc url(${bgHeader}) bottom left/cover no-repeat`}
      p={{ _: '40px 20px', lg: '80px 40px' }}
    >
      <Box maxWidth={1320} m="auto">
        <Box maxWidth={600} width="100%">
          <Text as="h1" fontSize={{ _: 30, md: 40 }} lineHeight={1.2} fontWeight={600}>
            Using AI image recognition to monitor security camera images
          </Text>
          <Text fontSize={{ _: 16, md: 18 }} lineHeight={1.4} my={4}>
            Artificial Intelligence (AI) Image Recognition enables you to
            take your security surveillance to the next level.
            <br />
            <br />
            Trained computers become the 24/7 eyes on your cameras
            so that you can respond immediately.
          </Text>
          <Box display={{ _: 'none', sm: 'block' }}>
            <Button type="primary" size="large" onClick={() => history.push('/register')}>
              Try AI Image Recognition
              FREE for 7 days
            </Button>
          </Box>
          <Box display={{ _: 'block', sm: 'none' }}>
            <Button type="primary" size="large" onClick={() => history.push('/register')}>
              Try FREE for 7 days
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
