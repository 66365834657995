import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { openErrorNotification, openSuccessNotification } from 'common/helpers';

export const deviceUsersGet = createAsyncThunk(
  'device/users/get',
  async (
    deviceId,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const { data } = await api.get(`/device/${deviceId}/sharings`);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const devicePendingInviteGet = createAsyncThunk(
  'device/pendingInvite/get',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const { data } = await api.get(`/device/devices/${payload}/invites`);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const devicePendingInviteDelete = createAsyncThunk(
  'device/pendingInvite/delete',
  async (
    { deviceId, inviteId },
    { extra: { createAuthenticatedClient }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const data = await api.delete(`/device/devices/${deviceId}/invites/${inviteId}`);
      dispatch(devicePendingInviteGet(deviceId));
      openSuccessNotification({
        message: 'Device Invitation Canceled!',
        description: 'Invitation for selected email was cancelled',
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceUserGetPermission = createAsyncThunk(
  'device/user/getPermission',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const { deviceId, deviceUserId } = payload;
      const data = await api.get(`/device/devices/${deviceId}/users/${deviceUserId}`);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceUserUpdatePermission = createAsyncThunk(
  'device/user/updatePermission',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const { deviceId, deviceUserId, values } = payload;
      const data = await api.patch(`/device/devices/${deviceId}/users/${deviceUserId}`, values);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceUsersInvite = createAsyncThunk(
  'device/users/invite',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const { deviceId, email } = payload;
      const request = await api.post(`/device/${deviceId}/sharings`, { email });
      // TODO: Revert when pending invite feature is re-enabled
      // dispatch(devicePendingInviteGet(deviceId));
      return request;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceUserRemove = createAsyncThunk(
  'device/user/remove',
  async (
    payload,
    { extra: { createAuthenticatedClient }, rejectWithValue },
  ) => {
    const api = createAuthenticatedClient();
    try {
      const { deviceId, deviceUserId } = payload;
      const request = await api.delete(`/device/${deviceId}/sharings/${deviceUserId}`);

      return request;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  deviceUsers: [],
  deviceUserPendingInvites: [],
  deviceUserPermission: {},
  selectedTab: 'invite',
  pendingGetDeviceUsers: false,
  pendingGetDeviceUserPendingInvites: false,
  pendingDeleteDeviceUserPendingInvites: false,
  pendingGetDeviceUserPermission: false,
  pendingUpdateDeviceUserPermission: false,
  pendingDeviceInvite: false,
  pendingRemoveDeviceUser: false,
  error: null,
};

export const slice = createSlice({
  name: 'deviceUsers',
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deviceUsersGet.fulfilled, (state, { payload }) => {
        state.pendingGetDeviceUsers = false;
        state.deviceUsers = payload;
      })
      .addCase(deviceUsersGet.pending, (state) => {
        state.pendingGetDeviceUsers = true;
      })
      .addCase(deviceUsersGet.rejected, (state) => {
        openErrorNotification('Failed to get Device Users.');
        state.pendingGetDeviceUsers = false;
      })

      .addCase(devicePendingInviteGet.fulfilled, (state, { payload }) => {
        state.pendingGetDeviceUserPendingInvites = false;
        state.deviceUserPendingInvites = payload;
      })
      .addCase(devicePendingInviteGet.pending, (state) => {
        state.pendingGetDeviceUserPendingInvites = true;
      })
      .addCase(devicePendingInviteGet.rejected, (state) => {
        openErrorNotification('Failed to get Device User Invites.');
        state.pendingGetDeviceUserPendingInvites = false;
      })

      .addCase(devicePendingInviteDelete.fulfilled, (state) => {
        state.pendingDeleteDeviceUserPendingInvites = false;
      })
      .addCase(devicePendingInviteDelete.pending, (state) => {
        state.pendingDeleteDeviceUserPendingInvites = true;
      })
      .addCase(devicePendingInviteDelete.rejected, (state) => {
        openErrorNotification('Failed to delete Device User Invites.');
        state.pendingDeleteDeviceUserPendingInvites = false;
      })

      .addCase(deviceUserGetPermission.fulfilled, (state, { payload }) => {
        state.pendingGetDeviceUserPermission = false;
        state.deviceUserPermission = payload;
      })
      .addCase(deviceUserGetPermission.pending, (state) => {
        state.pendingGetDeviceUserPermission = true;
      })
      .addCase(deviceUserGetPermission.rejected, (state) => {
        openErrorNotification('Failed to get User Device Permissions.');
        state.pendingGetDeviceUserPermission = false;
      })

      .addCase(deviceUserRemove.fulfilled, (state, { payload }) => {
        state.pendingRemoveDeviceUser = false;
        if (payload) {
          openSuccessNotification({
            message: 'User Removed from Device',
            description: 'User will no longer have access to this device',
          });
        }
      })
      .addCase(deviceUserRemove.pending, (state) => {
        state.pendingRemoveDeviceUser = true;
      })
      .addCase(deviceUserRemove.rejected, (state) => {
        openErrorNotification('Failed to remove User from Device.');
        state.pendingRemoveDeviceUser = false;
      })

      .addCase(deviceUserUpdatePermission.fulfilled, (state) => {
        state.pendingUpdateDeviceUserPermission = false;
      })
      .addCase(deviceUserUpdatePermission.pending, (state) => {
        state.pendingUpdateDeviceUserPermission = true;
      })
      .addCase(deviceUserUpdatePermission.rejected, (state) => {
        openErrorNotification('Failed to update User Device Permission.');
        state.pendingUpdateDeviceUserPermission = false;
      })

      .addCase(deviceUsersInvite.fulfilled, (state, { payload }) => {
        state.pendingDeviceInvite = false;
        console.log('deviceUsersInvite: ', payload);
        if (payload) {
          openSuccessNotification({
            message: 'User Invited to Device',
            description: 'User will now be able to access this device on their account.',
          });
        }
      })
      .addCase(deviceUsersInvite.pending, (state) => {
        state.pendingDeviceInvite = true;
      })
      .addCase(deviceUsersInvite.rejected, (state) => {
        openErrorNotification('Failed to invite Users to Device.');
        state.pendingDeviceInvite = false;
      });
  },
});

export const { setSelectedTab } = slice.actions;

export const selectSelectedTab = (state) =>
  state.deviceSharing.selectedTab;
export const selectDeviceUserPermission = (state) =>
  state.deviceSharing.deviceUserPermission;
export const selectPendingGetDeviceUserPermission = (state) =>
  state.deviceSharing.pendingGetDeviceUserPermission;
export const selectPendingUpdateDeviceUserPermission = (state) =>
  state.deviceSharing.pendingUpdateDeviceUserPermission;
export const selectPendingGetDeviceUsers = (state) =>
  state.deviceSharing.pendingGetDeviceUsers;
export const selectPendingGetDeviceUserPendingInvites = (state) =>
  state.deviceSharing.pendingGetDeviceUserPendingInvites;
export const selectPendingDeleteDeviceUserPendingInvites = (state) =>
  state.deviceSharing.pendingDeleteDeviceUserPendingInvites;
export const selectPendingDeviceInvite = (state) =>
  state.deviceSharing.pendingDeviceInvite;
export const selectPendingRemoveDeviceUser = (state) =>
  state.deviceSharing.pendingRemoveDeviceUser;
export const selectDeviceUsers = (state) =>
  state.deviceSharing.deviceUsers;
export const selectDeviceUserPendingInvites = (state) =>
  state.deviceSharing.deviceUserPendingInvites;

export default slice.reducer;
